import Vue from 'vue';
import Router from 'vue-router';
import {capitalize} from './services/utils'
import Portals from './views/Dashboard/Portals.vue';
import Portal from './views/Dashboard/Portal.vue';
import PortalCreate from './views/Dashboard/PortalCreate.vue';
import DashboardLayout from './views/Dashboard/Layout/DashboardLayout';
import Monitors from './views/Dashboard/Monitors.vue';
import Broadcasts from './views/Dashboard/Broadcasts.vue';
import Broadcast from './views/Dashboard/Broadcast.vue';
import Monitor from './views/Dashboard/Monitor.vue';
import Keys from './views/Dashboard/Keys.vue';
import Key from './views/Dashboard/Key.vue';
import Viewers from './views/Dashboard/Viewers.vue';
import Viewer from './views/Dashboard/Viewer.vue';
import Profile from './views/Dashboard/Profile.vue';
import Team from './views/Dashboard/Team.vue';
import Logout from './views/Logout.vue'
import Domains from "./views/Dashboard/Domains";
import OktaError from "@/views/OktaError";
import OktaAuth from '@okta/okta-vue'

const options = {
    issuer: process.env.VUE_APP_ISSUER ||'https://remotefilming.okta.com/oauth2/default',
    clientId: process.env.VUE_APP_CLIENT_ID || '',
   redirectUri: `${window.location.protocol}//${window.location.host}${process.env.VUE_APP_REDIRECT_URI}`,
    scopes: process.env.VUE_APP_SCOPE.split(' ') || ['openid', 'profile', 'email'],
    responseType: ['id_token', 'token', 'code'],
    pkce: false,
    'onSessionExpired': async (...args)=> {
        console.log('onSessionExpired', args)
    },
    'onAuthRequired': async (...args)=> {
        //console.log('onAuthRequired', args)
        const authed = await Vue.prototype.$auth.isAuthenticated();
        if(!authed){
            await Vue.prototype.$auth.loginRedirect(args);
        }
    }
};

Vue.use(OktaAuth, options);

const router = new Router({
    mode: 'history',
    routes: [
        {
            path: '/authorization-code/callback',
            //component: Auth.handleCallback()
            component: OktaError,
        },
        {
            path: '/logout',
            name: 'logout',
            component: Logout,
        },
        {
            path: '/',
            name: 'main',
            redirect: '/portals',
        },
        {
            path: '/create',
            name: 'create',
            component:DashboardLayout,
            children:[
              // {
              //     path: '',
              //     name: 'portal',
              //     meta: {
              //         backPath: '/portals',
              //         requiresAuth: true
              //     },
              //     component: PortalCreate
              // },
                {
                    path: 'portal',
                    name: 'portal',
                    meta: {
                        backPath: '/portals',
                        requiresAuth: true
                    },
                    component: PortalCreate
                }
            ]
        },
        {
            path: '/portals',
            name: 'main-portals',
            component: DashboardLayout,
            /*beforeEach: async (to, from, next) => {
                console.log('beforeEach');
                next()
            },*/
            children: [{
                path: '',
                name: 'portals',
                meta: {
                    requiresAuth: true
                },
                component: Portals
            },

                {
                    path: ':siteId',
                    name: 'portal',
                    meta: {
                        backPath: '/',
                        requiresAuth: true
                    },
                    component: Portal
                }]
        },
        {
            path: '/monitor',
            name: 'main-monitors',
            component: DashboardLayout,
            /*beforeEach: async (to, from, next) => {
                console.log('beforeEach');
                next()
            },*/
            children: [{
                path: '',
                name: 'monitors',
                meta: {
                    requiresAuth: true
                },
                component: Monitors
            },
                {
                    path: ':siteId',
                    name: 'monitor',
                    meta: {
                        backPath: '/monitors',
                        requiresAuth: true
                    },
                    component: Monitor
                }]
        },
        {
            path: '/broadcast',
            name: 'main-broadcasts',
            component: DashboardLayout,
            /*beforeEach: async (to, from, next) => {
                console.log('beforeEach');
                next()
            },*/
            children: [{
                path: '',
                name: 'broadcasts',
                meta: {
                    requiresAuth: true
                },
                component: Broadcasts
            },
                {
                    path: ':siteId',
                    name: 'broadcast',
                    meta: {
                        backPath: '/',
                        requiresAuth: true
                    },
                    component: Broadcast
                }]
        },
        {
            path: '/keys',
            name: 'main-keys',
            component: DashboardLayout,
            /*beforeEach: async (to, from, next) => {
                console.log('beforeEach');
                next()
            },*/
            children: [{
                path: '',
                name: 'keys',
                meta: {
                    requiresAuth: true
                },
                component: Keys
            },
                {
                    path: ':siteId',
                    name: 'key',
                    meta: {
                        backPath: '/',
                        requiresAuth: true
                    },
                    component: Key
                }]
        },
        {
            path: '/viewers',
            name: 'main-viewers',
            component: DashboardLayout,
            /*beforeEach: async (to, from, next) => {
                console.log('beforeEach');
                next()
            },*/
            children: [{
                path: '',
                name: 'viewers',
                meta: {
                    requiresAuth: true
                },
                component: Viewers
            },
                {
                    path: ':userId',
                    name: 'viewer',
                    meta: {
                        backPath: '/',
                        requiresAuth: true
                    },
                    component: Viewer
                }]
        },
        {
            path: '/profile',
            name: 'main-profile',
            component: DashboardLayout,

            children: [{
                path: '',
                name: 'profile',
                meta: {
                    requiresAuth: true
                },
                component: Profile
            }]
        },
        {
            path: '/team',
            name: 'main-team',
            component: DashboardLayout,

            children: [{
                path: '',
                name: 'team',
                meta: {
                    requiresAuth: true
                },
                component: Team
            }]
        },
        {
            path: '/domains',
            name: 'main-domains',
            component: DashboardLayout,

            children: [{
                path: '',
                name: 'domains',
                meta: {
                    requiresAuth: true
                },
                component: Domains
            }]
        },
        {
            path: '*',
            name: 'notfound',
            component: () => {
                return import(/* webpackChunkName: "notfound" */ './views/NotFound.vue')
            },

        },
    ]
});
router.beforeEach((to, from ,next)=>{

    const host  = location.hostname.indexOf('remotefilming') >= 0 ?  'REMOTE FILMING' : 'HIJACK LIVE';
    const section = capitalize(to.name);
    document.title = `${host} | ${section}`;
    next();
});

router.beforeEach(Vue.prototype.$auth.authRedirectGuard());

export default router;
