<template>
    <div>
        <md-table id="portals-table" v-model="searched" md-sort="name" md-sort-order="asc" md-fixed-header style="height: 100%;">
            <md-table-toolbar>
                <div class="md-toolbar-section-start">
                    <!--<h1 class="md-title">Portals</h1>-->
                </div>

                <md-field md-clearable class="md-toolbar-section-end">
                    <md-input placeholder="Search by name..." v-model="search" @input="searchOnTable"/>
                </md-field>
            </md-table-toolbar>

            <md-table-empty-state
                    md-label="No Team found"
                    :md-description="`No user found for this '${search}' query. Try a different search term or create a new team member.`">
                <md-button class="md-primary md-raised" @click="newUser">Create New User</md-button>
            </md-table-empty-state>

            <md-table-row slot="md-table-row" slot-scope="{ item }">
                <md-table-cell md-label="Name" md-sort-by="label">{{`${item.profile.firstName} ${item.profile.lastName}`}}</md-table-cell>
                <md-table-cell md-label="Email" md-sort-by="email">{{ item.profile.email }}</md-table-cell>
                <md-table-cell md-label="Role" md-sort-by="label">{{ item.role }}</md-table-cell>
                <md-table-cell md-label="Status" md-sort-by="status">{{ item.status }}</md-table-cell>
            </md-table-row>

        </md-table>


        <div>
            <md-dialog :md-active.sync="showDialog">

                <md-dialog-title v-if="!dialogLoading">Are you sure?</md-dialog-title>
                <md-dialog-content v-if="dialogLoading">
                    <div class="md-layout md-gutter md-alignment-center-center">
                        <div class="md-layout-item md-size-40">
                            <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
                        </div>
                    </div>
                </md-dialog-content>
                <md-dialog-actions v-if="!dialogLoading">
                    <md-button class="md-primary" @click="showDialog = false">Cancel</md-button>
                    <md-button class="md-primary" >Yes</md-button>
                </md-dialog-actions>
            </md-dialog>

            <!--<md-button class="md-primary md-raised" @click="showDialog = true">Show Dialog</md-button>-->
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    const toLower = text => {
        return text.toString().toLowerCase()
    };

    const searchByName = (items, term) => {
        if (term) {
            return items.filter(item => toLower(item.name).includes(toLower(term)))
        }

        return items
    };

    export default {
        name: 'TeamList',
        props:{
            type:{
                type: String,
                default: 'Portals'
            },
            team: {
                type: Array,
                default: function () {
                    return null
                }
            }
        },
        computed: {
            ...mapState({
                selectedDomain(state) {
                    return state.domains.selectedDomain
                }
            }),
        },
        data: () => ({
            dialogLoading: false,
            showDialog: false,
            search: null,
            searched: [],
            portalBaseUrl: 'dyson.remotefilming.tv',
            /*team: [{
                id: 'someid1',
                name: 'Adrian Paul',
                email: 'adrian.paul@hijackpost.com',
                role: 'owner',
                status: 'Active'
            },
                {
                    id: 'someid1',
                    name: 'Hijack',
                    email: 'tech@hijackpost.com',
                    role: 'admin',
                    status: 'Active'
                }]*/
        }),
        methods: {
            newUser() {
                window.alert('Noop')
            },
            searchOnTable() {
                this.searched = searchByName(this.team, this.search)
            },
        },
        created() {
            this.searched = this.team;
        }
    }
</script>

<style scoped>
    .md-field {
        max-width: 300px;
    }

    .md-card.md-theme-default {

        box-shadow: none !important;
    }

</style>
<style>
    table{
        width: 100% !important;
    }
</style>
