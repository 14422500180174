<template>
  <div>
    <md-table md-height="100%" id="portals-table" v-model="searched" md-sort="name" md-sort-order="asc" md-fixed-header style="height: 100%;">
      <md-empty-state v-if="domains" md-icon="language"
                      md-label="Add your first Domain"
                      md-description="Adding a domain, will facilitate  members, sites, etc,.">
        <md-button class="md-primary md-raised" @click="onAddEvt">Add first Domain</md-button>
      </md-empty-state>
      <div v-else class="text-center" >
        <spinner></spinner>
      </div>
      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <md-table-cell md-label="Created">{{item.createdAt | moment("MMM DD, Y h:mA")}}</md-table-cell>
        <md-table-cell md-label="Name">{{ item.name }}</md-table-cell>
        <md-table-cell md-label="Sites">{{ item.sites.length }}</md-table-cell>
        <md-table-cell md-label="Selected">
          <md-button class="md-icon-button" @click="onSelectedEvt(item)">
            <md-icon :class="{'selected-domain' : selectedDomain && selectedDomain._id === item._id}">language</md-icon>
          </md-button>
        </md-table-cell>
        <md-table-cell md-label="Delete">
          <md-button class="md-icon-button" @click="onDeleteEvt(item)">
            <md-icon>delete</md-icon>
          </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>


    <v-page v-show="domains && domains.length" :align="'center'" v-model="domainsInfoParams.pageNumber" :total-row="totalRow" @page-change="pageChange"></v-page>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import {Page} from "v-page";
import Spinner from "@/views/components/UIComponents/Spinner";

export default {
  name: 'DomainList',
  components: {
    'spinner': Spinner,
    'v-page': Page,
  },
  props: {
    type: {
      type: String,
      default: 'Portals'
    },
    domains: {
      type: Array,
      default: function () {
        return []
      }
    },
    onAdd: {
      type: Function,
    },
    onSelect: {
      type: Function,
    },
    onDelete: {
      type: Function,
    },
    onPageChange: {
      type: Function,
    },
  },
  computed: {
    ...mapState({
      selectedDomain(state) {
        return state.domains.selectedDomain
      },
      totalRow(state) {
        return state.domains.domainsCount
      },
      domainsInfoParams(state) {
        return state.domains.domainsInfoParams
      },

    }),
    searched(){
      return this.domains || [];
    }
  },
  data: () => ({
    //
  }),
  methods: {
    ...mapActions({
      'getDomains': 'domains/getDomains',
    }),
    pageChange(info){
      if(this.onPageChange){
        this.onPageChange(info);
      }
    },

    onAddEvt(){
      if(this.onAdd){
        this.onAdd();
      }
    },
    onSelectedEvt(item) {
      if(this.onSelect){
        this.onSelect(item)
      }
    },
    onDeleteEvt(item){
      if(this.onDelete){
        this.onDelete(item)
      }
    }
  },
  created() {
    //
  },
}
</script>

<style scoped>
</style>
<style>
.selected-domain{
  color: green !important;
}
.v-pagination ul li a{
  color: #2e2e2e !important;
}
.v-pagination__list a span{
  display: none !important;
}
</style>

