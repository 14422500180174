import apiClient from "@/services/apiClient";
//import sites from "./sites";

const state = () => ({
    domains: null,
    selectedDomain: null,
    selectedDomainUsers: null,
    addedDomain: null,
    error: null,
    displayDomain: '',
    domainsCount: null,
    domainsInfoParams: {pageNumber:1, pageSize:10}
});
//silent update?
const getters = {
    domains(state){
        //this.$store?
        return state.domains;
    }
};

const actions = {
    async getDomains({commit}, params={pageNumber:0, pageSize:0}) {
        commit('updateDomains', {error: null, data: null});
        let data = null;
        let error = null;

        try {
            data = await apiClient.getDomainsInfo(params);
        } catch (e) {

            error = e
        }
        commit('updateDomains', {error, data});
    },
    async selectDomain({commit}, domainId) {
        commit('updateSelectedDomain', {error: null, data: null});
        const response = {
            error: null,
            data: null
        };

        let data = null;
        let error = null;

        try {
            data = await apiClient.getDomainInfo(domainId);
        } catch (e) {
            console.log(e);
            error = e
        }
        console.log('!selectDomain - ',error, data);

        if (data) {
            response.data = data;
        }
        localStorage.setItem('selectedDomainId', domainId)
        commit('updateSelectedDomain', {error, data});
    },
    async addDomain({commit, state, dispatch}, domainName) {
        const rootDomain = document.location.hostname;//.indexOf('remotefilming') >= 0 ? 'remotefilming.tv' : 'hijacklive.io';
        commit('updateAddedDomain', {error: null, data: null});
        const response = {
            error: null,
            data: null
        };

        let data = null;
        let error = null;

        try {
            data = await apiClient.postDomainInfo(domainName, rootDomain);
        } catch (e) {
            console.log(e.message);
            error = e
        }

        if (data) {
            response.data = data;
            if(state.selectedDomain === null){
                dispatch('domains/selectDomain', data._id, {root:true});
            }
        }
        commit('updateAddedDomain', {error, data});
    },
    async removeDomain({commit}, domainId) {
        commit('updateRemovedDomain', {error: null, data: null});
        let data = null;
        let error = null;

        try {
            data = await apiClient.deleteDomainInfo(domainId);
        } catch (e) {
            console.log(e.message);
            error = e
        }

        commit('updateRemovedDomain', {error, data});
    },
    async getDomainUsers({commit}, domainId) {
        commit('updateSelectedDomainUsers', {error: null, data: null});
        const response = {
            error: null,
            data: null
        };

        let data = null;
        let error = null;

        try {
            data = await apiClient.getDomainUsers(domainId);
        } catch (e) {
            console.log(e);
            error = e
        }
        console.log(error, data);

        if (data) {
            response.data = data;
        }

        commit('updateSelectedDomainUsers', {error, data});
    },
};

const mutations = {
    clearError(state){
        state.error = null;
    },
    updateRemovedDomain(){

    },
    updateDomains(state, resp) {
        console.log('updateDomains - ', !!resp.data, resp.data)
        if(resp.data){
            state.domains = resp.data.domains;
            state.domainsCount = resp.data.count;
            state.domainsInfoParams.pageNumber = resp.data.pageNumber;
            state.domainsInfoParams.pageSize = resp.data.pageSize;
        }else{
            state.domains = null;
            state.domainsCount = 0;
        }
        state.error = resp.error
    },
    updateSelectedDomain(state, resp) {
        console.log('updateSelectedDomain - ', resp)
        //const hostname = document.location.hostname;
        state.selectedDomain = resp.data;
        if(state.selectedDomain){
            state.displayDomain = `${state.selectedDomain.name}.${state.selectedDomain.rootDomain}`;//hostname.indexOf('remotefilming') >= 0 ? `${state.selectedDomain.name}.remotefilming.tv` : `${state.selectedDomain.name}.hijacklive.io`;
            // this.dispatch('authorization/getTeam', state.selectedDomain._id);
            // this.dispatch('sites/getSites', state.selectedDomain._id);
        }
        state.error = resp.error;
    },
    updateAddedDomain(state, resp){
        state.addedDomain = resp.data;
        state.error = resp.error;
    },
    updateSelectedDomainUsers(state, resp) {
        //console.log('updateSelectedDomain - ', resp)
        state.selectedDomainUsers = resp.data;
        state.error = resp.error;
    },
    updateDomainsInfoParams(state, resp){
        console.log('resp', resp)
        //state.domainsInfoParams.pageNumber = resp.pageNumber;
        //state.domainsInfoParams.pageSize = resp.pageSize;
        state.domainsInfoParams = resp
    }
};

const modules = {
    //sites
};

const domains = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    modules
};
export default domains
