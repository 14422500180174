<template>
    <md-content>
        <transition name="fade" mode="out-in">
            <md-content class="text-center" v-if="subscribeToken">
                <div class="md-layout">
                    <div class="md-layout-item  md-size-20" v-show="showViewer && selectedSite.authentication.users && selectedSite.authentication.users.length>0">
                        <md-list>
                            <md-list-item v-for="(user, index) in selectedSite.authentication.users" :key="`user-${index}`">
                                <md-avatar>
                                    <!--<img src="https://placeimg.com/40/40/people/5" alt="People">-->
                                    <md-icon>account_circle</md-icon>
                                </md-avatar>

                                <span class="md-list-item-text">{{user.profile.firstName}} {{user.profile.lastName}}</span>

                                <!--<md-button class="md-icon-button md-list-action">
                                    <md-icon class="md-primary">chat_bubble</md-icon>
                                </md-button>-->
                            </md-list-item>
                        </md-list>
                    </div>

                    <div class="md-layout-item" style="position: relative;">
                        <video id="vidWin" ref="vidWin" class="bg-black" autoplay muted playsinline></video>
                        <div class="spinner-container" v-if="!isBroadcasting">
                            <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
                        </div>
                        <div>
                            <md-bottom-bar md-sync-route>
                                <div style="width: 100%;  background-color: black;">
                                    <div class="md-layout" style="width: 100%;">
                                        <div class="md-layout-item white text-left">
                                            <div style="margin: 6px 8px; text-align: left;">
                                                <md-icon :class="{'red': isBroadcasting}">circle</md-icon> {{isBroadcasting ? 'Live' : ''}}
                                            </div>
                                        </div>
                                        <div class="md-layout-item md-layout white text-right">
                                            <div class="md-layout-item viewer-controls">
                                                <md-button @click="onMute()">
                                                    <md-icon class="white">{{!isMuted ? 'volume_up' : 'volume_off'}}</md-icon>
                                                </md-button>
                                                <input style="margin-top: 16px; margin-right: 16px;" type="range" v-model.number="rangeVolume" @change="setVolume(rangeVolume)">
                                                <md-button @click="onFullscreen()">
                                                    <md-icon class="white">fullscreen</md-icon>
                                                </md-button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style="width: 100%;">
                                    <div class="md-layout md-size-100" style="width: 100%;">
                                        <div class="md-layout-item md-layout text-center">
                                            <div class="md-layout-item text-center md-size-10">
                                                <!--<md-button disabled>
                                                    <div>{{'Viewers'}}</div>
                                                    <md-icon>groups</md-icon>
                                                </md-button>-->
                                            </div>
                                            <div class="md-layout-item text-center">
                                                <md-button v-if="selectedSite.authentication.users && selectedSite.authentication.users.length>0" @click="showViewer = !showViewer">
                                                    <md-icon>groups</md-icon>
                                                    <div>{{'Viewers'}}</div>
                                                </md-button>
                                                <md-button @click="showChat = !showChat">
                                                    <md-icon>chat_bubble</md-icon>
                                                    <div>{{'Chat'}}</div>
                                                </md-button>
                                                <!--<md-button>
                                                    <md-icon>view_module</md-icon>
                                                    <div>{{'Cameras'}}</div>
                                                </md-button>-->
                                                <md-menu class="md-button" v-if="activeCameras.length>1">
                                                    <md-button md-menu-trigger>
                                                        <md-icon>view_module</md-icon>
                                                        <div>{{'Cameras'}}</div>
                                                    </md-button>

                                                    <md-menu-content>
                                                        <md-menu-item v-for="(item, index) in activeCameras" :key="`cam-${index}`" @click="onChangeStream(item, index)">
                                                            <span :class="{'red': selectedCameraStreamName === item.streamName}">{{item.label || `Blank ${numToSSColumn(index+1) }`}}</span>
                                                            <!--<md-button>
                                                                {{item.label || `Camera ${index}`}}
                                                            </md-button>-->
                                                        </md-menu-item>
                                                    </md-menu-content>
                                                </md-menu>
                                            </div>
                                            <div class="md-layout-item text-center md-size-10">
                                                <!--<md-button>
                                                    <md-icon>view_module</md-icon>
                                                    <div>{{'Cameras'}}</div>
                                                </md-button>-->
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </md-bottom-bar>
                        </div>
                    </div>

                    <div class="md-layout-item md-size-20" style="position: relative;" v-show="showChat">
                        <iframe ref="iframeChat" id="iframeChat" width="100%" height="100%" style="height:100%" frameBorder="0" :src="iframeChatUrl" @load="onChatChange" v-if="chatToken" v-show="hasChat"></iframe>
                        <div class="spinner-container" v-else>
                            <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
                        </div>
                    </div>
                </div>
            </md-content>
            <md-content v-else>
                <div class="md-layout md-gutter md-alignment-center-center">
                    <div class="md-layout-item text-center">
                        <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
                    </div>
                </div>
            </md-content>
        </transition>
    </md-content>
</template>

<script>

    import {mapState, mapActions} from "vuex";
    //import MillicastMedia from '@/services/millicast-manager';// eslint-disable-line no-eval
    import '@/services/millicast-manager';

    export default {
        name: 'Monitor',
        components: {},
        computed: {
            ...mapState({
                selectedDomain(state){
                  return state.domains.selectedDomain
                },
                selectedSite(state) {
                    return state.sites.selectedSite
                },
                subscribeToken(state) {
                    return state.broadcast.subscribeToken
                },
                chatToken(state){
                    return state.chat.chatToken
                }
                /*volumeTotal: function () {
                    return this.rangeVolume * 10
                }*/
            }),
            /*getChatSrcUrl(){
                return `https://chat.remotefilming.tv/channel/${this.selectedSite.chat.channel}?layout=embedded&resumeToken=${this.chatToken.token}`;
            },*/
            /*isMuted(){
                const video = this.$refs.vidWin;
                let response = null;
                if(video){
                    if(video.muted !== true || video.volume > 0){
                        response = false;
                    }else{
                        response = true;
                    }
                }else{
                    response = true;
                }
                console.log('isMuted', response);
                return response;
            },*/
            getActiveClass(item){
               return this.selectedCamera.streamName === item.streamName
            },
            activeCameras: function() {
                return this.selectedSite.cameras.streams.filter(function (u) {
                    return u.enabled
                })
            }
        },
        methods:{
            ...mapActions({
                'getSubscribeToken': 'broadcast/getSubscribeToken',
                //'selectSite': 'sites/selectSite',
                'getSite': 'sites/getSite',
                'getChatToken': 'chat/getChatToken'
            }),
            numToSSColumn(num){
                let s = '', t;

                while (num > 0) {
                    t = (num - 1) % 26;
                    s = String.fromCharCode(65 + t) + s;
                    num = (num - t)/26 | 0;
                }
                return s || undefined;
            },
            onChatChange(){
                console.log('onChatChange');
                /**/
                setTimeout(()=>{
                    this.iframeChatUrl = `https://chat.remotefilming.tv/channel/${this.selectedSite.chat.channel}?layout=embedded`
                    this.hasChat = true;
                }, 1000);
            },

            onMute(){
                const video = this.$refs.vidWin;
                video.muted = video.muted === false;
                console.log('onMute - ', video.muted);
                if(video.muted === true){
                    this.setVolume(0);
                }else{
                    this.setVolume(!this.lastVolumeValue ? 100 : this.lastVolumeValue);
                }

                console.log('After: muted', video.muted, ' volume:', video.volume, ' isMuted ', this.isMuted );
            },
            setVolume(val) {
                const video = this.$refs.vidWin;
                console.log('setVolume Before: ', val, ' - ', video.volume);
                this.lastVolumeValue = video.volume*100;
                video.volume = Math.floor(val) / 100;

                this.isMuted = !(video.volume > 0);
                if(this.isMuted !== video.muted)video.muted = this.isMuted;
                this.rangeVolume = Math.floor(val);
                console.log('After: muted', video.muted, ' volume:', video.volume, ' isMuted ', this.isMuted );
            },
            onFullscreen(){

            },
            addVideoForStream(stream, isMuted) {
                //Do not duplicate
                if (this.isBroadcasting === true)
                    return;

                //Video element
                //const video = this.$refs.vidWin;//document.querySelector("video");
                let vidWin = this.$refs.vidWin;
                //Set same id
                // video.id = stream.id;
                //Set src stream
                console.log('stream', stream);

                if (vidWin) {
                    if (!isMuted && vidWin.hasAttribute('muted')) {
                        vidWin.muted = false;
                        vidWin.removeAttribute('muted');
                        this.setVolume(vidWin.volume*100)
                    }else{
                        this.setVolume(0)
                    }

                    vidWin.srcObject = stream;
                    this.lastVolumeValue = vidWin.volume;
                    console.log('vidWin', vidWin);
                }

                this.isBroadcasting = true;
                //console.log('addVideoForStream')
            },
            async getPeerSDP() {
                let own = this;
                let getIceServers = function getIceServers(location = 'https://turn.millicast.com/webrtc/_turn') {
                    const url = location;
                    return new Promise((resolve, reject) => {
                        let xhr = new XMLHttpRequest();

                        xhr.onreadystatechange = () => {
                            if (xhr.readyState !== 4) {
                                return;
                            }

                            if (xhr.status < 200 || xhr.status >= 300) {
                                let error = new Error(`IceServers call failed. StatusCode: ${xhr.status} Response: ${xhr.responseText}`);
                                error.responseStatus = xhr.status;
                                error.responseText = xhr.responseText;
                                error.responseJson = null;
                                reject(error);
                                return;
                            }

                            let jsonResponse = JSON.parse(xhr.responseText);
                            if (!jsonResponse || jsonResponse['s'] !== 'ok') {
                                let error = new Error(`IceServers invalid response. Response: ${xhr.responseText}`);
                                error.responseStatus = xhr.status;
                                error.responseText = xhr.responseText;
                                error.responseJson = jsonResponse;
                                reject(error);
                                return;
                            }

                            // final resolve array
                            let finalServers = [];

                            let credentials = [];
                            let valIceServers = jsonResponse['v']['iceServers'] ? jsonResponse['v']['iceServers'] : jsonResponse['v'] ? jsonResponse['v'] : [];
                            console.log('valIceServers', valIceServers, jsonResponse);
                            for (const server of valIceServers) {
                                // normalize server.urls
                                if (server.url) {
                                    // convert to new url's format if detected
                                    server.urls = [server.url];
                                    delete server.url;
                                } else if (server.urls && !Array.isArray(server.urls)) {
                                    // assuming this is using legacy notation where urls is a single string
                                    server.urls = [server.urls];
                                } else {
                                    // assure we have an array of something
                                    server.urls = [];
                                }

                                // skip empty urls
                                if (!server.urls.length) {
                                    continue;
                                }
                                // now to identify servers with identical credentials

                                // not everything has credentials
                                if (!server.username || !server.credential) {
                                    finalServers.push(server);
                                    continue;
                                }

                                let credIndex = credentials.findIndex((s) => s.username === server.username && s.credential === server.credential);
                                if (credIndex === -1) {
                                    // new credential pair
                                    credentials.push(server);
                                    continue;
                                }

                                // else we want to merge with credIndex
                                let mergeServer = credentials[credIndex];
                                for (const urlStr of server.urls) {
                                    mergeServer.urls.push(urlStr);
                                }
                            }

                            // lets separate udp from tcp and unspecified
                            for (const server of credentials) {
                                let udpUrls = [];
                                let tcpUrls = [];
                                let unspecifiedUrls = [];

                                for (const urlStr of server.urls) {
                                    let queryIndex = urlStr.indexOf('?');
                                    if (queryIndex === -1) {
                                        unspecifiedUrls.push(urlStr);
                                        continue;
                                    }

                                    let queryString = new URLSearchParams(urlStr.substr(queryIndex + 1));
                                    let transport = queryString.get('transport');
                                    switch (transport) {
                                        case 'udp':
                                            udpUrls.push(urlStr);
                                            break;
                                        case 'tcp':
                                            tcpUrls.push(urlStr);
                                            break;
                                        default:
                                            unspecifiedUrls.push(urlStr);
                                            break;
                                    }
                                }

                                if (udpUrls.length) {
                                    let newServer = Object.assign({}, server);
                                    newServer.urls = udpUrls;
                                    finalServers.push(newServer);
                                }
                                if (tcpUrls.length) {
                                    let newServer = Object.assign({}, server);
                                    newServer.urls = tcpUrls;
                                    finalServers.push(newServer);
                                }
                                if (unspecifiedUrls.length) {
                                    let newServer = Object.assign({}, server);
                                    newServer.urls = unspecifiedUrls;
                                    finalServers.push(newServer);
                                }
                            }

                            resolve(finalServers);
                        };

                        xhr.open('PUT', url, true);
                        xhr.setRequestHeader('Accept', 'application/json');
                        xhr.send();
                    });
                };
                let iceServers = null;
                let peerConfig = null;
                let sdp = null;
                try {
                    iceServers = await getIceServers();
                } catch (e) {
                    //throw e;
                    return Promise.reject(e);
                }
                peerConfig = {
                    rtcpMuxPolicy: 'require',  // default
                    bundlePolicy: 'max-bundle', // default is balanced
                    iceServers
                };
                return new Promise((resolve, reject) => {
                    own.peer = new RTCPeerConnection(peerConfig);
                    let options = {
                        offerToReceiveAudio: true,
                        offerToReceiveVideo: true,
                    };
                    own.peer.oniceconnectionstatechange = function () {
                        console.log('iceConnectionState ', own.peer.iceConnectionState);
                        if (own.peer.iceConnectionState === 'disconnected') {
                            //disconnectedHandler(new Event('disconnected'))
                        }
                    };
                    let audioonly = false;
                    let muted = true;

                    own.peer.ontrack = function (event) {
                        console.log('ontrack ', event);
                        //Play/bind it if track is video
                        if (event.receiver.track.kind === "audio" && audioonly === true) {
                            //addVideoForStream(event.streams[0]);
                            return;
                        }

                        if (event.receiver.track.kind === "video" && audioonly === false) {
                            own.addVideoForStream(event.streams[0], muted);
                        }
                    };
                    own.peer.createOffer(options)
                        .then((offer) => {
                            sdp = offer.sdp;
                            //support for stereo
                            offer.sdp = offer.sdp.replace("useinbandfec=1", "useinbandfec=1; stereo=1");
                            return own.peer.setLocalDescription(offer);
                        })
                        .then(() => {
                            // Send the offer to the remote peer using the signaling
                            resolve(sdp);
                        })
                        .catch((e) => {
                            reject(e)
                        });
                });
                /*return new Promise((resolve, reject) => {
                    own.peer = new RTCPeerConnection(peerConfig);
                    for (const track of own.stream.getTracks()) {
                        own.peer.addTrack(track, own.stream);
                    }
                    /!*peer.onicecandidate = function(){

                    };*!/
                    let options = {
                        offerToReceiveAudio: true,
                        offerToReceiveVideo: true,
                    };
                    own.peer.createOffer(options)
                        .then((offer) => {
                            //
                            desc = offer;
                            //support for stereo
                            if(this.mediaSettings.stereo){
                                desc.sdp = desc.sdp.replace("useinbandfec=1", "useinbandfec=1; stereo=1");
                            }
                            //simulcast
                            if(this.mediaSettings.simulcast === true && this.mediaSettings.format !== 'vp9' && this.mediaSettings.format !== 'av1'){
                                desc = getSimulcast(desc);
                            }

                            sdp = desc.sdp;
                            return own.peer.setLocalDescription(desc);
                        })

                        .then(() => {
                            // Send the offer to the remote peer using the signaling
                            resolve(sdp);
                        })
                        .catch((e) => {
                            reject(e)
                        });
                });*/
            },
            async setRemotePeerSDP(sdp) {
                return this.peer.setRemoteDescription({type: 'answer', sdp})
            },

            queryDirectorSubscribe(subscribeToken, streamName) {
                let payload = {
                    streamAccountId: 'VQumde',
                    unauthorizedSubscribe: false,
                    streamName: streamName
                };
                return this.queryDirectorBase('subscribe', subscribeToken, payload);
            },
            queryDirectorBase(queryType, token, payload) {
                switch (queryType) {
                    case 'publish':
                    case 'subscribe':
                        break;
                    default:
                        throw new Error('incorrect queryType used');
                }
                const DIRECTOR_BASE_URL = 'https://director.millicast.com/api/';
                const url = `${DIRECTOR_BASE_URL}director/${queryType}`;
                return new Promise((resolve, reject) => {
                    let xhr = new XMLHttpRequest();

                    xhr.onreadystatechange = () => {
                        if (xhr.readyState !== 4) {
                            return;
                        }

                        if (xhr.status < 200 || xhr.status >= 300) {
                            let error = new Error(`Director (${queryType}) failed. StatusCode: ${xhr.status} Response: ${xhr.responseText}`);
                            error.responseStatus = xhr.status;
                            error.responseText = xhr.responseText;
                            error.responseJson = null;
                            reject(error);
                            return;
                        }

                        let jsonResponse = JSON.parse(xhr.responseText);
                        if (!jsonResponse || jsonResponse.status !== 'success') {
                            let error = new Error(`Invalid Director (${queryType}) response. Response: ${xhr.responseText}`);
                            error.responseStatus = xhr.status;
                            error.responseText = xhr.responseText;
                            error.responseJson = jsonResponse;
                            reject(error);
                            return;
                        }

                        resolve({
                            url: `${jsonResponse.data['urls'][0]}?token=${jsonResponse.data['jwt']}`,
                            jwt: jsonResponse.data['jwt']
                        });
                    };

                    xhr.open('POST', url, true);
                    if (token) {
                        xhr.setRequestHeader('Authorization', `Bearer ${token}`);
                    }
                    xhr.setRequestHeader('Accept', 'application/json');
                    xhr.setRequestHeader('Content-Type', 'application/json');

                    xhr.send(JSON.stringify(payload));
                });
            },
            getWebsocket() {
                let own = this;
                let url = `${this.director.url}`;
                let ws;
                let error;
                if (!!ws && own.websocket.readyState === WebSocket.OPEN) {
                    return Promise.resolve(ws);
                }
                return new Promise((resolve, reject) => {
                    ws = new WebSocket(url);
                    ws.onopen = () => {
                        if (ws.readyState !== WebSocket.OPEN) {
                            error = {state: ws.readyState};
                            return reject(error);
                        }
                        resolve(ws);
                    };
                    ws.onerror = (evt) => {
                        error = evt || true;
                        console.log('ws::onerror', ws.readyState);
                    };
                    ws.onclose = () => {
                        if (error) {
                            reject(`WebSocket Connection Error ${url}`);
                        }
                        console.log('ws::onclose', ws.readyState);
                        if (ws.readyState === 3) {
                            ws = null;
                            this.peer = null;
                        }
                    };
                });
            },
            onmessageHandler (event, resolve, reject) {
                console.log(event);
                let jsonMsg;
                try {
                    let message = event.data;
                    jsonMsg = JSON.parse(message);
                    //console.log(jsonMsg);
                    if (jsonMsg['type'] === 'error') {
                        //console.error(`Signalling error: ${jsonMsg.data}`);
                        return reject(`Signalling error: ${message}`);
                    }

                    // there is only one message ever on a publishing websocket
                    // so we don't need to fully add TransactionManager really
                    // but this is technically bad form
                    // also means we aren't handling response errors at all
                    switch (jsonMsg.type) {
                        case 'response':
                            resolve(jsonMsg.data.sdp);
                            break;
                        case 'event':
                            this.streamStatusHandler(jsonMsg);
                            break;
                    }

                } catch (err) {
                    //console.error(`Failed to handle signalling message: ${err}`);
                    return reject(`Failed to handle signalling message: ${err}`);
                }
            },
            streamStatusHandler(evt) {
                console.log('streamStatusHandler - ',evt.name);
                if (evt.name === 'active') {
                    //
                } else if (evt.name === 'inactive') {
                    //
                }
                //$('.spin-text').text(evt.name);
            },
            getRemotePeerSDP(ws, sdp) {
                return new Promise((resolve, reject) => {
                    let payload = {
                        type: 'cmd',//hangs & no error
                        transId: new Date().getTime(),//no error
                        name: 'view',
                        data: {
                            sdp:   sdp
                        }
                    };
                    let MILLI_PROTO_VER = Number(this.$route.query.v) === 1 ? 'v1' : 'v2';

                    if(MILLI_PROTO_VER === 'v1'){
                        payload.data.name = this.streamName;
                        payload.data.streamId = this.streamName;
                    }

                    ws.onmessage = async (event) => {
                        await this.onmessageHandler(event, resolve, reject);
                    };
                    ws.send(JSON.stringify(payload));
                });
            },
            async startPlay() {
                try {
                    this.director = await this.queryDirectorSubscribe(this.subscribeToken.token, this.selectedCamera.streamName);
                    this.websocket = await this.getWebsocket();
                    this.localSDP = await this.getPeerSDP();
                    this.remoteSDP = await this.getRemotePeerSDP(this.websocket, this.localSDP);
                    await this.setRemotePeerSDP(this.remoteSDP);
                    this.isBroadcasting = true;
                    //console.log('success');
                } catch (e) {
                    //console.log(e);
                    //throw e;
                    this.isBroadcasting = false;
                    return Promise.reject(e);
                }
            },
            stopPlay() {
                console.log('stopBroadcast');

                if(this.stream){
                    this.peer.close();
                    this.peer = null;
                    this.websocket.close();
                    this.websocket = null;
                }
                this.isBroadcasting = false;
            },

            onChangeStream(item, index){
                /*if (this.isBroadcasting) {
                    alert('You will need to stop broadcasting first.');
                    return;
                }*/
                this.stopPlay();
                this.isBroadcasting = false;
                this.selectedCamera = null;
                const camIndex = this.selectedSite.cameras.streams.findIndex((camera)=>{
                    return camera.streamName === item.streamName;
                });
                //this.selectedCamera.label = this.selectedSite.cameras.streams[camIndex].label;
                let selectedCamera = this.selectedSite.cameras.streams[camIndex];
                this.selectedCamera = {
                    label: selectedCamera.label,
                    streamName: selectedCamera.streamName
                };
                this.selectedCameraLabel = this.selectedCamera.label || 'Camera '+index;
                this.selectedCameraStreamName = this.selectedCamera.streamName;
                console.log('onChangeCam selectedCamera ', camIndex, item, this.selectedCamera);

                this.startPlay();
            },
            onInit(){

                setTimeout(()=>{
                    if(this.chatToken){
                        //this.iframeChatUrl = `https://chat.remotefilming.tv/channel/${this.selectedSite.chat.channel}?layout=embedded&resumeToken=${this.chatToken.token}`;
                    }
                }, 1000)
            },
            async run() {
              console.log('run - ',this.selectedDomain)
              const {_id} = this.selectedDomain;
              const {siteId} = this.$route.params;
              await this.getSite({domainId: _id, siteName: siteId});

              console.log('!created! ', this.selectedSite);
              if (!this.selectedCamera) {
                this.selectedCamera = this.selectedSite.cameras.streams[0];
                this.selectedCameraLabel = this.selectedSite.cameras.streams[0].label || 'Camera 0';
                this.selectedCameraStreamName = this.selectedSite.cameras.streams[0].streamName;
              }

              await this.getSubscribeToken(this.selectedSite.cameras.subscribeTokenId);

              if (this.subscribeToken) {
                await this.startPlay();
              }

              /*if (!this.chatToken) {
                await this.getChatToken(this.selectedSite.chat.channel);
              }*/
            }
        },
        data(){
            return {
                showViewer: true,
                hasChat: false,
                showChat: true,
                iframeChatUrl: null,
                isMuted: true,
                rangeVolume: 100,
                lastVolumeValue: 0,
                millicastMedia: null,

                stream: null,
                devices: null,
                activeCam: {label: 'none'},
                activeMic: {label: 'none'},
                disabledBandwidthBtn: false,
                disabledSimulcastBtn: false,
                disabledVideoFormatBtn: false,
                mediaSettings:{
                    format: 'h264',
                    simulcast: false,
                    stereo: true,
                    bitrate: 'Unlimited'
                },
                previousMediaSettings: {},
                constraints: {
                    audio: true,
                    video: {
                        width: {min: 640, max: 1920, ideal: 1280},
                        height: {min: 480, max: 1080, ideal: 720},
                        frameRate: {min: 10, max: 60, ideal: 24},
                        advanced: [
                            // additional constraints go here, tried in order until something succeeds
                            // can attempt high level exact constraints, slowly falling back to lower ones
                            {aspectRatio: 16 / 9},
                            {aspectRatio: 4 / 3},
                        ]
                    }
                },
                selectedConstraints: {
                    audio: {
                        echoCancellation: false,
                        //channelCount: {ideal:2}
                    },
                    video: {
                        width: 1280,
                        height: 720,
                        frameRate: 24,
                        aspectRatio: 16 / 9,
                    }
                },
                previousConstraints: {},
                isBroadcasting: false,
                micEnabled: false,
                camEnabled: false,
                director: null,
                websocket:null,
                peer: null,
                localSDP: null,
                remoteSDP: null,
                selectedCameraLabel: '',
                selectedCameraStreamName: null,
                showMediaSettingsDialog: false,
                dialogMediaSettingsLoading: false,
            }
        },
        async created() {
            console.log('created !! ',this.selectedDomain);
            //this.previousConstraints = this.selectedConstraints;
          if(!this.selectedDomain){
            await this.$router.push('/domains');
          }else{
            await this.run();
            this.onInit()
          }
        },
        beforeRouteLeave(to, from, next){
            if (this.stream) {
                //this.trackMicVolume(false);
                this.stream.getTracks().forEach((track) => {
                    track.stop();
                });
                this.stream = null;
                //delete this.stream;
            }
            let vidWin = this.$refs.vidWin;
            if (vidWin) {
                vidWin.srcObject = null;
            }
            next();
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    /*#portals {
        display: flex;
        justify-content: center;
        align-items: center;
    }*/
    .md-content.md-table-content.md-scrollbar.md-theme-default{
        height: 100% !important;
        max-height: 100% !important;
    }
    .bg-black{
        background-color: black;
    }



    /*Viewer Controls*/
    .viewer-controls>.md-button{
        min-width: 36px !important;
    }


</style>
