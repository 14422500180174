<template>
    <div>
        <Spinner></Spinner>
        <h2>Logging out</h2>
        <div class="my-3">
            <!--<router-link class="mr-3" to="/"><i class="fas fa-home"></i> Go Home</router-link>-->
        </div>
    </div>
</template>
<script>
    import Spinner from "./components/UIComponents/Spinner";
    export default {
        name: 'logout',
        components:{
            Spinner
        },
        methods: {
            async logout () {
                await this.$auth.logout();
                localStorage.clear();
                //await this.refreshActiveUser()
                this.$router.push('/');
            }
        },
        async created() {
            try {
                await this.logout();
            }catch (e) {
                localStorage.clear();
                this.$router.push('/');
            }
        }
    }
</script>
<style scoped>
    a {
        color: #42b983;
    }

</style>
