import axios from 'axios';
//const REST_API_BASE_URL = process.env.HOST_URL || 'http://localhost:3000/';
//console.log(REST_API_BASE_URL);
const httpClient = axios.create({
    //baseURL: REST_API_BASE_URL,
    headers: {
        common: {
            // 'Accept': 'text/html',
            // 'Content-Type': 'text/html'
        }
    },
    // withCredentials: true
});
export default httpClient;
