import apiClient from "@/services/apiClient";

const state = () => ({
    sites: null,
    selectedSite: null,
    createdSite: {},
    error: null,
    createdSiteError: null,
    sitesCount: null,
    sitesInfoParams: {pageNumber:1, pageSize:10}
});
//silent update?
const getters = {
    sites(state){
        return state.sites;
    }
};

const actions = {
    async getSite({commit}, payload) {
        console.log(payload)
        commit('updateSelectedSite', {error: null, data: null});
        const response = {
            error: null,
            data: null
        };

        let data = null;
        let error = null;

        try {
            data = await apiClient.getSiteInfo(payload);//domain.sites;
        } catch (e) {
            console.log(e);
            error = e
        }
        //console.log(error, typeof data, !!data);

        if (typeof data === 'string') {
            response.error = data;
            response.data = null;
        }else{
            response.data = data;
            response.error = error;
        }
        console.log(response)
        commit('updateSelectedSite', response);
    },
    async getSites({commit}, {domainId, params}) {
        commit('updateSites', {error: null, data: null});
        const response = {
            error: null,
            data: null
        };

        let data = null;
        let error = null;

        try {
            data = await apiClient.getSitesInfo(domainId, params);//domain.sites;
        } catch (e) {
            console.log(e);
            error = e
        }
        console.log(error, data);

        if (data) {
            response.data = data;
        }

        commit('updateSites', {error, data});
    },
    async selectSite({commit, state}, siteId) {
        siteId = `/${siteId}`;
        commit('updateSelectedSite', {error: null, data: null});
        const response = {
            error: null,
            data: null
        };

        let data = null;
        let error = null;

        console.log('siteId', siteId)

        const {sites} = JSON.parse(JSON.stringify(state));
        console.log('state', state, sites);
        try {
            data = state.sites.find((s)=>{
                console.log(s)
                return s.path === siteId
            })
        } catch (e) {
            console.log('eeee ',e);
            error = e
        }
        console.log('selectSite - ', state.sites, '-- ', siteId, error, data);

        if (data) {
            response.data = data;
        }

        commit('updateSelectedSite', {error, data});
    },
    async createSite({commit, state, dispatch}, {domainId, site}) {
        commit('updateCreatedSite', {error: null, data: null});
        const response = {
            error: null,
            data: null
        };

        let data = null;
        let error = null;

        if(site.expiration.date)site.expiration.date = new Date(site.expiration.date).getTime();

        try {
            data = await apiClient.postSiteInfo(domainId, site);
        } catch (e) {
            console.log('postSiteInfo', e);
            error = e
        }

        if (data) {
            response.data = data;

            //dispatch('domains/getDomains', state.domainsInfoParams, {root:true});

            if(state.selectedDomain === null){
                dispatch('domains/selectDomain', data._id, {root:true});
            }
        }

        commit('updateCreatedSite', {error, data});
    },
    // eslint-disable-next-line no-unused-vars
    async removeSite({commit, state, dispatch}, {domainId, siteId}) {
        commit('updateRemovedSite', {error: null, data: null});
        const response = {
            error: null,
            data: null
        };

        let data = null;
        let error = null;

        try {
            data = await apiClient.deleteSiteInfo(domainId, siteId);
        } catch (e) {
            console.log('deleteSiteInfo', e);
            error = e
        }

        if (data) {
            response.data = data;

            //dispatch('domains/getDomains', state.domainsInfoParams, {root:true});
            //dispatch('domains/selectDomain', domainId, {root:true});
        }

        commit('updateRemovedSite', {error, data});
    },
    async updateSite({commit, dispatch}, {domainId, siteId, site}) {
        commit('updateSelectedSite', {error: null, data: null});
        const response = {
            error: null,
            data: null
        };

        let data = null;
        //let error = null;

        console.log('putSiteInfo - ', domainId, siteId);
        try {
            data = await apiClient.putSiteInfo(domainId, siteId, site);
        } catch (e) {
            console.log('putSiteInfo - e ', e);
            //error = e
        }

        if (data) {
            response.data = data;

            //dispatch('domains/getDomains', null, {root:true});
            //dispatch('domains/selectDomain', domainId, {root:true});
        }

        //dispatch('sites/selectSite', site.path.replace(/^\/+/g, ''), {root:true});
        dispatch('sites/getSite', {domainId, siteName:site.path.replace(/^\/|\/$/g, '')}, {root:true});
        return data;
    },
};

const mutations = {
    updateSites(state, resp) {
        console.log('updateSites - ', resp)
        if(resp.data){
            state.sites = resp.data.sites;
            state.sitesCount = resp.data.count;
            state.sitesInfoParams.pageNumber = resp.data.pageNumber;
            state.sitesInfoParams.pageSize = resp.data.pageSize;
        }else{
            state.sites = null;
            state.sitesCount = 0;
        }
        state.error = resp.error
    },
    updateSelectedSite(state, resp) {
        state.selectedSite = resp.data;
        state.error = resp.error
    },
    updateCreatedSite(state, resp) {
        state.createdSite = resp.data;
        console.log('updateCreatedSite', resp.error);
        state.createdSiteError = resp.error
    }
};

const modules = {

};

const sites = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    modules
};
export default sites
