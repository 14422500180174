<template>
  <div class="padding-10">
    <md-progress-spinner md-mode="indeterminate" :md-stroke="stoke" :md-diameter="diameter"></md-progress-spinner>
  </div>
</template>
<script>
  export default{
    name: 'spinner',
    props: {
      mode: {
        type: String,
        default: 'DEFAULT'
      },
      diameter:{
        type: Number,
        default: 60
      },
      stoke:{
        type: Number,
        default: 6
      }
    }
  }
</script>
<style scoped>
.padding-10{
  padding: 10px;
}
</style>
