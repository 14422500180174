<template>
    <md-content>
        <md-toolbar class="md-transparent" md-elevation="0">
            <md-button class="md-icon-button">
                <md-icon>vpn_key</md-icon>
            </md-button>
            <span class="md-title">Key</span>
        </md-toolbar>
        <md-content id="portal" v-if="selectedSite">
            <div>
                <h2>Portal Link</h2>
                <div class="md-layout md-gutter">
                    <div class="md-layout-item">
                        <md-field>
                            <label>Name
                                <md-icon>info_outline
                                    <md-tooltip>tooltip</md-tooltip>
                                </md-icon>
                            </label>
                            <md-input v-model="selectedSite.label" placeholder="Portal name"></md-input>
                            <md-button @click="copyText(selectedSite.label)">
                                <md-icon >content_copy</md-icon>
                            </md-button>
                        </md-field>

                        <div id="security-options" v-if="selectedSite.authentication.authenticationType === 'password'">
                            <label>Portal Security</label>
                            <div class="md-layout">
                                <div class="md-layout-item">
                                    <md-field>
                                        <md-input v-model="selectedSite.authentication.credentials.password" type="password"
                                                  placeholder="Password"></md-input>
                                    </md-field>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="md-layout-item">
                        <md-field>
                            <label>Share URL
                                <md-icon>info_outline
                                    <md-tooltip>tooltip</md-tooltip>
                                </md-icon>
                            </label>
                            <md-input style="margin-top: 15px;" :value="getShareUrl" readonly></md-input>
                            <md-button @click="copyText(getShareUrl)">
                                <md-icon >content_copy</md-icon>
                            </md-button>
                        </md-field>
                    </div>
                </div>
            </div>
            <div>
                <h2>Stream Keys</h2>
                <md-table>
                    <md-table-row>
                        <md-table-head>Camera</md-table-head>
                        <md-table-head>Stream Name</md-table-head>
                        <md-table-head v-if="publishToken">Publishing Token</md-table-head>
                        <!--<md-table-head>Email Info</md-table-head>-->
                    </md-table-row>

                    <md-table-row v-for="(camera, index) in selectedSite.cameras.streams" :key="`camera-${index}`">
                        <md-table-cell>{{camera.label | DefaultCameraLabel(index) }}</md-table-cell>
                        <md-table-cell>
                            <md-field >
                                <md-input :value="camera.streamName" readonly></md-input>
                                <md-button @click="copyText(camera.streamName)">
                                    <md-icon >content_copy</md-icon>
                                </md-button>
                            </md-field>
                        </md-table-cell>
                        <md-table-cell>
                            <md-field v-if="publishToken">
                                <md-input :value="publishToken.token" readonly></md-input>
                                <md-button @click="copyText(publishToken.token)">
                                    <md-icon >content_copy</md-icon>
                                </md-button>
                            </md-field>
                        </md-table-cell>
                        <!--<md-table-cell>
                            <md-icon>mail</md-icon>
                        </md-table-cell>-->
                    </md-table-row>

                </md-table>
            </div>

            <div>
                <h2>RMTP Stream Keys</h2>
                <md-table>
                    <md-table-row>
                        <md-table-head>Camera</md-table-head>
                        <md-table-head>URL</md-table-head>
                        <md-table-head>Stream Key</md-table-head>
                        <!--<md-table-head>Email Info</md-table-head>-->
                    </md-table-row>

                    <md-table-row v-for="(camera, index) in selectedSite.cameras.streams" :key="`camera-${index}`">
                        <md-table-cell>{{camera.label | DefaultCameraLabel(index) }}</md-table-cell>
                        <md-table-cell>
                            <md-field >
                                <md-input :value="getRtmpLink(camera)" readonly></md-input>
                                <md-button @click="copyText(getRtmpLink(camera))">
                                    <md-icon >content_copy</md-icon>
                                </md-button>
                            </md-field>
                        </md-table-cell>
                        <md-table-cell>
                            <md-field >
                                <md-input v-model="camera.streamName" readonly></md-input>
                                <md-button @click="copyText(camera.streamName)">
                                    <md-icon >content_copy</md-icon>
                                </md-button>
                            </md-field>
                        </md-table-cell>
                        <!--<md-table-cell>
                            <md-icon>mail</md-icon>
                        </md-table-cell>-->
                    </md-table-row>


                </md-table>
            </div>

            <div>
                <h2>Broadcast Links</h2>
                <md-table>
                    <md-table-row>
                        <md-table-head>Camera</md-table-head>
                        <md-table-head>Link</md-table-head>
                        <md-table-head>Password</md-table-head>
                        <!--<md-table-head>Email Info</md-table-head>-->
                    </md-table-row>

                    <md-table-row v-for="(camera, index) in selectedSite.cameras.streams" :key="`camera-${index}`">
                        <md-table-cell>{{camera.label | DefaultCameraLabel(index) }}</md-table-cell>
                        <md-table-cell>
                            <md-field >
                                <md-input :value="getLink(camera)" readonly></md-input>
                                <md-button @click="copyText(getLink(camera))">
                                    <md-icon >content_copy</md-icon>
                                </md-button>
                            </md-field>
                        </md-table-cell>
                        <md-table-cell>
                            <md-field >
                                <md-input value="N/A" readonly></md-input>
                                <md-button>
                                    <md-icon >content_copy</md-icon>
                                </md-button>
                            </md-field>
                        </md-table-cell>
                        <!--<md-table-cell>
                            <md-icon>mail</md-icon>
                        </md-table-cell>-->
                    </md-table-row>



                </md-table>
            </div>
        </md-content>
    </md-content>
</template>

<script>

    import {mapActions, mapState} from "vuex";

    let numToSSColumn = (num)=>{
        let s = '', t;

        while (num > 0) {
            t = (num - 1) % 26;
            s = String.fromCharCode(65 + t) + s;
            num = (num - t)/26 | 0;
        }
        return s || undefined;
    };

    export default {
        name: 'Key',
        components: {

        },
        computed: {
            ...mapState({
                selectedDomain(state){
                    return state.domains.selectedDomain
                },
                selectedSite(state) {
                    return state.sites.selectedSite
                },
                publishToken(state) {
                    return state.broadcast.publishToken
                },
                displayDomain(state){
                    return state.domains.displayDomain
                },
            }),
            getShareUrl(){
                return this.selectedDomain ? `${this.displayDomain}${this.selectedSite.path}` : '';
            },
        },
        filters:{
            /**
             * @return {string}
             */
            DefaultCameraLabel(value, index){
                console.log('DefaultCameraLabel - ',value, index);
                return `Camera ${numToSSColumn(index+1)}`;
            }
        },
        methods:{
            ...mapActions({
                'getSite': 'sites/getSite',
                'getPublishToken': 'broadcast/getPublishToken',
            }),

            getLink(camera){
              let domainSplit = this.displayDomain.split('.');
              if(domainSplit.length<=3){
                return `https://${domainSplit[0]}.broadcast.${domainSplit[1]}.${domainSplit[2]}${this.selectedSite.path}/${camera.streamName}`
              }else{
                return `https://${domainSplit[0]}.broadcast.${domainSplit[1]}.${domainSplit[2]}.${domainSplit[domainSplit.length-1]}${this.selectedSite.path}/${camera.streamName}`
              }
            },
            getRtmpLink(){
                return `rtmp://${this.displayDomain}/`
            },
            getWebRtcLink(camera){
                return `https://${this.displayDomain}/${camera.streamName}`
            },
            async run() {
              if (!this.selectedSite) {
                const {_id} = this.selectedDomain;
                const {siteId} = this.$route.params;
                await this.getSite({domainId: _id, siteName: siteId});
              }

              await this.getPublishToken(this.selectedSite.cameras.publishTokenId);
            }
        },
        async created() {
          if(!this.selectedDomain){
            await this.$router.push('/domains');
          }else{
            await this.run();
          }

        },
        data: () => ({})
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .md-switch {
        display: flex;
    }

    table {
        width: 100%;
        table-layout: fixed;
    }
    th {
        text-align: left;

    }
    #camera-options>.md-layout>.md-layout-item>.md-field, #security-options>.md-layout>.md-layout-item>.md-field {
        margin-bottom: 0;
    }

    .md-toggle{
        border-radius: 50px;
    }
</style>
