<template>
    <div>
        <md-table id="portals-table" v-if="searched && searched.length>0" v-model="searched" md-sort="name" md-sort-order="asc" md-fixed-header style="height: 100%;">
            <md-table-toolbar>
                <div class="md-toolbar-section-start">
                    <!--<h1 class="md-title">Portals</h1>-->
                </div>

                <md-field md-clearable class="md-toolbar-section-end">
                    <md-input placeholder="Search by name..." v-model="search" @input="searchOnTable"/>
                </md-field>
            </md-table-toolbar>

            <md-table-empty-state
                    md-label="No portals found"
                    :md-description="`No user found for this '${search}' query. Try a different search term or create a new user.`">
                <!--<md-button class="md-primary md-raised" @click="newUser">Create New User</md-button>-->
            </md-table-empty-state>

            <md-table-row slot="md-table-row" slot-scope="{ item }">
                <!--<md-table-cell md-label="ID" md-sort-by="id" md-numeric>{{ item.id }}</md-table-cell>-->
                <md-table-cell md-label="Created" md-sort-by="created">{{ item.created | moment("ddd, hA") }}</md-table-cell>
                <md-table-cell md-label="Name" md-sort-by="label">{{ item.profile.name }}</md-table-cell>
                <md-table-cell md-label="Email" md-sort-by="url">{{ item.profile.email }}</md-table-cell>
                <md-table-cell md-label="Status" md-sort-by="status">{{ item.status || '' }}</md-table-cell>

                <md-table-cell v-if="type==='Viewers'" :md-label="type">
                    <md-button class="md-icon-button" @click="$router.push(`/viewers/${item.id}`)">
                        <md-icon>groups</md-icon>
                    </md-button>
                </md-table-cell>
            </md-table-row>

        </md-table>
        <md-empty-state v-else
                        :md-icon="iconType"
                        md-label="Create or Update your first Portal"
                        md-description="Creating a Portal, will allow you to make Viewer Portals Accessible to Users.">
            <md-button @click="$router.push('/create/portal')" class="md-primary md-raised">Create first Portal</md-button>
        </md-empty-state>

    </div>
</template>

<script>
    import {mapState} from "vuex";
    import sidebarLinksArray from "../../../services/sidebarLinks";

    const toLower = text => {
        return text.toString().toLowerCase()
    };

    const searchByName = (items, term) => {
        if (term) {
            return items.filter(item => toLower(item.label).includes(toLower(term)))
        }
        return items
    };

    export default {
        name: 'ViewersList',
        props:{
            type:{
                type: String,
                default: 'Viewers'
            }
        },
        computed: {
            ...mapState({
                selectedDomainUsers(state) {
                    return state.domains.selectedDomainUsers
                }

            }),
            searched() {
                return this.selectedDomainUsers;
            },
            iconType(){
                let icon = 'dvr';
                const foundlink = sidebarLinksArray.find((link)=>{
                    return link.name === this.type;
                });
                icon = foundlink  ? foundlink.icon : icon;
                console.log(icon);
                return icon;
            },
            portalBaseUrl(){
                return `${this.selectedDomain.name}.remotefilming.tv`;
            }
        },
        data: () => ({
            search: null,
            //searched: [],
            //portalBaseUrl: 'dyson.remotefilming.tv',
        }),
        methods: {
            newUser() {
                window.alert('Noop')
            },
            searchOnTable() {
                this.searched = searchByName(this.selectedDomainUsers, this.search)
            }
        },
        created() {
            //this.searched = this.portals;
        }
    }
</script>

<style scoped>
    .md-field {
        max-width: 300px;
    }

    .md-card.md-theme-default {

        box-shadow: none !important;
    }

</style>
<style>
    table{
        width: 100% !important;
    }
</style>
