<template>
    <md-content>
        <!--<md-toolbar class="md-transparent" md-elevation="0">
            <md-button class="md-icon-button">
                <md-icon>videocam</md-icon>
            </md-button>
            &lt;!&ndash;<span  class="md-title">{{selectedCamera.label || 'Default'}}</span>&ndash;&gt;
            <md-menu md-size="medium" v-if="hasDevices" class="md-button" md-align-trigger >
                <md-button md-menu-trigger>{{selectedCameraLabel}}</md-button>
                <md-menu-content>
                    <md-menu-item @click="onChangeStream(item, index)" v-for="(item, index) in selectedSite.cameras.streams" :key="`streams-${index}`">
                        <span class="md-list-item-text">{{item.label}}</span>
                    </md-menu-item>
                </md-menu-content>
            </md-menu>
        </md-toolbar>-->
        <transition name="fade" mode="out-in">
            <md-content class="text-center bg-black" id="broadcast" v-if="hasDevices">
                <video id="vidWin" ref="vidWin" autoplay muted playsinline></video>
                <div class="spinner-container" v-if="isChangingStream">
                    <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
                </div>
                <div>
                    <md-bottom-bar md-sync-route>
                        <div class="md-layout md-size-100" style="width: 100%;">
                            <div class="md-layout-item">
                                <md-menu md-size="medium" class="md-button" md-align-trigger>
                                    <md-button md-menu-trigger>{{getSelectedMediaVideoLabel}}</md-button>
                                    <md-menu-content>
                                        <md-menu-item @click="onChangeCam(item.deviceId)" v-for="(item, index) in devices.videoin" :key="`video-${index}`">
                                            <span class="md-list-item-text">{{item.label}}</span>
                                        </md-menu-item>
                                    </md-menu-content>
                                </md-menu>
                                <md-button @click="toggleCam()">
                                    <md-icon>{{ camEnabled ? 'videocam_off': 'videocam'}}</md-icon>
                                </md-button>
                            </div>
                            <div class="md-layout-item text-center">
                                <md-button @click="openMediaSettingsPanel()">
                                    <md-icon>settings</md-icon>
                                </md-button>
                                <md-button @click="onBroadcast()">{{!isBroadcasting ? 'Broadcast': 'Stop'}}</md-button>
                                <md-menu class="md-button">
                                    <md-button md-menu-trigger>
                                        <md-icon>view_module</md-icon>
                                        <!--<div>{{'Cameras'}}</div>-->
                                    </md-button>

                                    <md-menu-content>
                                        <md-menu-item v-for="(item, index) in activeCameras" :key="`cam-${index}`" @click="onChangeStream(item, index)">
                                            <span :class="{'red': selectedCameraStreamName === item.streamName}">{{item.label || `Blank ${numToSSColumn(index + 1)}`}}</span>
                                            <!--<md-button>
                                                {{item.label || `Camera ${index}`}}
                                            </md-button>-->
                                        </md-menu-item>
                                    </md-menu-content>
                                </md-menu>
                            </div>
                            <div class="md-layout-item">
                                <md-button @click="toggleMic()">
                                    <md-icon>{{ micEnabled ? 'mic_off': 'mic'}}</md-icon>
                                </md-button>
                                <md-menu md-size="medium" class="md-button" md-align-trigger>
                                    <md-button md-menu-trigger>{{getSelectedMediaAudioLabel}}</md-button>
                                    <md-menu-content>
                                        <md-menu-item @click="onChangeMic(item.deviceId)" v-for="(item, index) in devices.audioin" :key="`audio-${index}`">
                                            <span class="md-list-item-text">{{item.label}}</span>
                                        </md-menu-item>
                                    </md-menu-content>
                                </md-menu>
                            </div>
                            <!--<div class="md-layout-item">
                                <md-button >
                                    <md-icon>fullscreen</md-icon>
                                </md-button>
                            </div>-->
                        </div>

                        <!--<md-bottom-bar-item md-label="Home" md-icon="home">


                        </md-bottom-bar-item>
                        <md-bottom-bar-item to="/components/bottom-bar/posts" md-label="Posts" md-icon="/assets/icon-whatshot.svg"></md-bottom-bar-item>
                        <md-bottom-bar-item to="/components/bottom-bar/favorites" md-label="Favorites" md-icon="favorite"></md-bottom-bar-item>-->
                    </md-bottom-bar>
                </div>
            </md-content>
            <md-content v-else>
                <div class="md-layout md-gutter md-alignment-center-center">
                    <div class="md-layout-item text-center">
                        <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
                    </div>
                </div>
            </md-content>
        </transition>
        <div>
            <md-dialog :md-active.sync="showMediaSettingsDialog" class="md-accent" @md-clicked-outside="cancelMediaSettings()">

                <md-dialog-title>Media Settings</md-dialog-title>
                <!--                <md-dialog-content v-if="dialogLoading">-->
                <md-dialog-content v-if="!dialogMediaSettingsLoading">
                    <!--<md-field  :class="messageClass">
                        <label>Domain name</label>
                        <md-input v-model="mediaSettings" placeholder="Domain name" required></md-input>
                        <span class="md-error">There is an error</span>
                    </md-field>-->
                    <div class="md-layout md-gutter">
                        <div class="md-layout-item">
                            <div class="md-layout">
                                <div class="md-layout-item">
                                    <md-field>
                                        <label for="format">Format</label>
                                        <md-select v-model="mediaSettings.format" @md-selected="onVideoFormat" :disabled="disabledVideoFormatBtn" name="format" id="format">
                                            <md-option value="h264">h.264</md-option>
                                            <md-option value="vp8">vp8</md-option>
                                            <md-option value="vp9">vp9</md-option>
                                        </md-select>
                                    </md-field>
                                </div>
                                <div class="md-layout-item">
                                    <md-switch v-model="mediaSettings.simulcast" :disabled="disabledSimulcastBtn">Simulcast</md-switch>
                                </div>
                            </div>

                            <md-field>
                                <label for="bitrate">Bitrate</label>
                                <md-select v-model="mediaSettings.bitrate" name="bitrate" id="bitrate" :disabled="disabledBandwidthBtn">
                                    <md-option value="Unlimited">Unlimited</md-option>
                                    <md-option value="2000">2000</md-option>
                                    <md-option value="1000">1000</md-option>
                                    <md-option value="500">500</md-option>
                                    <md-option value="250">250</md-option>
                                    <md-option value="125">125</md-option>
                                </md-select>
                            </md-field>
                            <div class="md-layout">
                                <div class="md-layout-item">
                                    <md-field>
                                        <label>Width</label>
                                        <md-input v-model="selectedConstraints.video.width" @change="onWidth(selectedConstraints.video.width)"></md-input>
                                    </md-field>
                                </div>
                                <div class="md-layout-item">
                                    <md-field>
                                        <label>Height</label>
                                        <md-input v-model="selectedConstraints.video.height" @change="onHeight(selectedConstraints.video.height)"></md-input>
                                    </md-field>
                                </div>
                                <div class="md-layout-item">
                                    <md-field>
                                        <label>FPS</label>
                                        <md-input v-model="selectedConstraints.video.frameRate"></md-input>
                                    </md-field>
                                </div>
                            </div>

                            <div class="md-layout">
                                <div class="md-layout-item">
                                <md-radio v-model="selectedConstraints.video.aspectRatio" :value="16/9" @change="onAspect">16/9</md-radio>
                                <md-radio v-model="selectedConstraints.video.aspectRatio" :value="4/3" @change="onAspect">4/3</md-radio>
                                <md-radio v-model="selectedConstraints.video.aspectRatio" :value="0" @change="onAspect">Custom</md-radio>
                                </div>
                            </div>

                            <md-switch v-model="selectedConstraints.audio.echoCancellation" value="1">ECHO CANCELLATION</md-switch>
                            <!--<md-switch v-model="mediaSettings.audioType" value="1">{{Mono}}</md-switch>-->
                            <!--<br>
                            <small>mediaSettings value: {{ mediaSettings }}</small>
                            <br>
                            <small>selectedConstraints value: {{ selectedConstraints }}</small>
                            <br>
                            <small>previousMediaSettings value: {{ previousMediaSettings }}</small>
                            <br>
                            <small>previousConstraints value: {{ previousConstraints }}</small>-->

                        </div>
                    </div>

                </md-dialog-content>
                <md-dialog-content v-if="dialogMediaSettingsLoading">
                    <div class="md-layout md-gutter md-alignment-center-center">
                        <div class="md-layout-item md-size-40">
                            <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
                        </div>
                    </div>
                </md-dialog-content>
                <md-dialog-actions>
                    <md-button class="md-primary" @click="cancelMediaSettings()">Cancel</md-button>
                    <md-button class="md-primary" @click="updateMediaSettings()">Update</md-button>
                </md-dialog-actions>
            </md-dialog>
        </div>
    </md-content>
</template>

<script>

    import {mapState, mapActions} from "vuex";
    //import MillicastMedia from '@/services/millicast-manager';// eslint-disable-line no-eval
    import '@/services/millicast-manager';
    import SemanticSDP from 'semantic-sdp';

    function getLocalDescription(offer, mediaSettings){
        //
        if(!mediaSettings){
            return offer;
        }
        console.log('getLocalDescription', mediaSettings);
        //support for stereo
        if(mediaSettings.stereo){
            offer.sdp = offer.sdp.replace("useinbandfec=1", "useinbandfec=1; stereo=1");
        }
        //simulcast
        if(mediaSettings.simulcast === true && mediaSettings.format !== 'vp9' && mediaSettings.format !== 'av1'){
            offer = getSimulcast(offer);
        }

        return offer;
    }
    function getRemoteDescription(offer, mediaSettings){
        let bw = mediaSettings.bitrate === 'Unlimited' ? 0 : mediaSettings.bitrate;
        let sdp = getBandwidthRestriction(offer.sdp, bw);


        return new RTCSessionDescription({
            type: 'answer',
            sdp
        });
    }
    function getBandwidthRestriction (sdp, bitrate = 0) {
        //process the SDP as an object
        let offer = SemanticSDP.SDPInfo.process(sdp);
        //get video portion of SDP
        let videoOffer = offer.getMedia("video");

        let _sdp;
        // if the bitrate we want is 0 or less than 1, remove any previous settings
        if (bitrate < 1) {
            _sdp = sdp.replace(/b=AS:.*\r\n/, '').replace(/b=TIAS:.*\r\n/, '');
            // else set new bitrate and convert back to string for re-negotiation of new offer.
        } else {
            videoOffer.setBitrate(bitrate);
            _sdp = offer.toString();
            // check adapter.js file for firefox verison if browser is firefox.
            if (window.adapter) {
                if (_sdp.indexOf('b=AS:') > -1 && window.adapter.browserDetails.browser === 'firefox') {
                    _sdp = _sdp.replace('b=AS:', 'b=TIAS:');
                }
            }
        }
        return _sdp;
    }
    function getSimulcast(offer) {

        //support for multiopus
        ///// temporary patch for now
        let isChromium = window.chrome;
        let winNav = window.navigator;
        let vendorName = winNav.vendor;
        let agent = winNav.userAgent.toLowerCase();
        let isOpera = typeof window.opr !== "undefined";
        let isIEedge = agent.indexOf("edge") > -1;
        // let isEdgium = agent.indexOf("edg") > -1;
        let isIOSChrome = agent.match("crios");

        let isChrome = false;
        if (isIOSChrome) {
            //
        } else if( isChromium !== null && typeof isChromium !== "undefined" &&
            vendorName === "Google Inc." && isOpera === false &&
            isIEedge === false) {/*  && isEdgium === false */
            // is Google Chrome
            isChrome = true;
        }

        try {
            if(isChrome){
                //Get sdp
                let sdp = offer.sdp;
                //OK, chrome way
                const reg1 = RegExp("m=video.*?a=ssrc:(\\d*) cname:(.+?)\\r\\n","s");
                const reg2 = RegExp("m=video.*?a=ssrc:(\\d*) mslabel:(.+?)\\r\\n","s");
                const reg3 = RegExp("m=video.*?a=ssrc:(\\d*) msid:(.+?)\\r\\n","s");
                const reg4 = RegExp("m=video.*?a=ssrc:(\\d*) label:(.+?)\\r\\n","s");
                //Get ssrc and cname
                let res = reg1.exec(sdp);
                const ssrc = res[1];
                const cname = res[2];
                //Get other params
                const mslabel = reg2.exec(sdp)[2];
                const msid = reg3.exec(sdp)[2];
                const label = reg4.exec(sdp)[2];
                //Add simulcasts ssrcs
                const num = 2;
                const ssrcs = [ssrc];
                for (let i=0;i<num;++i)
                {
                    //Create new ssrcs
                    const ssrc = 100+i*2;
                    const rtx   = ssrc+1;
                    //Add to ssrc list
                    ssrcs.push(ssrc);
                    //Add sdp stuff
                    sdp +=	"a=ssrc-group:FID " + ssrc + " " + rtx + "\r\n" +
                        "a=ssrc:" + ssrc + " cname:" + cname + "\r\n" +
                        "a=ssrc:" + ssrc + " msid:" + msid + "\r\n" +
                        "a=ssrc:" + ssrc + " mslabel:" + mslabel + "\r\n" +
                        "a=ssrc:" + ssrc + " label:" + label + "\r\n" +
                        "a=ssrc:" + rtx + " cname:" + cname + "\r\n" +
                        "a=ssrc:" + rtx + " msid:" + msid + "\r\n" +
                        "a=ssrc:" + rtx + " mslabel:" + mslabel + "\r\n" +
                        "a=ssrc:" + rtx + " label:" + label + "\r\n";
                }
                //Conference flag
                sdp += "a=x-google-flag:conference\r\n";
                //Add SIM group
                sdp += "a=ssrc-group:SIM " + ssrcs.join(" ") + "\r\n";
                //Update sdp in offer without the rid stuff
                offer.sdp = sdp;
                //Add RID equivalent to send it to the sfu
                sdp += "a=simulcast:send a;b;c\r\n";
                sdp += "a=rid:a send ssrc="+ssrcs[2]+"\r\n";
                sdp += "a=rid:b send ssrc="+ssrcs[1]+"\r\n";
                sdp += "a=rid:c send ssrc="+ssrcs[0]+"\r\n";
                //Set it back
                // offer.sdp = sdp;
                console.log('* simulcast set!');
            }
        } catch(e) {
            console.error(e);
        }
        return offer;
    }

    export default {
        name: 'Broadcast',
        components: {},
        computed: {
            ...mapState({
              selectedDomain(state) {
                return state.domains.selectedDomain
              },
                selectedSite(state) {
                    return state.sites.selectedSite
                },
                publishToken(state) {
                    return state.broadcast.publishToken
                }
            }),
            /*getSelectedCameraLabel(){
                let response = null;// = this.selectedCamera ? this.selectedCamera.label : null;
                if(this.selectedSite && this.selectedCamera){
                    const camIndex = this.selectedSite.cameras.streams.findIndex((camera)=>{
                        return camera.streamName === this.selectedCamera.streamName;
                    });
                    response = `Camera ${camIndex}`
                }
                return response || 'Default';
            },*/
            activeCameras: function() {
                return this.selectedSite.cameras.streams.filter(function (u) {
                    return u.enabled
                })
            },
            hasDevices(){
                return this.devices;
            },
            isMicMuted(){
                let response = true;
                if(this.stream){
                   response = !this.micEnabled;
                }
                return response;
            },
            getSelectedMediaAudioLabel(){
                if(!this.devices)return '';
                console.log('getSelectedMediaVideoLabel - ',  this.devices, this.millicastMedia.constraints.audio);
                let device = this.devices.audioin.find((device)=>{
                    if(!this.millicastMedia.constraints.audio.deviceId){
                        return false;
                    }
                    return device.deviceId === this.millicastMedia.constraints.audio.deviceId.exact;
                });
                if(!device){
                    device = this.devices.audioin[0]
                }
                return device.label;
            },
            getSelectedMediaVideoLabel(){
                if(!this.devices)return '';
                console.log('getSelectedMediaVideoLabel - ',  this.devices, this.millicastMedia.constraints.video);
                let device = this.devices.videoin.find((device)=>{
                    if(!this.millicastMedia.constraints.video.deviceId){
                        return false;
                    }
                    return device.deviceId === this.millicastMedia.constraints.video.deviceId.exact;
                });
                if(!device){
                    device = this.devices.videoin[0]
                }
                return device.label;
            }
        },
        methods:{
            ...mapActions({
                'getPublishToken': 'broadcast/getPublishToken',
                'selectSite': 'sites/selectSite',
              'getSite': 'sites/getSite'

            }),
            numToSSColumn(num){
                let s = '', t;

                while (num > 0) {
                    t = (num - 1) % 26;
                    s = String.fromCharCode(65 + t) + s;
                    num = (num - t)/26 | 0;
                }
                return s || undefined;
            },
            async getPeerSDP() {
                let own = this;
                let getIceServers = function getIceServers(location = 'https://turn.millicast.com/webrtc/_turn') {
                    const url = location;
                    return new Promise((resolve, reject) => {
                        let xhr = new XMLHttpRequest();

                        xhr.onreadystatechange = () => {
                            if (xhr.readyState !== 4) {
                                return;
                            }

                            if (xhr.status < 200 || xhr.status >= 300) {
                                let error = new Error(`IceServers call failed. StatusCode: ${xhr.status} Response: ${xhr.responseText}`);
                                error.responseStatus = xhr.status;
                                error.responseText = xhr.responseText;
                                error.responseJson = null;
                                reject(error);
                                return;
                            }

                            let jsonResponse = JSON.parse(xhr.responseText);
                            if (!jsonResponse || jsonResponse['s'] !== 'ok') {
                                let error = new Error(`IceServers invalid response. Response: ${xhr.responseText}`);
                                error.responseStatus = xhr.status;
                                error.responseText = xhr.responseText;
                                error.responseJson = jsonResponse;
                                reject(error);
                                return;
                            }

                            // final resolve array
                            let finalServers = [];

                            let credentials = [];
                            let valIceServers = jsonResponse['v']['iceServers'] ? jsonResponse['v']['iceServers'] : jsonResponse['v'] ? jsonResponse['v'] : [];
                            console.log('valIceServers', valIceServers, jsonResponse);
                            for (const server of valIceServers) {
                                // normalize server.urls
                                if (server.url) {
                                    // convert to new url's format if detected
                                    server.urls = [server.url];
                                    delete server.url;
                                } else if (server.urls && !Array.isArray(server.urls)) {
                                    // assuming this is using legacy notation where urls is a single string
                                    server.urls = [server.urls];
                                } else {
                                    // assure we have an array of something
                                    server.urls = [];
                                }

                                // skip empty urls
                                if (!server.urls.length) {
                                    continue;
                                }
                                // now to identify servers with identical credentials

                                // not everything has credentials
                                if (!server.username || !server.credential) {
                                    finalServers.push(server);
                                    continue;
                                }

                                let credIndex = credentials.findIndex((s) => s.username === server.username && s.credential === server.credential);
                                if (credIndex === -1) {
                                    // new credential pair
                                    credentials.push(server);
                                    continue;
                                }

                                // else we want to merge with credIndex
                                let mergeServer = credentials[credIndex];
                                for (const urlStr of server.urls) {
                                    mergeServer.urls.push(urlStr);
                                }
                            }

                            // lets separate udp from tcp and unspecified
                            for (const server of credentials) {
                                let udpUrls = [];
                                let tcpUrls = [];
                                let unspecifiedUrls = [];

                                for (const urlStr of server.urls) {
                                    let queryIndex = urlStr.indexOf('?');
                                    if (queryIndex === -1) {
                                        unspecifiedUrls.push(urlStr);
                                        continue;
                                    }

                                    let queryString = new URLSearchParams(urlStr.substr(queryIndex + 1));
                                    let transport = queryString.get('transport');
                                    switch (transport) {
                                        case 'udp':
                                            udpUrls.push(urlStr);
                                            break;
                                        case 'tcp':
                                            tcpUrls.push(urlStr);
                                            break;
                                        default:
                                            unspecifiedUrls.push(urlStr);
                                            break;
                                    }
                                }

                                if (udpUrls.length) {
                                    let newServer = Object.assign({}, server);
                                    newServer.urls = udpUrls;
                                    finalServers.push(newServer);
                                }
                                if (tcpUrls.length) {
                                    let newServer = Object.assign({}, server);
                                    newServer.urls = tcpUrls;
                                    finalServers.push(newServer);
                                }
                                if (unspecifiedUrls.length) {
                                    let newServer = Object.assign({}, server);
                                    newServer.urls = unspecifiedUrls;
                                    finalServers.push(newServer);
                                }
                            }

                            resolve(finalServers);
                        };

                        xhr.open('PUT', url, true);
                        xhr.setRequestHeader('Accept', 'application/json');
                        xhr.send();
                    });
                };
                let iceServers = null;
                let peerConfig = null;
                let sdp = null;
                try {
                    iceServers = await getIceServers();
                } catch (e) {
                    //throw e;
                    return Promise.reject(e);
                }
                peerConfig = {
                    rtcpMuxPolicy: 'require',  // default
                    bundlePolicy: 'max-bundle', // default is balanced
                    iceServers
                };
                return new Promise((resolve, reject) => {
                    own.peer = new RTCPeerConnection(peerConfig);
                    for (const track of own.stream.getTracks()) {
                        own.peer.addTrack(track, own.stream);
                    }
                    /*peer.onicecandidate = function(){

                    };*/
                    let options = {
                        offerToReceiveAudio: true,
                        offerToReceiveVideo: true,
                    };
                    own.peer.createOffer(options)
                        .then((offer) => {
                            const desc = getLocalDescription(offer, own.mediaSettings);
                            sdp = desc.sdp;
                            return own.peer.setLocalDescription(desc);
                        })

                        .then(() => {
                            // Send the offer to the remote peer using the signaling
                            resolve(sdp);
                        })
                        .catch((e) => {
                            reject(e)
                        });
                });
            },
            async setRemotePeerSDP(sdp) {
                return this.peer.setRemoteDescription({type: 'answer', sdp})
            },
            openMediaSettingsPanel(){
                if (this.isBroadcasting) {
                    alert('You will need to stop broadcasting first.');
                    return;
                }

                this.showMediaSettingsDialog = true;
                let pc = JSON.parse(JSON.stringify(this.selectedConstraints));
                let pms = JSON.parse(JSON.stringify(this.mediaSettings));
                this.previousConstraints = pc;
                this.previousMediaSettings = pms;
            },
            async updateMediaSettings() {
                if(this.peer){
                    let RTCOfferOptions = {offerToReceiveVideo: true, offerToReceiveAudio: true};
                    this.peer.createOffer(RTCOfferOptions)
                        .then((offer) => {
                            const desc = getLocalDescription(offer, this.mediaSettings);
                            return this.peer.setLocalDescription(desc);
                        })
                        .then(() => {
                            const answer = getRemoteDescription(this.peer.remoteDescription, this.mediaSettings);
                            //Set it
                            return this.peer.setRemoteDescription(answer);
                        })
                        /*.then(()=>{
                            return this.getMedia();
                        })*/
                        .then(() => {
                            this.showMediaSettingsDialog = false;
                            this.previousConstraints = this.selectedConstraints;
                        })
                        .catch(() => {
                            //console.error(err);
                            this.showMediaSettingsDialog = false;
                        });
                }else {
                    //await this.getMedia();
                    this.previousConstraints = this.selectedConstraints;
                    this.showMediaSettingsDialog = false;
                }
            },
            cancelMediaSettings(){
                this.showMediaSettingsDialog = false;
                let pc = JSON.parse(JSON.stringify(this.previousConstraints));
                let pms = JSON.parse(JSON.stringify(this.previousMediaSettings));
                setTimeout(()=>{
                    this.mediaSettings =  pms;
                    this.selectedConstraints = pc;
                    console.log('cancelMediaSettings - ', this.mediaSettings,' -- ', this.selectedConstraints);
                },200);

            },
            onVideoFormat(type) {
                console.log(' onVideoFormat:',type);
                // this.disabledVideoFormatBtn = true;
                if(type === 'vp9' || type === 'av1'){
                    this.disabledSimulcastBtn = true;
                    this.mediaSettings.simulcast = false;
                }else{
                    this.disabledSimulcastBtn = false;
                }
            },

            onAspect(e) {
                console.log('onAspect:', e, this.selectedConstraints.video.aspectRatio);
                //this.constraints.video.aspectRatio = e;
                if (e !== 0) {
                    setTimeout(() => {
                        this.onHeight(this.selectedConstraints.video.height);
                    }, 100)
                }
            },
            onWidth(width) {
                console.log(this.selectedConstraints.video.aspectRatio, ' onWidth:', width, ' height:', Math.ceil(width / this.selectedConstraints.video.aspectRatio));
                if (this.selectedConstraints.video.aspectRatio === 0) return;

                this.selectedConstraints.video.height = Math.ceil(width / this.selectedConstraints.video.aspectRatio);
            },
            onHeight(height) {
                console.log(this.selectedConstraints.video.aspectRatio, ' onHeight:', height, ' width:', Math.ceil(height * this.selectedConstraints.video.aspectRatio));
                if (this.selectedConstraints.video.aspectRatio === 0) return;

                this.selectedConstraints.video.width = Math.ceil(height * this.selectedConstraints.video.aspectRatio);
            },

            queryDirectorPublish(publishToken, streamName) {
                let payload = {
                    streamName: streamName
                };
                return this.queryDirectorBase('publish', publishToken, payload);
            },
            queryDirectorBase(queryType, token, payload) {
                switch (queryType) {
                    case 'publish':
                    case 'subscribe':
                        break;
                    default:
                        throw new Error('incorrect queryType used');
                }
                const DIRECTOR_BASE_URL = 'https://director.millicast.com/api/';
                const url = `${DIRECTOR_BASE_URL}director/${queryType}`;
                return new Promise((resolve, reject) => {
                    let xhr = new XMLHttpRequest();

                    xhr.onreadystatechange = () => {
                        if (xhr.readyState !== 4) {
                            return;
                        }

                        if (xhr.status < 200 || xhr.status >= 300) {
                            let error = new Error(`Director (${queryType}) failed. StatusCode: ${xhr.status} Response: ${xhr.responseText}`);
                            error.responseStatus = xhr.status;
                            error.responseText = xhr.responseText;
                            error.responseJson = null;
                            reject(error);
                            return;
                        }

                        let jsonResponse = JSON.parse(xhr.responseText);
                        if (!jsonResponse || jsonResponse.status !== 'success') {
                            let error = new Error(`Invalid Director (${queryType}) response. Response: ${xhr.responseText}`);
                            error.responseStatus = xhr.status;
                            error.responseText = xhr.responseText;
                            error.responseJson = jsonResponse;
                            reject(error);
                            return;
                        }

                        resolve({
                            url: `${jsonResponse.data['urls'][0]}?token=${jsonResponse.data['jwt']}`,
                            jwt: jsonResponse.data['jwt']
                        });
                    };

                    xhr.open('POST', url, true);
                    if (token) {
                        xhr.setRequestHeader('Authorization', `Bearer ${token}`);
                    }
                    xhr.setRequestHeader('Accept', 'application/json');
                    xhr.setRequestHeader('Content-Type', 'application/json');

                    xhr.send(JSON.stringify(payload));
                });
            },
            getWebsocket() {
                let own = this;
                let url = `${this.director.url}`;
                let ws;
                let error;
                if (!!ws && own.websocket.readyState === WebSocket.OPEN) {
                    return Promise.resolve(ws);
                }
                return new Promise((resolve, reject) => {
                    ws = new WebSocket(url);
                    ws.onopen = () => {
                        if (ws.readyState !== WebSocket.OPEN) {
                            error = {state: ws.readyState};
                            return reject(error);
                        }
                        resolve(ws);
                    };
                    ws.onerror = (evt) => {
                        error = evt || true;
                        console.log('ws::onerror', ws.readyState);
                    };
                    ws.onclose = () => {
                        if (error) {
                            reject(`WebSocket Connection Error ${url}`);
                        }
                        console.log('ws::onclose', ws.readyState);
                        if (ws.readyState === 3) {
                            ws = null;
                            this.peer = null;
                        }
                    };
                });
            },
            getRemotePeerSDP(ws, sdp) {
                const onmessageHandler = function (event, resolve, reject) {
                    let jsonMsg;
                    try {
                        let message = event.data;
                        jsonMsg = JSON.parse(message);
                        if (jsonMsg['type'] === 'error') {
                            //console.error(`Signalling error: ${jsonMsg.data}`);
                            return reject(`Signalling error: ${message}`);
                        }

                        // there is only one message ever on a publishing websocket
                        // so we don't need to fully add TransactionManager really
                        // but this is technically bad form
                        // also means we aren't handling response errors at all
                        switch (jsonMsg.type) {
                            case 'response':
                                resolve(jsonMsg.data.sdp);
                                break;
                            case 'event':
                            default:
                                // ignored
                                break;
                        }

                    } catch (err) {
                        //console.error(`Failed to handle signalling message: ${err}`);
                        return reject(`Failed to handle signalling message: ${err}`);
                    }
                };
                return new Promise((resolve, reject) => {
                    let payload = {
                        type: 'cmd',//hangs & no error
                        transId: new Date().getTime(),//no error
                        name: 'publish',
                        data: {
                            sdp:   sdp,
                            codec: 'h264'
                        }
                    };
                    let MILLI_PROTO_VER = Number(this.$route.query.v) === 1 ? 'v1' : 'v2';

                    if(MILLI_PROTO_VER === 'v1'){
                        payload.data.name = this.streamName;
                        payload.data.streamId = this.streamName;
                    }

                    ws.onmessage = async (event) => {
                        await onmessageHandler(event, resolve, reject);
                    };
                    ws.send(JSON.stringify(payload));
                });
            },
            reconnect() {
                this.stopBroadcast();
                setTimeout(() => {
                    this.startBroadcast()
                }, 1000);
            },
            async startBroadcast() {
                try {
                    this.director = await this.queryDirectorPublish(this.publishToken.token, this.selectedCamera.streamName);
                    this.websocket = await this.getWebsocket();
                    this.localSDP = await this.getPeerSDP();
                    this.remoteSDP = await this.getRemotePeerSDP(this.websocket, this.localSDP);
                    this.peer.onconnectionstatechange = () => {
                        console.trace(`iceConnectionState: ${this.peer.iceConnectionState} wsReadyState ${this.websocket.readyState}`);
                        switch (this.peer.iceConnectionState) {
                            case 'connected':
                                //this.disconnecting = false;
                                //this.disconnected = false;
                                break;
                            case 'disconnected':
                                /*if (this.websocket.readyState !== 1) {
                                    this.reconnect();
                                }*/
                                break;
                            case 'failed':
                                /*if (this.websocket.readyState !== 1) {
                                    this.reconnect();
                                }*/
                                break;
                        }
                    };

                    // getRemoteDescription()
                    await this.setRemotePeerSDP(this.remoteSDP);
                    this.isBroadcasting = true;
                    //console.log('success');
                } catch (e) {
                    //console.log(e);
                    //throw e;
                    this.isBroadcasting = false;
                    return Promise.reject(e);
                }
            },
            stopBroadcast() {
                console.log('stopBroadcast');

                if(this.stream){
                    this.peer.close();
                    this.peer = null;
                    this.websocket.close();
                    this.websocket = null;
                }
                this.isBroadcasting = false;
            },
            onChangeStream(item, index){
                if (this.isBroadcasting) {
                    alert('You will need to stop broadcasting first.');
                    return;
                }
                this.isChangingStream = true;
                this.selectedCamera = null;
                const camIndex = this.selectedSite.cameras.streams.findIndex((camera)=>{
                    return camera.streamName === item.streamName;
                });
                //this.selectedCamera.label = this.selectedSite.cameras.streams[camIndex].label;
                let selectedCamera = this.selectedSite.cameras.streams[camIndex];
                this.selectedCamera = {
                    label: selectedCamera.label,
                    streamName: selectedCamera.streamName
                };
                this.selectedCameraLabel = this.selectedCamera.label || 'Camera '+index;
                this.selectedCameraStreamName = this.selectedCamera.streamName;
                this.isChangingStream = false;
                console.log('onChangeCam selectedCamera ', camIndex, item, this.selectedCamera);
            },
            onBroadcast(){
                if(this.isBroadcasting){
                    this.stopBroadcast();
                }else {
                    this.startBroadcast();
                }
            },
            onChangeCam(id) {
                if (this.isBroadcasting) {
                    alert('You will need to stop broadcasting first.');
                    return;
                }

                console.log('onChangeCam id:', id);
                //todo - check current cam, ignore if match else
                if (typeof (this.selectedConstraints.video) !== 'object') {
                    this.selectedConstraints.video = {};
                }
                //update constraints object with new device.
                this.selectedConstraints.video.deviceId = {
                    exact: id
                };
                //refresh our media, then renegociate.
                this.getMedia();
                /*.then(str => {
                    if (!!this.pc) {
                        this.createOffer();
                    }
                }).catch(e => {
                    console.error('Media Fail: ', e);
                })*/
            },
            onChangeMic(id) {
                if (this.isBroadcasting) {
                    alert('You will need to stop broadcasting first.');
                    return;
                }

                console.log('onChangeMic id:', id);
                //todo - check current cam, ignore if match else
                //look up camlist for new cam, and renegotiate
                if (typeof (this.selectedConstraints.audio) !== 'object') {
                    this.selectedConstraints.audio = {};
                }
                //update constraints object with new device.
                this.selectedConstraints.audio.deviceId = {
                    exact: id
                };
                //refresh our media, then renegociate.
                this.getMedia();
                    /*.then(str => {
                        if (!!this.pc) {
                            this.createOffer();
                        }
                    }).catch(e => {
                        console.error('Media Fail: ', e);
                    })*/
            },
            getAssignedConstraints(){
                let assignedConstraints = Object.assign({}, this.constraints,  this.selectedConstraints);

                if(typeof assignedConstraints.video.width !== 'object' && typeof assignedConstraints.video.width !== 'boolean'){
                    //assignedConstraints.video.width = {exact: assignedConstraints.video.width};
                }
                else if(typeof assignedConstraints.video.width === 'object'){
                    //assignedConstraints.video.width = assignedConstraints.video.width.exact ? assignedConstraints.video.width.exact : assignedConstraints.video.width.ideal;
                }

                if(typeof assignedConstraints.video.height !== 'object' && typeof assignedConstraints.video.height !== 'boolean'){
                    //assignedConstraints.video.height = {exact: assignedConstraints.video.height};
                }
                else if(typeof assignedConstraints.video.height === 'object'){
                    //assignedConstraints.video.height = assignedConstraints.video.height.exact ? assignedConstraints.video.height.exact : assignedConstraints.video.height.ideal;
                }

                if(typeof assignedConstraints.video.frameRate !== 'object' && typeof assignedConstraints.video.frameRate !== 'boolean'){
                     // //assignedConstraints.video.frameRate = {exact: assignedConstraints.video.frameRate};
                }
                else if(typeof assignedConstraints.video.frameRate === 'object'){
                    //assignedConstraints.video.frameRate = assignedConstraints.video.frameRate.exact ? assignedConstraints.video.frameRate.exact : assignedConstraints.video.frameRate.ideal;
                }

                if (assignedConstraints.video.advanced && !assignedConstraints.video.aspectRatio) {
                    // //delete assignedConstraints.video.advanced;
                    // assignedConstraints.video.aspectRatio = assignedConstraints.video.width / assignedConstraints.video.height;
                }

                if (assignedConstraints.video.aspectRatio === 0) {
                    assignedConstraints.video.aspectRatio = assignedConstraints.video.width / assignedConstraints.video.height;
                }

                return assignedConstraints;

            },

            getMedia(){
                if (this.millicastMedia) this.millicastMedia = null;
                if (this.stream) {
                    this.stream.getTracks().forEach((track) => {
                        track.stop();
                    });
                    this.stream = null;
                }

                this.millicastMedia = new window.MillicastMedia();
                this.millicastMedia.constraints = this.getAssignedConstraints();
                console.log('AssignedConstraints ',this.millicastMedia.constraints);
                this.millicastMedia.getMedia()
                    .then(str => {
                        this.stream = str;
                        this.micEnabled = this.stream.getAudioTracks()[0].enabled;
                        this.camEnabled = this.stream.getVideoTracks()[0].enabled;
                        /* let vTracks = str.getVideoTracks();
                        if(vTracks){
                          console.log('VID TRACK Settings:',vTracks[0].getSettings());
                        } */
                        //set cam feed to video window so user can see self.

                        return this.getMediaDevices();
                    })
                    .then(()=>{
                        let vidWin = this.$refs.vidWin;
                        if (vidWin) {
                            vidWin.srcObject = this.stream;
                        }
                    })
                    .catch(e => {
                        console.log(e);
                    });
            },
            async getMediaDevices() {
                if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
                    console.error("Could not get list of media devices!  This might not be supported by this browser.");
                    return;
                }
                const displayDevices = (list) => {
                    const aTracks = this.stream.getAudioTracks(),
                        vTracks = this.stream.getVideoTracks();

                    this.activeMic = aTracks.length > 0 ? aTracks[0] : {label: 'none'};
                    this.activeCam = vTracks.length > 0 ? vTracks[0] : {label: 'none'};

                    this.devices = list;
                    console.log('device list: ', this.devices, ', active.mic:', this.activeMic, ', active.cam', this.activeCam);
                };

                if (this.millicastMedia && this.millicastMedia.devices) {
                    displayDevices(this.millicastMedia.devices)
                }

                return Promise.resolve();
            },
            toggleMic() {
                let b = !this.stream.getAudioTracks()[0].enabled;
                this.stream.getAudioTracks()[0].enabled = b;
                this.micEnabled = b;
            },
            toggleCam() {
                let b = !this.stream.getVideoTracks()[0].enabled;
                this.stream.getVideoTracks()[0].enabled = b;
                this.camEnabled = b;
            },
            getCameraLabel(item){
                console.log('getCameraLabel - ',item);
                let response = item.label;
                if(!response){
                    const camIndex = this.selectedSite.cameras.streams.findIndex((camera)=>{
                        return camera.streamName === item.streamName;
                    });
                    response = `Camera ${camIndex}`
                }
                return response;
            },
            async run() {
              this.previousConstraints = this.selectedConstraints;

              if (!this.selectedSite) {
                const {_id} = this.selectedDomain;
                const {siteId} = this.$route.params;
                await this.getSite({domainId: _id, siteName: siteId});
              }

              if (!this.selectedCamera) {
                this.selectedCamera = this.selectedSite.cameras.streams[0];
                this.selectedCameraLabel = this.selectedSite.cameras.streams[0].label || 'Camera 0';
                this.selectedCameraStreamName = this.selectedSite.cameras.streams[0].streamName;
              }

              await this.getPublishToken(this.selectedSite.cameras.publishTokenId);
              this.getMedia();
            }
        },
        data(){
            return {
                selectedCameraStreamName: null,
                millicastMedia: null,

                stream: null,
                devices: null,
                activeCam: {label: 'none'},
                activeMic: {label: 'none'},
                disabledBandwidthBtn: false,
                disabledSimulcastBtn: false,
                disabledVideoFormatBtn: false,
                mediaSettings:{
                    format: 'h264',
                    simulcast: false,
                    stereo: true,
                    bitrate: 'Unlimited'
                },
                previousMediaSettings: {},
                constraints: {
                    audio: {
                        echoCancellation: false,
                        //channelCount: {ideal:2}
                    },
                    video: {
                        width: {min: 640, max: 1920, ideal: 1280},
                        height: {min: 480, max: 1080, ideal: 720},
                        frameRate: {min: 10, max: 60, ideal: 24},
                        advanced: [
                            // additional constraints go here, tried in order until something succeeds
                            // can attempt high level exact constraints, slowly falling back to lower ones
                            {aspectRatio: 16 / 9},
                            {aspectRatio: 4 / 3},
                        ]
                    }
                },
                selectedConstraints: {
                    audio: {
                        echoCancellation: false,
                        //channelCount: {ideal:2}
                    },
                    video: {
                        width: 1280,
                        height: 720,
                        frameRate: 24,
                        aspectRatio: 16 / 9,
                    }
                },
                previousConstraints: {},
                isBroadcasting: false,
                micEnabled: false,
                camEnabled: false,
                director: null,
                websocket:null,
                peer: null,
                localSDP: null,
                remoteSDP: null,
                selectedCameraLabel: '',
                showMediaSettingsDialog: false,
                dialogMediaSettingsLoading: false,
            }
        },
        async created() {
            console.log('created!! ',this.selectedSite);
            if(!this.selectedDomain){
              await this.$router.push('/domains');
            }else{
              await this.run();
            }
        },
        beforeRouteLeave(to, from, next){
            if (this.stream) {
                //this.trackMicVolume(false);
                this.stream.getTracks().forEach((track) => {
                    track.stop();
                });
                this.stream = null;
                //delete this.stream;
            }
            let vidWin = this.$refs.vidWin;
            if (vidWin) {
                vidWin.srcObject = null;
            }
            next();
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    /*#portals {
        display: flex;
        justify-content: center;
        align-items: center;
    }*/
    .md-content.md-table-content.md-scrollbar.md-theme-default{
        height: 100% !important;
        max-height: 100% !important;
    }
    .bg-black{
        background-color: black;
    }
</style>
