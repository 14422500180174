/*const getUserInfo = async()=>{
    console.log('getUserInfo');

    let accessToken = await Vue.prototype.$auth.getAccessToken();
    return new Promise( (resolve, reject)=>{
        let data = null;
        httpClient({
            url:'/api/user',
            method: 'GET',
            //url: resource,
            //data,
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then(function (response) {
                data = response.data;
                resolve(data);
            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);
                reject(error);
            });
    });
};*/

import apiClient from "@/services/apiClient";

const state = () => ({
    status: 'unauthenticated',//'authenticated', 'unauthenticated' 'authenticating'',
    user: null,
    team: null,
    error: null
});

const getters = {

};

const actions = {
    async getUser({commit}) {
        commit('updateUser', {error:null, data:null});

        const response = {
            error:null,
            data:null
        };

        let data = null;
        let error = null;

        try {
            data = await apiClient.getUserInfo();
            //data = await apiClient.getUserInfo();
        } catch (e) {
            //console.log(e);
            error = e
        }
        console.log(error, data);

        if(data){
            response.data = data;
        }

        commit('updateUser', response);
    },
    async updateUser({commit}, payload) {
        commit('updateUser', {error:null, data:null});

        const response = {
            error:null,
            data:null
        };

        let data = null;
        let error = null;

        try {
            data = await apiClient.putUserInfo(payload); // userId, profile, credentials
            //data = await apiClient.getUserInfo();
        } catch (e) {
            //console.log(e);
            error = e
        }
        console.log(error, data);

        if(data){
            response.data = data;
        }

        commit('updateUser', response);
    },
    async getTeam({commit}, teamId) {
        commit('updateTeam', {error:null, data:null});

        const response = {
            error:null,
            data:null
        };

        let data = null;
        let error = null;

        try {
            data = await apiClient.getTeamInfo(teamId);
        } catch (e) {
            console.log('getTeam -', e);
            error = e
        }
        console.log('getTeam', error, 'getTeam data - ', data);

        if(data){
            response.data = data;
        }

        commit('updateTeam', response);
    },
    async updateTeam({commit}, payload) {
        const {domainId, teamId, users} = payload;
        commit('updateTeam', {error:null, data:null});

        const response = {
            error:null,
            data:null
        };

        let data = null;
        let error = null;

        try {
            data = await apiClient.putTeamInfo(domainId, teamId, users);
        } catch (e) {
            console.log('updateTeam -', e);
            error = e
        }
        console.log('updateTeam', error, 'updateTeam data', data);

        if(data){
            response.data = data;
        }

        commit('updateTeam', response);
    },
};

const mutations = {
    updateStatus (state, resp) {
        state.status = resp.data;
        state.error = resp.error
    },
    updateUser (state, resp) {
        state.user = resp.data;
        //if(!state.user.credentials)state.user.credentials = {};
        state.error = resp.error
    },
    updateTeam (state, resp) {
        /*if(resp.data){
            state.team = resp.data.length > 0 ? resp.data : null;
        }else{
            state.team = null;
        }*/
        console.log('updateTeam')
        state.team = resp.data;
        state.error = resp.error;
    },
};

const modules = {};

const authorization = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    modules
};
export default authorization
