<template>
    <md-content>
        <md-toolbar class="md-transparent" md-elevation="0">
            <md-button class="md-icon-button">
                <md-icon>language</md-icon>
            </md-button>
            <span class="md-title">Domains</span>
            <div class="md-toolbar-section-end">
                <md-button v-if="domains" class="md-raised md-primary" @click="onAddDomain">
                    Add New Domain
                </md-button>
                <spinner v-else></spinner>
            </div>
        </md-toolbar>
        <md-content id="portals">
            <domains-list v-bind:domains="domains" :on-page-change="onPageChangeDomain" :on-add="onAddDomain" :on-select="onSelectedDomain" :on-delete="onDeleteDomain"></domains-list>
        </md-content>

        <div id="addDialog">
            <md-dialog :md-active.sync="displayAddDialog" @md-clicked-outside="cancelNew()" class="md-accent">
                <md-dialog-title>Add New Domain</md-dialog-title>
                <md-dialog-content v-if="!dialogAddLoading">
                    <div class="md-layout md-gutter">
                        <div class="md-layout-item">
                            <md-field :class="errorClass">
                                <label>Name</label>
                                <md-input v-model="newDomain.name" placeholder="Name"
                                          required></md-input>
                                <span class="md-error">{{ error ? error.message : 'Domain Name Required' }}</span>
                            </md-field>
                        </div>
                    </div>
                </md-dialog-content>
                <md-dialog-content v-else>
                    <div class="md-layout md-gutter md-alignment-center-center">
                        <div class="md-layout-item md-size-40">
                            <Spinner></Spinner>
                        </div>
                    </div>
                </md-dialog-content>
                <md-dialog-actions v-if="!dialogAddLoading">
                    <md-button class="md-primary" @click="cancelNew()">Cancel</md-button>
                    <md-button class="md-primary" @click="addNew(newDomain)">Add</md-button>
                </md-dialog-actions>
            </md-dialog>
        </div>
        <div id="selectDialog">
          <md-dialog :md-active.sync="displaySelectDialog">

            <md-dialog-title v-if="!dialogSelectLoading">Are you sure?</md-dialog-title>
            <md-dialog-content v-if="dialogSelectLoading">
              <div class="md-layout md-gutter md-alignment-center-center">
                <div class="md-layout-item md-size-40">
                  <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
                </div>
              </div>
            </md-dialog-content>
            <md-dialog-actions v-if="!dialogSelectLoading">
              <md-button class="md-primary" @click="onSelectedCancel">Cancel</md-button>
              <md-button class="md-primary" @click="onSelectedChange">Yes</md-button>
            </md-dialog-actions>
          </md-dialog>
        </div>

    </md-content>
</template>

<script>
    import DomainsList from './Partials/DomainsList.vue'
    import {mapActions, mapMutations, mapState} from "vuex";
    import sidebarLinksArray from "../../services/sidebarLinks";
    import Spinner from "@/views/components/UIComponents/Spinner";

    export default {
        name: 'Domains',
        components: {
          Spinner,
            DomainsList
        },
        computed: {
            ...mapState({
                domains(state) {
                    return state.domains.domains
                },
                selectedDomain(state) {
                    return state.domains.selectedDomain
                },
              domainsInfoParams(state) {
                  console.log('domainsInfoParams')
                return state.domains.domainsInfoParams
              },
              error(state) {
                return state.domains.error
              },
            }),
          displaySelectDialog(){
            return this.showSelectDialog || this.showDeleteDialog;
          },
            errorClass () {
              return {
                'md-invalid': this.error
              }
            },
            iconType(){
                let icon = 'language';
                const found = sidebarLinksArray.find((link)=>{
                    return link.name === this.type;
                });
                icon = found  ? found.icon : icon;
                console.log(icon);
                return icon;
            },
        },
        data(){
            return {
              chosenItem: null,
                displayAddDialog: false,
                dialogAddLoading: false,
                showSelectDialog: false,
                showDeleteDialog: false,
                dialogSelectLoading: false,
                newDomain: {},
            }
        },
        methods: {
            ...mapActions({
                'getDomains': 'domains/getDomains',
                'addDomain': 'domains/addDomain',
                'selectDomain': 'domains/selectDomain',
                'removeDomain': 'domains/removeDomain',
            }),
            ...mapMutations({
              'clearError': 'domains/clearError',
              'updateDomainsInfoParams': 'domains/updateDomainsInfoParams',
            }),
            onPageChangeDomain(info){
              console.log('onPageChangeDomain',info, this.domainsInfoParams);
              const {pageNumber, pageSize} = info;
              let currentPageNumber = this.domainsInfoParams.pageNumber;
              let currentPageSize = this.domainsInfoParams.pageSize;
              if(pageNumber !== currentPageNumber || pageSize !== currentPageSize){
                this.getDomains(info);
                //this.updateDomainsInfoParams(info);
              }
            },
            onSelectedDomain(item) {
              console.log('onSelectedDomain');
              this.chosenItem = item;
              this.showSelectDialog = !!item._id;//this.selectedDomain ? this.selectedDomain._id !== item._id : false;
              this.showDeleteDialog = false;
            },
            onDeleteDomain(item){
              this.chosenItem = item;
              this.showDeleteDialog = !!item._id;//this.selectedDomain ? this.selectedDomain._id !== item._id : false;
              this.showSelectDialog = false;
            },
            async onSelectedChange() {
              if (this.showSelectDialog) {
                await this.selectDomain(this.chosenItem._id);
                this.showSelectDialog = false;
              }
              if (this.showDeleteDialog) {
                await this.removeDomain(this.chosenItem._id);
                this.showDeleteDialog = false;
                await this.getDomains(this.domainsInfoParams);
                this.searched = this.domains;
              }
            },
            onSelectedCancel() {
              this.chosenItem = null;
              this.showSelectDialog = false;
              this.showDeleteDialog= false;
            },
            cancelNew(){
                this.displayAddDialog = false;
                this.newDomain = {};
                this.dialogAddLoading = false;
                if(this.error){
                  this.clearError();
                }
            },
            onAddDomain(){
              this.displayAddDialog = true
            },
            async addNew(domain) {
                this.dialogAddLoading = true;
                const {name} = domain;
                await this.addDomain(name);
                //await this.getDomains();
                this.dialogAddLoading = false;

                await this.getDomains(this.domainsInfoParams);
            },
        },
        created: async function () {
          if(!this.domains){
            await this.getDomains(this.domainsInfoParams);
          }
          console.log('created domains')
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
