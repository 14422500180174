import Vue from 'vue';
import render from './render';
import router from './router';
import store from './store';

import VueRouter from 'vue-router';
import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'

import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import VueMoment from 'vue-moment';
import moment from 'moment-timezone'
import 'numeral';
import vueNumeralFilterInstaller from 'vue-numeral-filter';
import './assets/styles/main.css'

//If you want to copyText from Element
function copyTextFromElement(elementID, elementIconID) {
  let element = document.getElementById(elementID); //select the element
  let icon = document.getElementById(elementIconID);
  let tooltip = document.getElementById(`${elementID}ToolTip`);
  let elementText = element.textContent || element.value; //get the text content from the element

  tooltip.innerText = 'Copied';

  icon.parentElement.style.color = 'blue';
  setTimeout(()=>{
    icon.parentElement.style.color = 'unset';
    tooltip.innerText = 'Copy';
  }, 1000);
  copyText(elementText); //use the copyText function below
}

//If you only want to put some Text in the Clipboard just use this function
// and pass the string to copied as the argument.
function copyText(text) {
  navigator.clipboard.writeText(text);
}

Vue.mixin({
  methods: {
    copyTextFromElement,
    //If you only want to put some Text in the Clipboard just use this function
    // and pass the string to copied as the argument.
    copyText
  }
});

Vue.use(VueMoment, {
  moment,
});
Vue.use(vueNumeralFilterInstaller, { locale: 'en-gb' });

Vue.use(VueMaterial);

import {capitalize} from './services/utils'
Vue.filter('capitalize', capitalize);

import Page from 'v-page';
Vue.use(Page, {
  language: 'en',
  info: false
});

Vue.use(VueRouter);
Vue.config.productionTip = false;
new Vue({
  store,
  router,
  render,
}).$mount('#app');
