<template>
    <md-content>
        <md-toolbar class="md-transparent" md-elevation="0">
            <md-button class="md-icon-button">
                <md-icon>settings</md-icon>
            </md-button>
            <span class="md-title">Profile Settings</span>
        </md-toolbar>
        <md-content id="portal">

            <div>
                <div class="md-layout md-gutter">
                    <div class="md-layout-item">
                        <md-field>
                            <label>Name</label>
                            <md-input v-model="userName" placeholder="Name"></md-input>
                        </md-field>

                        <!--<md-field>
                            <label>Domain</label>
                            <md-input value="dyson.remotefilming.tv/" readonly></md-input><md-input placeholder="Path name" v-model="domain_name"></md-input>
                        </md-field>-->


                    </div>


                    <div class="md-layout-item">

                        <md-field>
                            <label>Email</label>
                            <md-input v-model="this.userInfo.profile.email" placeholder="Email"></md-input>
                        </md-field>

                        <!--<md-field>
                        <label>Logo</label>
                        </md-field>
                        <md-card style="background-color: #2e2e2e">
                            <md-card-media-cover>
                                <md-card-media md-ratio="16:9">
                                    <img src="@/assets/hijack.png" alt="Skyscraper">
                                </md-card-media>

                                <md-card-area>
                                    <md-card-actions class="md-alignment-left">
                                        <md-button class="md-icon-button">
                                            <md-icon>delete</md-icon>
                                        </md-button>
                                        <md-button class="md-icon-button">
                                            <md-icon>edit</md-icon>
                                        </md-button>
                                    </md-card-actions>
                                </md-card-area>
                            </md-card-media-cover>
                        </md-card>-->
                    </div>
                </div>
                <transition name="fade" mode="out-in">
                    <div class="md-layout md-gutter" v-if="showPassword">
                        <div class="md-layout-item">
                            <md-field>
                                <label>Password</label>
                                <md-input v-model="this.userInfo.credentials.password.value" placeholder="Password"></md-input>
                            </md-field>
                        </div>
                        <div class="md-layout-item">
                            <md-field md-toggle-password="true" :class="{ 'md-invalid': passwordConfirmErrorMessage }">
                                <label>Confirm Password</label>
                                <md-input v-model="confirmCredentials" placeholder="Confirm Password"></md-input>
                                <span class="md-error">{{passwordConfirmErrorMessage}}</span>
                            </md-field>

                            <!--<md-field>
                            <label>Logo</label>
                            </md-field>
                            <md-card style="background-color: #2e2e2e">
                                <md-card-media-cover>
                                    <md-card-media md-ratio="16:9">
                                        <img src="@/assets/hijack.png" alt="Skyscraper">
                                    </md-card-media>

                                    <md-card-area>
                                        <md-card-actions class="md-alignment-left">
                                            <md-button class="md-icon-button">
                                                <md-icon>delete</md-icon>
                                            </md-button>
                                            <md-button class="md-icon-button">
                                                <md-icon>edit</md-icon>
                                            </md-button>
                                        </md-card-actions>
                                    </md-card-area>
                                </md-card-media-cover>
                            </md-card>-->
                        </div>
                    </div>
                </transition>
                <div class="w-100">
                    <md-button class="md-raised md-primary"  @click="showPassword = !showPassword">
                        {{ showPassword ? 'CANCEL PASSWORD' : 'CHANGE PASSWORD' }}
                    </md-button>
                    <md-button class="md-raised md-accent" >
                        CANCEL
                    </md-button>
                    <md-button class="md-raised md-primary"  @click="onSave()">
                        SAVE UPDATE
                    </md-button>
                </div>
            </div>

        </md-content>
    </md-content>
</template>

<script>

    import {mapActions, mapState} from "vuex";

    export default {
        name: 'Portal',
        components: {

        },
        computed: {
            ...mapState({
                userInfo(state) {
                    return state.authorization.user
                },
            }),
            confirmCredentials: {
                // getter
                get () {
                    return this.passwordConfirm;
                },
                // setter
                set(newValue) {
                    //this.password = newValue;
                    if(newValue !== this.userInfo.credentials.password.value)this.passwordConfirmErrorMessage = 'Passwords do not match.';
                    this.passwordConfirm = newValue;
                }
            },
            userName: {
                // getter
                get () {
                    return `${this.userInfo.profile.firstName} ${this.userInfo.profile.lastName}`
                },
                // setter
                set(newValue) {
                    let names = newValue.split(' ');
                    if(this.userInfo){
                        this.userInfo.profile.firstName = names[0];
                        this.userInfo.profile.lastName = names[names.length - 1];
                        this.userInfo.profile.displayName = `${this.userInfo.profile.firstName} ${this.userInfo.profile.lastName}`
                    }

                }
            }
        },
        created() {
            //const b = this.isAuthenticated;
            //console.log(b)
        },
        methods: {
            ...mapActions({
                'getUser': 'authorization/getUser',
                'updateUser': 'authorization/updateUser',
            }),
            onSave(){
                console.log('onSave ---', this.userInfo)
                this.updateUser(this.userInfo);
            },
            onCancel(){
                this.getUser();
            }
        },
        data: () => ({
            domain_name: null,
            showPassword: false,
            password: null,
            passwordConfirm: null,
            passwordConfirmErrorMessage: null
        })
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .md-switch {
        display: flex;
    }

    table {
        width: 100%;
        table-layout: fixed;
    }
    th {
        text-align: left;

    }
    #camera-options>.md-layout>.md-layout-item>.md-field, #security-options>.md-layout>.md-layout-item>.md-field {
        margin-bottom: 0;
    }

    .md-toggle{
        border-radius: 50px;
    }
</style>
