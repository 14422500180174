<template>
    <md-content>
        <md-toolbar class="md-transparent" md-elevation="0">
            <md-button class="md-icon-button">
                <md-icon>account_circle</md-icon>
            </md-button>
            <span class="md-title">Viewer</span>
        </md-toolbar>
        <md-content id="portal" v-if="selectedDomainUser">
            <div>
                <div>
                    {{selectedDomainUsers}}
                </div>
            </div>

        </md-content>
    </md-content>
</template>

<script>

    import {mapActions, mapState} from "vuex";

    export default {
        name: 'Viewer',
        components: {

        },
        computed: {
            ...mapState({
                selectedDomain(state) {
                    return state.domains.selectedDomain
                },
                selectedDomainUsers(state) {
                    return state.domains.selectedDomainUsers
                }
            })
        },
        methods:{
            ...mapActions({
                'getDomainUsers': 'domains/getDomainUsers'
            }),
        },
        async created() {
            setTimeout(async ()=>{
                await this.getDomainUsers(this.selectedDomain._id);

                this.selectedDomainUser = this.selectedDomainUsers.find((user)=>{
                    return this.$route.params.userId === user.id;
                });

                console.log('selectedDomainUser', this.selectedDomainUser);
            }, 1500)
        },
        data: () => ({
            selectedDomainUser: null,
        })
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .md-switch {
        display: flex;
    }

    table {
        width: 100%;
        table-layout: fixed;
    }
    th {
        text-align: left;

    }
    #camera-options>.md-layout>.md-layout-item>.md-field, #security-options>.md-layout>.md-layout-item>.md-field {
        margin-bottom: 0;
    }

    .md-toggle{
        border-radius: 50px;
    }
</style>
