<template>
  <div class="page-container h-100">
    <md-app class="h-100">

      <md-app-toolbar class="md-accent" md-elevation="0">
        <md-button class="md-icon-button" @click="toggleMenu">
          <md-icon>menu</md-icon>
        </md-button>
        <img v-show="displayDomain" :class="{'ml-15px': !menuVisible, 'ml-5px': menuVisible}" :src="require(`@/assets/${img}`)" alt=""/>
      </md-app-toolbar>

      <md-app-drawer :md-active.sync="menuVisible" md-persistent="mini">
        <md-toolbar id="profile-bar" class="md-transparent padding-0" md-elevation="0">
          <div class="w-100" >
            <md-list>
              <transition name="fade" mode="out-in">
                <md-list-item v-if="!!userInfo && selectedDomain" class="bg-transparent-hover" @click="updateFavicon()">
                  <md-icon style="margin-right: 10px;">account_circle</md-icon>
                  <span class="md-list-item-text" style="font-weight: bold;">{{selectedDomain.name}}.{{selectedDomain.rootDomain}}
                    <!--                                      <br/>-->
                    <!--                                      <small>{{selectedDomain.name}}</small>-->
                                    </span>
                </md-list-item>
                <md-list-item v-if="!!userInfo && (!selectedDomain && domains && domains.length<=0)" class="bg-transparent-hover">
                  <md-icon>account_circle</md-icon>
                  <span class="md-list-item-text">{{userInfo ? userInfo.profile.login : 'Default User'}}</span>
                </md-list-item>
                <md-list-item v-else>
                  <div class="md-layout md-gutter md-alignment-center-space-between w-inherit m-0">
                    <div class="md-layout-item text-center">
                      <Spinner diameter="30" stoke="3"></Spinner>
                    </div>
                  </div>
                </md-list-item>

              </transition>
            </md-list>
          </div>
          <!--<div class="md-toolbar-section-end">

          </div>-->
        </md-toolbar>
        <sidebar-list></sidebar-list>
      </md-app-drawer>

      <md-app-content>
        <transition name="fade" mode="out-in">
          <Content v-if="!!userInfo"></Content>
          <md-app class="spinner h-100" v-else>
            <md-app-content>
              <div class="md-layout md-gutter md-alignment-center-space-between">
                <div class="md-layout-item text-center">
                  <Spinner></Spinner>
                </div>
              </div>
            </md-app-content>
          </md-app>
        </transition>

        <md-snackbar md-position="center" :md-active.sync="showSnackbar" md-persistent>
          <span>Domain note added. Showing limited messages!</span>
          <md-button class="md-primary" @click="showSnackbar = false">Close</md-button>
        </md-snackbar>
      </md-app-content>
    </md-app>
  </div>
</template>
<script>

import SidebarList from "../Partials/SidebarList";
import Content from './Content.vue'
import Spinner from "../../components/UIComponents/Spinner";
import {mapState, mapActions} from "vuex";

export default {
  components: {
    Spinner,
    Content,
    SidebarList
  },
  computed: {
    ...mapState({
      userInfo(state) {
        return state.authorization.user
      },
      domains(state) {
        return state.domains.domains
      },
      selectedDomain(state) {
        return state.domains.selectedDomain
      },
      displayDomain(state){
        return state.domains.displayDomain
      },
      domainsInfoParams(state) {
        return state.domains.domainsInfoParams
      },
    }),
    img(){
      return this.displayDomain && this.displayDomain.indexOf('remotefilming') >= 0 ?  'remotefilming.png' : 'hijack.png';
    }
  },
  data() {
    return {
      authenticated: false,
      faviconName: 'vue.png',
      showSnackbar: false,
      menuVisible: true,
      showDialog: false,
      showAreYouSureDialog: null,
      domainName: null,
      unselectedDomainId: null,
      hasMessages: null
    }
  },
  async created() {
    await this.getUserInfo();
    let selectedDomainId = localStorage.getItem('selectedDomainId')
    if(selectedDomainId){
      await this.selectDomain(selectedDomainId)
    }
    this.updateFavicon();
  },

  methods: {
    ...mapActions({
      'getUserInfo': 'authorization/getUser',
      'getDomains': 'domains/getDomains',
      'selectDomain': 'domains/selectDomain',
    }),
    updateFavicon(){
      const baseUrl = '/';
      const rel = 'icon';
      const applyIcon = (icon) => {
        document?.head
            .querySelectorAll(`link[rel*="${rel}"]`)
            .forEach(el => el.href = `${baseUrl}${icon}`)
      };

      //this.faviconName = !this.faviconName || this.faviconName === 'vue.png' ? 'favicon-32x32.png' : 'vue.png';
      this.faviconName = this.displayDomain ? this.displayDomain.indexOf('remotefilming') >= 0 ?  'favicon-rf.ico' : 'favicon-hl.png' : null;
      applyIcon(this.faviconName);
    },
    toggleMenu() {
      this.menuVisible = !this.menuVisible
    },
  }
}

</script>

<style scoped>

.md-app {
  min-height: 350px;
}

.md-toolbar .md-button:first-child{
  margin-left: 6px;
}

.md-drawer {
  width: 230px;
  max-width: calc(100vw - 125px);
}

.md-accent {
  background-color: #2e2e2e !important;
}


small {
  font-size: 10px;
}
img{
  width: 35%;
}

.w-inherit{
  width: inherit;
}

.m-0{
  margin: 0;
}

.ml-5px{
  margin-left: .5em;
}
.ml-15px{
  margin-left: 1.5em;
}

.padding-0{
  padding: 0;
}
.bg-transparent-hover {
  background-color: transparent;
}


@media only screen and (min-width: 425px){
  img{
    width: 15%;
  }
}
@media only screen and (min-width: 1440px){
  img{
    width: 10%;
  }
}
</style>
<style>

.md-overlay.md-fixed.md-dialog-overlay{
  z-index: 8;
}
</style>
