<template>
    <div>
        <md-list>
            <md-list-item :disabled="!userInfo"  v-for="(link, index) in sidebarLinks" :key="`links-${index}`"
                          :md-expand="!!link.children" @click="onparentclick(link)">
                <md-icon>{{link.icon}}</md-icon>
                <span class="md-list-item-text">{{link.name}}</span>

                <md-list v-if="link.children" slot="md-expand">
                    <md-list-item :disabled="!userInfo" class="md-inset" v-for="(child, index) in link.children"
                                  :key="`childlinks-${index}`" @click="onchildclick(child.path)">
                        <md-icon>{{child.icon}}</md-icon>
                        <span class="md-list-item-text">{{child.name}}</span>
                    </md-list-item>

                </md-list>
            </md-list-item>
        </md-list>
    </div>
</template>

<script>
    import sidebarLinksArray from "../../../services/sidebarLinks";
    import {mapState} from "vuex";

    export default {
        name: "SidebarList",
        computed:{
            sidebarLinks() {
                return sidebarLinksArray

            },
            ...mapState({
                userInfo(state) {
                    return state.authorization.user
                }
            }),
        },
        methods: {
            onparentclick(link){
                if(!link.children){
                    /*if(link.path === '/logout'){
                        this.logout();
                    }else{
                        this.$router.push(link.path)
                    }*/
                    this.$router.push(link.path)
                }else{
                    return true
                }
            },
            onchildclick(path){
                this.$router.push(path);

            }
        }
    }
</script>

<style scoped>

</style>
