import apiClient from "@/services/apiClient";

const state = () => ({
    publishToken: null,
    subscribeToken: null,
    error: null
});
//silent update?
const getters = {

};

const actions = {
    async getPublishToken({commit}, publishTokenId) {
        commit('updatePublishToken', {error: null, data: null});
        const response = {
            error: null,
            data: null
        };

        let data = null;
        let error = null;

        try {
            data = await apiClient.getPublishTokenInfo(publishTokenId);
        } catch (e) {
            console.log(e);
            error = e
        }
        console.log(error, data);

        if (data) {
            response.data = data;
        }

        commit('updatePublishToken', {error, data});
    },
    async getSubscribeToken({commit}, subscribeTokenId) {
        commit('updateSubscribeToken', {error: null, data: null});
        const response = {
            error: null,
            data: null
        };

        let data = null;
        let error = null;

        try {
            data = await apiClient.getSubscribeTokenInfo(subscribeTokenId);
        } catch (e) {
            console.log(e);
            error = e
        }
        console.log(error, data);

        if (data) {
            response.data = data;
        }

        commit('updateSubscribeToken', {error, data});
    }
};

const mutations = {
    updatePublishToken(state, resp) {
        console.log('PublishToken - ', resp);
        state.publishToken = resp.data;
        state.error = resp.error
    },
    updateSubscribeToken(state, resp) {
        console.log('SubscribeToken - ', resp);
        state.subscribeToken = resp.data;
        state.error = resp.error
    }
};

const modules = {

};

const sites = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    modules
};
export default sites
