<template>
  <md-content>
    <md-toolbar class="md-transparent" md-elevation="0">
      <md-button class="md-icon-button">
        <md-icon>groups</md-icon>
      </md-button>
      <span class="md-title">Viewers</span>
    </md-toolbar>
    <md-content v-if="selectedDomain && selectedDomainUsers">>
      <viewers-list type="Viewers"></viewers-list>
    </md-content>
    <md-app class="h-100" v-else>
      <md-app-content>
        <div class="md-layout md-gutter md-alignment-center-space-between">
          <div class="md-layout-item"></div>
          <div class="md-layout-item text-center">
            <Spinner></Spinner>
          </div>
          <div class="md-layout-item"></div>
        </div>
      </md-app-content>
    </md-app>
  </md-content>
</template>

<script>
import ViewersList from './Partials/ViewersList.vue'
import {mapActions, mapState} from "vuex";
import Spinner from "../components/UIComponents/Spinner";

export default {
  name: 'Viewers',
  components: {
    Spinner,
    ViewersList
  },
  computed: {
    ...mapState({
      selectedDomain(state) {
        return state.domains.selectedDomain
      },
      selectedDomainUsers(state) {
        return state.domains.selectedDomainUsers
      }
    }),
  },
  methods: {
    ...mapActions({
      'getDomainUsers': 'domains/getDomainUsers',
    }),
  },
  async created() {
    //this.updateSelectedSite({data: null, error: null});
    if (this.selectedDomain) {
      await this.getDomainUsers(this.selectedDomain._id);
    } else {
      this.$router.push('/domains');
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.md-content.md-table-content.md-scrollbar.md-theme-default {
  height: 100% !important;
  max-height: 100% !important;
}

</style>
