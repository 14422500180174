import Vue from 'vue';
import Vuex from 'vuex';
import plugins from '@/store/plugins';
import authorization from '@/store/modules/authorization';
import domains from '@/store/modules/domains';
import sites from '@/store/modules/sites';
import broadcast from '@/store/modules/broadcast';
import chat from '@/store/modules/chat';
// import users from '@/store/modules/users';
// import encoders from '@/store/modules/encoders';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {},
    mutations: {},
    actions: {},
    getters: {},
    modules: {
        authorization,
        domains,
        sites,
        broadcast,
        chat
        // users,
        // encoders,
    },
    plugins
})
