<template>
    <md-content>
        <md-toolbar class="md-transparent" md-elevation="0">
            <md-button class="md-icon-button">
                <md-icon>admin_panel_settings</md-icon>
            </md-button>
            <span class="md-title">Team</span>
            <div class="md-toolbar-section-end">
                <md-button @click="displayAddTeam = true">
                    Add New Team Member
                </md-button>
            </div>
        </md-toolbar>
        <md-content id="portals">
            <div class="md-layout-item text-center"  v-if="!team">
                <spinner></spinner>
            </div>
            <team-list v-else-if="team.length >= 1" v-bind:team="team"></team-list>
            <md-empty-state  v-else
                            :md-icon="iconType"
                            md-label="Add your first Team member"
                            md-description="Adding a team member, will allow members control over sites.">
                <md-button class="md-primary md-raised" @click="displayAddTeam = true">Add first Team Member</md-button>
            </md-empty-state>

        </md-content>
        <div>
            <md-dialog :md-active.sync="displayAddTeam" class="md-accent">

                <md-dialog-title>Add New Team Member</md-dialog-title>

                <md-dialog-content v-if="!dialogAddTeamLoading">
                    <div class="md-layout md-gutter">
                        <div class="md-layout-item">
                            <md-field :class="firstNameError">
                                <label>First name</label>
                                <md-input v-model="newUser.profile.firstName" placeholder="First name"
                                          required></md-input>
                                <span class="md-error">There is an error</span>
                            </md-field>
                        </div>
                        <div class="md-layout-item">
                            <md-field :class="lastNameError">
                                <label>Last name</label>
                                <md-input v-model="newUser.profile.lastName" placeholder="Last name"
                                          required></md-input>
                                <span class="md-error">There is an error</span>
                            </md-field>
                        </div>
                    </div>
                    <md-field :class="userEmailError">
                        <label>Email</label>
                        <md-input v-model="newUser.profile.email" placeholder="Email" required></md-input>
                        <span class="md-error">There is an error</span>
                    </md-field>
                </md-dialog-content>
                <md-dialog-content v-if="dialogAddTeamLoading">
                    <div class="md-layout md-gutter md-alignment-center-center">
                        <div class="md-layout-item md-size-40">
                            <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
                        </div>
                    </div>
                </md-dialog-content>
                <md-dialog-actions v-if="!dialogAddTeamLoading">
                    <md-button class="md-primary" @click="cancelNewTeamMember()">Cancel</md-button>
                    <md-button class="md-primary" @click="addNewTeamMember(newUser)">Add</md-button>
                </md-dialog-actions>
            </md-dialog>
        </div>
    </md-content>
</template>

<script>
    import TeamList from './Partials/TeamList.vue'
    import {mapActions, mapState} from "vuex";
    import sidebarLinksArray from "../../services/sidebarLinks";
    import Spinner from "../components/UIComponents/Spinner";

    export default {
        name: 'Team',
        components: {
            TeamList,
            Spinner
        },
        computed: {
            ...mapState({
                team(state) {
                    return state.authorization.team
                },
                selectedDomain(state) {
                    return state.domains.selectedDomain
                }
            }),
            iconType(){
                let icon = 'admin_panel_settings';
                const foundlink = sidebarLinksArray.find((link)=>{
                    return link.name === this.type;
                });
                icon = foundlink  ? foundlink.icon : icon;
                console.log(icon);
                return icon;
            },
        },
        data(){
            return {
                displayAddTeam: false,
                dialogAddTeamLoading: false,
                newUser: {profile: {}},
                //team: null
            }
        },
        methods: {
            ...mapActions({
                'getTeam': 'authorization/getTeam',
                'updateTeam': 'authorization/updateTeam',
            }),
            validateEmail(x) {
                console.log('validateEmail - ', x);
                let emailValue = x;
                let reg = /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;
                if (!reg.test(emailValue)) {
                    console.log('invalid email')
                    let index = this.selectedSite.authentication.users.findIndex((email) => {
                        return email === emailValue;
                    })
                    this.selectedSite.authentication.users.splice(index, 1);
                    //console.log(index);
                    this.hasUserEmailError = true;
                    setTimeout(() => {
                        this.hasUserEmailError = false;
                    }, 2000);
                } else {
                    console.log('valid email')
                }
                console.log('users - ', this.selectedSite.authentication.users)
            },
            cancelNewTeamMember(){
                this.displayAddTeam = false;
                this.newUser = {profile: {}};
                this.dialogAddTeamLoading = false;
            },
            async addNewTeamMember(nUser) {
              console.log(nUser);
              this.dialogAddTeamLoading = true;
              await this.updateTeam({domainId: this.selectedDomain._id, teamId: this.selectedDomain.teamId, users: [nUser]});
              this.displayAddTeam = false;
              this.newUser = {profile: {}};
              this.dialogAddTeamLoading = false;
            },
        },
        created: async function () {
            if(this.selectedDomain && !this.team){
              await this.getTeam(this.selectedDomain._id);
            }else{
              //todo - handle
            }
        }
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .md-content.md-table-content.md-scrollbar.md-theme-default{
        height: 100% !important;
        max-height: 100% !important;
    }

</style>
