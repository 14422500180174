import httpClient from "./httpClient";
import Vue from "vue";
const onRejected = function (error, reject) {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        console.log(error.request);
    } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
    }
    console.log(error.config);
    reject(error);
}
const getUserInfo = async()=>{
    console.log('getUserInfo');

    let accessToken = await Vue.prototype.$auth.getAccessToken();
    return new Promise( (resolve, reject)=>{
        let data = null;
        httpClient({
            url:'/api/user',
            method: 'GET',
            //url: resource,
            //data,
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then(function (response) {
                data = response.data;
                resolve(data);
            })
            .catch((error)=>{
                onRejected(error, reject)
            });
    });
};
const putUserInfo = async (payload) => {
    console.log('postDomainInfo');
    const {userId, profile, credentials} = payload;

    let accessToken = await Vue.prototype.$auth.getAccessToken();
    return new Promise((resolve, reject) => {
        let data = {
            profile, //Object
            credentials, //Object
        };
        httpClient({
            url: `/api/user/${userId}`,
            method: 'PUT',
            //url: resource,
            data,
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then(function (response) {
                data = response.data;
                resolve(data);
            })
            .catch((error)=>{
                onRejected(error, reject)
            });
    });
};

const getDomainsInfo = async (params=null) => {
    console.log('getDomainsInfo');

    let accessToken = await Vue.prototype.$auth.getAccessToken();
    return new Promise((resolve, reject) => {
        let data = null;
        httpClient({
            url: '/api/domains',
            method: 'GET',
            //url: resource,
            //data,
            params,
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then(function (response) {
                data = response.data;
                resolve(data);
            })
            .catch((error)=>{
                onRejected(error, reject)
            });
    });
};
const getDomainInfo = async (domainId) => {
    console.log('getDomainInfo');

    let accessToken = await Vue.prototype.$auth.getAccessToken();
    return new Promise((resolve, reject) => {
        let data = null;
        httpClient({
            url: `/api/domains/${domainId}`,
            method: 'GET',
            //url: resource,
            //data,
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then(function (response) {
                data = response.data;
                resolve(data);
            })
            .catch((error)=>{
                onRejected(error, reject)
            });
    });
};
const postDomainInfo = async (name, rootDomain) => {
    console.log('postDomainInfo');

    let accessToken = await Vue.prototype.$auth.getAccessToken();
    return new Promise((resolve, reject) => {
        let data = {
            name,
            rootDomain
        };
        httpClient({
            url: `/api/domains/`,
            method: 'POST',
            //url: resource,
            data,
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then(function (response) {
                data = response.data;
                resolve(data);
            })
            .catch(function (error) {
                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    console.log(error.response.data);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                    reject(error.response.data);
                } else if (error.request) {
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
                console.log(error.config);

            });
    });
};
const deleteDomainInfo = async (domainId) => {
    console.log('getDomainInfo');

    let accessToken = await Vue.prototype.$auth.getAccessToken();
    return new Promise((resolve, reject) => {
        let data = null;
        httpClient({
            url: `/api/domains/${domainId}`,
            method: 'DELETE',
            //url: resource,
            //data,
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then(function (response) {
                data = response.data;
                resolve(data);
            })
            .catch((error)=>{
                onRejected(error, reject)
            });
    });
};

const getPublishTokenInfo = async(publishTokenId)=>{
    console.log('getPublishTokenInfo');

    let accessToken = await Vue.prototype.$auth.getAccessToken();
    return new Promise( (resolve, reject)=>{
        let data = null;
        httpClient({
            url:`/api/publish/${publishTokenId}`,
            method: 'GET',
            //url: resource,
            //data,
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then(function (response) {
                data = response.data;
                resolve(data);
            })
            .catch((error)=>{
                onRejected(error, reject)
            });
    });
};
const getSubscribeTokenInfo = async(subscribeTokenId)=>{
    console.log('getSubscribeTokenInfo');

    let accessToken = await Vue.prototype.$auth.getAccessToken();
    return new Promise( (resolve, reject)=>{
        let data = null;
        httpClient({
            url:`/api/subscribe/${subscribeTokenId}`,
            method: 'GET',
            //url: resource,
            //data,
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then(function (response) {
                data = response.data;
                resolve(data);
            })
            .catch((error)=>{
                onRejected(error, reject)
            });
    });
};

const getChatTokenInfo = async(channelName)=>{
    console.log('getChatTokenInfo');

    let accessToken = await Vue.prototype.$auth.getAccessToken();
    return new Promise( (resolve, reject)=>{
        let data = null;
        httpClient({
            url:`/api/rocketchat/token/${channelName}`,
            method: 'GET',
            //url: resource,
            //data,
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then(function (response) {
                data = response.data;
                resolve(data);
            })
            .catch((error)=>{
                onRejected(error, reject)
            });
    });
};

const getSitesInfo = async (domainId, params=null) => {
    console.log('getDomainInfo');

    let accessToken = await Vue.prototype.$auth.getAccessToken();
    return new Promise((resolve, reject) => {
        let data = null;
        httpClient({
            url: `/api/domains/${domainId}/sites`,
            method: 'GET',
            //url: resource,
            //data,
            params,
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then(function (response) {
                data = response.data;
                resolve(data);
            })
            .catch((error)=>{
                onRejected(error, reject)
            });
    });
};
const getSiteInfo = async (payload) => {
    console.log('getDomainInfo');
    const {domainId, siteName} = payload;
    let accessToken = await Vue.prototype.$auth.getAccessToken();
    return new Promise((resolve, reject) => {
        let data = null;
        httpClient({
            url: `/api/domains/${domainId}/sites/${siteName}`,
            method: 'GET',
            //url: resource,
            //data,
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then(function (response) {
                data = response.data;
                resolve(data);
            })
            .catch((error)=>{
                onRejected(error, reject)
            });
    });
};
const postSiteInfo = async (domainId, site) =>{
    let accessToken = await Vue.prototype.$auth.getAccessToken();
    return new Promise((resolve, reject) => {
        let data = site;
        httpClient({
            ///domains/:domainId/sites
            url: `/api/domains/${domainId}/sites`,
            method: 'POST',
            //url: resource,
            data,
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then(function (response) {
                data = response.data;
                resolve(data);
            })
            .catch((error)=>{
                onRejected(error, reject)
            });
    });
};
const putSiteInfo = async (domainId, siteId, site) =>{
    let accessToken = await Vue.prototype.$auth.getAccessToken();
    return new Promise((resolve, reject) => {
        let data = site;
        httpClient({
            ///domains/:domainId/sites
            url: `/api/domains/${domainId}/sites/${siteId}`,
            method: 'PUT',
            //url: resource,
            data,
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then(function (response) {
                data = response.data;
                resolve(data);
            })
            .catch((error)=>{
                onRejected(error, reject)
            });
    });
};
const deleteSiteInfo = async (domainId, siteId) =>{
    let accessToken = await Vue.prototype.$auth.getAccessToken();
    return new Promise((resolve, reject) => {
        let data = {};
        httpClient({
            ///domains/:domainId/sites
            url: `/api/domains/${domainId}/sites/${siteId}`,
            method: 'DELETE',
            //url: resource,
            data,
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then(function (response) {
                data = response.data;
                resolve(data);
            })
            .catch((error)=>{
                onRejected(error, reject)
            });
    });
};

const postSiteLogo = async (domainId, siteId, site) =>{
    let accessToken = await Vue.prototype.$auth.getAccessToken();
    return new Promise((resolve, reject) => {
        let data = site;
        httpClient({
            ////domains/:domainId/sites/:siteId/logo
            url: `/api/domains/${domainId}/sites/${siteId}/logo`,
            method: 'POST',
            //url: resource,
            data,
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then(function (response) {
                data = response.data;
                resolve(data);
            })
            .catch((error)=>{
                onRejected(error, reject)
            });
    });
};
const getDomainUsers = async (domainId) => {
    //const = '/domains/:domainId/users';
    let accessToken = await Vue.prototype.$auth.getAccessToken();
    return new Promise((resolve, reject) => {
        let data = null;
        httpClient({
            url: `/api/domains/${domainId}/users`,
            //url:`/api/rocketchat/token/${channelName}`,
            method: 'GET',
            //url: resource,
            //data,
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then(function (response) {
                data = response.data;
                resolve(data);
            })
            .catch((error)=>{
                onRejected(error, reject)
            });
    });
};

const getTeamInfo = async(domainId)=>{
    console.log('getTeamInfo');

    let accessToken = await Vue.prototype.$auth.getAccessToken();
    return new Promise( (resolve, reject)=>{
        let data = null;
        httpClient({
            url:`/api/domains/${domainId}/team`,
            method: 'GET',
            //url: resource,
            //data,
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then(function (response) {
                data = response.data;
                resolve(data);
            })
            .catch((error)=>{
                onRejected(error, reject)
            });
    });
};
const putTeamInfo = async(domainId, teamId, users)=>{
    console.log('postTeamInfo');

    let accessToken = await Vue.prototype.$auth.getAccessToken();
    return new Promise((resolve, reject) => {
        let data = {
            users
        };
        httpClient({
            url: `/api/domains/${domainId}/team/${teamId}`,
            method: 'PUT',
            //url: resource,
            data,
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
            .then(function (response) {
                data = response.data;
                resolve(data);
            })
            .catch((error)=>{
                onRejected(error, reject)
            });
    });
};

export default {
    getDomainsInfo,
    getDomainInfo,
    getUserInfo,
    putUserInfo,
    postDomainInfo,
    deleteDomainInfo,
    getSitesInfo,
    getSiteInfo,
    postSiteInfo,
    putSiteInfo,
    postSiteLogo,
    deleteSiteInfo,
    getPublishTokenInfo,
    getSubscribeTokenInfo,
    getChatTokenInfo,
    getDomainUsers,
    getTeamInfo,
    putTeamInfo,
};
