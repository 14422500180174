<template>
  <div class="h-100">
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
  //import Spinner from './views/components/UIComponents/Spinner'


export default {
  name: 'App',
  components: {
    //Spinner
  },
  data () {
    return {
      activeUser: null
    }
  },
  async created () {
  },
  watch: {
    //
  },
  methods: {
    async logout() {
      //
    }
  }
}
</script>


<style>
  @import url("https://fonts.googleapis.com/css?family=Material+Icons");

  .md-app-scroller{
    overflow: hidden;
    overflow-y: scroll;
  }
  .spinner main .md-app-scroller{
    overflow:revert;
  }
  .spinner main .md-app-scroller .md-content.md-app-content.md-flex.md-theme-default{
    border: none;
  }
  .vmdtp_header{
    background-color: rgba(0,0,0,.54)!important;
  }
  div.vmdtp_buttons>.vmdtp_button{
    color: rgba(0,0,0,.54)!important;
  }

  .vmdtp_main__item span.selected{
    background-color: rgba(0,0,0,.54)!important;
  }
  .vmdtp_header, button.vmdtp_button.vmdtp_button--left.active, button.vmdtp_button.vmdtp_button--right.active{
    background-color: rgba(0,0,0,.54)!important;
    color: #ffffff;
  }
  .dz-details{
    background-color: rgba(0,0,0,.54)!important;
  }

  button.md-button.md-raised.md-primary.md-theme-default{
    background-color: rgba(0,0,0,.54) !important;
  }
  .vmdtp_clock .vmdtp_arrow, .vmdtp_clock .vmdtp_center-point{
    background-color: rgba(0,0,0,.54) !important;
  }
  li.vmdtp_item{
    background-color: rgba(224, 224, 224, 0.75) !important;
  }

  .vmdtp_clock .vmdtp_arrow:before{
    background-color: rgba(2, 2, 2, 0.54) !important;
  }

  .fade-enter-active, .fade-leave-active {
  transition: opacity .5s
}
.fade-enter, .fade-leave-to /* .fade-leave-active in <2.1.8 */ {
  opacity: 0
}

  .md-app-container{
    overflow-x: hidden!important;
  }

  .md-inset>.md-list-item-container>.md-list-item-content {
    padding-left: 2em !important;
  }

  .md-list-item-content.md-ripple:hover {
    background-color: transparent;
  }

  #vidWin{
    width: 100%;
  }

  .red{
    color: red !important;
  }
  .red-background{
    background-color: red !important;
  }
  .white{
    color: white !important;
  }
  .text-left{
    text-align: left !important;
  }
  .text-right{
    text-align: right !important;
  }
  .md-button {
    text-transform: none
  }

  .spinner-container{
    position: absolute;
    color: white;
    width: 100%;
    z-index: 10;
    top: 45%;
  }

</style>
