<template>
  <md-content>
    <md-toolbar class="md-transparent w-100" md-elevation="0">

      <div class="md-toolbar-section-end">
        <md-button @click="$router.push('/')">
          CANCEL
        </md-button>
        <md-button class="md-raised md-primary" @click="updatePortal()">
          CREATE PORTAL
        </md-button>
      </div>
    </md-toolbar>
    <md-content id="portal">

      <div v-show="selectedSiteStatus">
        <div class="md-layout md-gutter" v-if="selectedSite">
          <div class="md-layout-item">
            <md-field :class="{ 'md-invalid': hasSiteLabelError }">
              <label>Name</label>
              <md-input auto-complete="off" v-model="selectedSite.label" placeholder="Portal name"
                        aria-required="true"></md-input>
              <span class="md-error">{{ siteLabelErrorMessage }}</span>
            </md-field>

            <div id="security-options">
              <label>Portal Security</label>

              <!--<div class="md-layout" disabled="true">
                  <div class="md-layout-item">
                      <md-switch style="margin-top: 25px;" value="password">Universal Password</md-switch>
                  </div>
                  <div class="md-layout-item">
                      <md-field>
                          &lt;!&ndash;<label>Password toggle</label>&ndash;&gt;
                          <md-input autocomplete="new-password" type="password" placeholder="Password"></md-input>
                      </md-field>
                  </div>
              </div>-->

              <div class="md-layout">
                <div class="md-layout-item">
                  <md-switch style="margin-top: 25px;"
                             v-model="selectedSite.authentication.authenticationType"
                             value="password">Universal Password
                  </md-switch>
                </div>
                <div class="md-layout-item">
                  <md-field>
                    <!--<label>Password toggle</label>-->
                    <md-input v-model="getCredentials" type="password" aria-autocomplete="none"
                              :required="selectedSite.authentication.authenticationType === 'password'"
                              placeholder="Password"></md-input>
                  </md-field>
                </div>
              </div>
              <!--<md-switch v-model="selectedSite.authentication.authenticationType" value="passwordless">Password-less</md-switch>-->
              <md-switch v-model="selectedSite.authentication.authenticationType" value="user-managed">User Managed
              </md-switch>
              <!--<md-switch v-model="selectedSite.authentication.authenticationType" value="multi-factor">Multi Factor Authentication</md-switch>-->
            </div>
            <hr style="margin-top: 20px;"/>
            <label>Portal Options</label>

            <md-switch v-model="selectedSite.chat.enabled">Chat function</md-switch>
            <md-switch v-model="selectedSite.display.showViewers">Viewers function</md-switch>
            <!--<md-switch disabled="">Video Chat function</md-switch>-->
            <!--<md-switch disabled="" v-model="selectedSite.authentication.autoInvite">Send invitation email to new viewers when portal is updated</md-switch>-->
            <md-switch v-model="selectedSite.display.showCameraLabels">Show Camera labels</md-switch>
            <md-switch v-model="selectedSite.display.showLiveDuration">Show live timecodes duration</md-switch>
            <!--<md-switch disabled="">Allow users to select which cameras they view</md-switch>-->


            <hr style="margin-top: 20px;"/>
            <div id="camera-options">
              <div class="md-layout">
                <div class="md-layout-item md-size-70">
                  <label>Multiple Cameras</label>
                </div>
                <div class="md-layout-item md-size-30 text-center">
                  <md-button @click="selectedSite.cameras.push({ enabled: true, label: null })">
                    <!--<md-icon >add_circle_outline</md-icon>-->
                    ADD
                  </md-button>
                </div>
              </div>

              <div class="md-layout" v-for="(camera, index) in selectedSite.cameras" :key="`camera-${index}`">
                <div class="md-layout-item md-size-30">
                  <md-switch style="margin-top: 25px;" v-model="camera.enabled">{{ numToSSColumn(index + 1) }}
                  </md-switch>
                </div>
                <div class="md-layout md-layout-item md-size-70">
                  <div class="md-layout-item md-size-70">
                    <md-field>
                      <label>*Label</label>
                      <md-input v-model="camera.label" placeholder="Camera label"></md-input>
                    </md-field>
                  </div>
                  <div class="md-layout-item md-size-30" v-if="selectedSite.cameras.length > 1">
                    <md-button @click="removeCamera(index)">
                      <md-icon>remove_circle_outline</md-icon>
                    </md-button>
                  </div>
                </div>
              </div>


            </div>
          </div>


          <div class="md-layout-item">
            <md-field :class="sitePathError">
              <label>Domain</label>
              <md-input aria-autocomplete="none" :value="`${this.displayDomain}/`" readonly></md-input>
              <md-input v-model="selectedSite.path" placeholder="Path name" aria-required="true"></md-input>
              <span class="md-error">{{ sitePathErrorMessage || createdSiteError }}</span>
            </md-field>

            <label>{{ selectedSite.authentication.authenticationType !== 'password' ? 'Viewers' : 'Share URL' }}
              <!--<md-icon>info_outline
                <md-tooltip>tooltip</md-tooltip>
              </md-icon>-->
            </label>
            <md-field class="border-none" style="margin: 0; padding-top: 0;"
                      v-if="selectedSite.authentication.authenticationType !== 'password'">
              <!--<md-chips style="margin-top: 0;" md-deletable="true" v-model="usersEmails"
                        md-placeholder="Add Viewer..."></md-chips>
              <md-button @click="displayAddUserModal = true">ADD
              </md-button>-->

              <div v-if="authentication.users.length<1" @click="displayAddUserModal = true" style="cursor: not-allowed;    width: calc(100% - 88px);    position: absolute;    height: 100%;    z-index: 30;"></div>
              <md-chips style="margin-top: 0;" :md-limit="authentication.users.length" md-deletable="true" v-model="authentication.users"
                        :md-format="formatChip"></md-chips>
              <md-button @click="displayAddUserModal = true">ADD
              </md-button>
              <!--<span class="md-error">Invalid Email</span>-->
            </md-field>
            <md-field v-else style="margin-top: 0; padding-top: 0;">
              <!--<label>Share URL
                  <md-icon>info_outline
                      <md-tooltip>tooltip</md-tooltip>
                  </md-icon>
              </label>-->
              <md-input style="margin-top: 15px;" :value="`https://${getDomainUrl}/${selectedSite.path}`"
                        readonly></md-input>
              <md-button @click="copyText(`https://${getDomainUrl}/${selectedSite.path}`)">
                <md-icon>content_copy</md-icon>
              </md-button>
            </md-field>

            <div class="md-layout">
              <div class="md-layout-item" style="margin-bottom: 25px;" :class="{'md-invalid': siteExpirationErrorMessage || createdSiteError}">
                <label style="">Expiration
                  <!--<md-icon>info_outline
                    <md-tooltip>tooltip</md-tooltip>
                  </md-icon>-->
                </label>
                <div class="md-layout-item md-layout">
                  <div class="md-layout-item">
                    <div class="expirationContainer">
                      <VueMaterialDateTimePicker placeholder="Click here to set expiration" v-model="selectedSite.expiration.date"
                                                 :is-date-only="false"></VueMaterialDateTimePicker>
                    </div>
                  </div>
                  <div class="md-layout-item md-size-20">
                    <md-button @click="selectedSite.expiration.date=null">CLEAR</md-button>
                  </div>
                </div>
                <span class="md-error">{{ siteExpirationErrorMessage || createdSiteError }}</span>
              </div>
            </div>

            <div>
              <label>Portal State</label>
              <div class="md-layout">
                <div class="md-layout-item">
                  <md-field style="margin-top: 0;">
                    <md-select v-model="selectedSite.state" name="state" id="state">
                      <md-option value="enabled">Enabled</md-option>
                      <md-option value="disabled">Disabled</md-option>
                      <md-option disabled value="error">Error</md-option>
                      <md-option disabled value="expired">Expired</md-option>
                    </md-select>
                  </md-field>
                </div>
              </div>
            </div>

            <label>Branding
              <!--<md-icon>info_outline
                <md-tooltip>tooltip</md-tooltip>
              </md-icon>-->
            </label>
            <md-card class="dz-card" v-if="dropOptions">
              <md-card-media-cover>
                <md-card-media md-ratio="16:9">
                  <img v-if="selectedSite.brands.main && !showDropzone"
                       :src="require(`@/assets/${selectedSite.brands.main}`)" alt="Skyscraper">
                  <vue-dropzone disabled="" @vdropzone-file-added="onFileAdded"
                                v-show="showDropzone || (!selectedSite.brands.main)" ref="dropzone"
                                :options="dropOptions" @vdropzone-success="onLogoUploadSuccess">
                  </vue-dropzone>
                  <!--                                  <md-button v-show="showDropzone" class="md-icon-button close-btn"  @click="showDropzone = false">
                                                        <md-icon>close</md-icon>
                                                    </md-button>-->
                </md-card-media>

                <md-card-area v-show="imageFile">
                  <md-card-actions class="md-alignment-left">
                    <md-button class="md-icon-button" @click="onRemoveFile">
                      <md-icon style="color: rgba(0,0,0,.54) !important;">delete</md-icon>
                    </md-button>
                    <!--                                      <md-button class="md-icon-button" @click="showDropzone = true">
                                                              <md-icon style="color: rgba(0,0,0,.54) !important;">edit</md-icon>
                                                          </md-button>-->
                  </md-card-actions>
                </md-card-area>
              </md-card-media-cover>
            </md-card>
            <!--<hr style="margin-top: 25px;"/>
            <div class="content">
                <label>Individual portal navigation <md-icon>info_outline <md-tooltip>tooltip</md-tooltip></md-icon></label>
                <br/>
                <md-button-toggle md-disabled="true">
                    <md-button class="md-toggle">Portal</md-button>
                    <md-button class="md-toggle">Monitor</md-button>
                    <md-button class="md-toggle">Broadcast</md-button>
                    <md-button class="md-toggle">Keys</md-button>
                    <md-button class="md-toggle">Viewers</md-button>
                </md-button-toggle>
            </div>-->

          </div>
        </div>
      </div>

      <div v-show="!selectedSiteStatus">
        <div class="md-layout md-gutter">
          <div class="md-layout-item text-center">
            <spinner></spinner>
          </div>
        </div>
      </div>

    </md-content>
    <md-snackbar md-position="center" :md-active.sync="errorBarStatus" md-persistent>
      <span>Site error: {{ errorBar ? errorBar.message : '' }}!</span>
      <md-button class="md-primary" @click="errorBar = null">Close</md-button>
    </md-snackbar>
    <div>
      <md-dialog :md-active.sync="displayAddUserModal" class="md-accent">

        <md-dialog-title>Add New Viewer</md-dialog-title>

        <md-dialog-content v-if="!dialogAddUserLoading">
          <div class="md-layout md-gutter">
            <div class="md-layout-item">
              <md-field :class="firstNameError">
                <label>First name</label>
                <md-input v-model="newUser.firstName" placeholder="First name" required></md-input>
                <span class="md-error">There is an error</span>
              </md-field>
            </div>
            <div class="md-layout-item">
              <md-field :class="lastNameError">
                <label>Last name</label>
                <md-input v-model="newUser.lastName" placeholder="Last name" required></md-input>
                <span class="md-error">There is an error</span>
              </md-field>
            </div>
          </div>
          <md-field :class="userEmailError">
            <label>Email</label>
            <md-input v-model="newUser.email" placeholder="Email" required></md-input>
            <span class="md-error">There is an error</span>
          </md-field>
          <div class="md-layout md-gutter">
            <div class="md-layout-item">
              <md-switch class="allowBroadcastSwitch" v-model="newUser.allowBroadcast" value="1">Allow Viewer to Broadcast</md-switch>
            </div>
          </div>
        </md-dialog-content>
        <md-dialog-content v-if="dialogAddUserLoading">
          <div class="md-layout md-gutter md-alignment-center-center">
            <div class="md-layout-item md-size-40">
              <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
            </div>
          </div>
        </md-dialog-content>
        <md-dialog-actions>
          <md-button class="md-primary" @click="cancelNewUser()">Cancel</md-button>
          <md-button class="md-primary" @click="addNewUser(newUser)">Add</md-button>
        </md-dialog-actions>
      </md-dialog>
    </div>
  </md-content>
</template>

<script>

import {mapActions, mapState} from "vuex";
import Spinner from "../components/UIComponents/Spinner";
import VueMaterialDateTimePicker from 'vue-material-date-time-picker'
import vueDropzone from "vue2-dropzone";
//import vueDropzone from "vue2-dropzone";

const siteModel = {
  label: '',
  path: '',
  cameras: [
    {enabled: true, label: null},
    {enabled: false, label: null},
    {enabled: false, label: null},
    {enabled: false, label: null},
  ],
  chat: {
    enabled: true,
    enableVideoChat: false, //Video Chat function
  },//handle channel creation or false
  brands: {main: null, co: null},
  authentication: {
    authenticationType: 'password', //password, passwordless, user-managed, multi-factor
    credentials: {
      password: ''
    },
    users: [],// groupId to user list
    autoInvite: false, //Send invitation email to new viewers when portal is updated
  },
  display: {
    showViewers: false,
    showCameraLabels: false,
    showLiveDuration: false,
    allowStreamSelection: true, //Allow users to select which cameras they view
  },
  expiration: {
    date: null
  },
  state: 'enabled'
};
export default {
  name: 'PortalCreate',
  components: {
    Spinner,
    VueMaterialDateTimePicker,
    vueDropzone
  },
  computed: {
    ...mapState({
      selectedDomain(state) {
        return state.domains.selectedDomain
      },
      createdSite(state) {
        return state.sites.createdSite
      },
      createdSiteError(state) {
        return state.sites.createdSiteError
      },
      displayDomain(state) {
        return state.domains.displayDomain
      }
    }),
    dropOptions() {
      //const accessToken = this.$auth.getAccessToken();
      if (!this.selectedDomain || !this.selectedSite) return null;
      //this.$refs.dropzone.setOption('url' `/api/domains/${this.selectedDomain._id}/sites/${this.selectedSite._id}/logo`)
      return {
        url: `/api/domains/${this.selectedDomain._id}/sites/${this.selectedSite._id}/logo`,
        // headers: {"Authorization": `Bearer ${accessToken}`},
        paramName: "uploaded_file",
        autoProcessQueue: false,
        createImageThumbnails: false
      }
    },
    getDomainUrl() {
      return this.selectedDomain ? `${this.displayDomain}` : '';
    },
    getCredentials: {
      /*console.log('getCredentials ', this.selectedSite.authentication.credentials.password );
      return this.selectedSite.authentication.credentials ? this.selectedSite.authentication.credentials.password : '';*/
      get: function () {
        return this.selectedSite.authentication.credentials ? this.selectedSite.authentication.credentials.password : '';
      },
      // setter
      set: function (newValue) {
        this.selectedSite.authentication.credentials.password = newValue;
      }
    },
    selectedSiteStatus() {
      return !this.loading && this.selectedDomain;
    },
    errorBarStatus() {
      return !!this.errorBar;
    },
    formatPath() {
      let path = this.selectedSite.path;
      path = path.replace(/[^a-zA-Z_0-9-]/g, "");
      path = path.split(' ').join('');
      path = path.toLowerCase();
      return `/${path}`;
    },
    /*formatCameras(){
        let cameras = this.selectedSite.cameras;
        for(let camera in cameras){
            if(camera.enabled === true || camera)
        }
        return path;
    },*/
    sitePathError() {
      return {
        'md-invalid': this.hasSitePathError || this.createdSiteError
      }
    },
    userEmailError() {
      return {
        'md-invalid': this.hasUserEmailError
      }
    },
    firstNameError() {
      return {
        'md-invalid': this.hasUserFirstNameError
      }
    },
    lastNameError() {
      return {
        'md-invalid': this.hasUserLastNameError
      }
    }
  },
  async created() {
    this.loading = false;
  },
  methods: {
    ...mapActions({
      'createSite': 'sites/createSite',
    }),
    onRemoveFile() {
      if (this.imageFile) {
        this.$refs.dropzone.removeFile(this.imageFile);
        this.imageFile = null;
      }
    },
    onFileAdded(file) {
      this.imageFile = file;
    },
    removeCamera(index) {
      if (index > -1) {
        this.selectedSite.cameras.splice(index, 1);
      }
      console.log(this.selectedSite.cameras);
    },
    numToSSColumn(num) {
      let s = '', t;

      while (num > 0) {
        t = (num - 1) % 26;
        s = String.fromCharCode(65 + t) + s;
        num = (num - t) / 26 | 0;
      }
      return s || undefined;
    },
    resetErrors() {
      this.sitePathErrorMessage = null;
      this.createdSiteError = null;
      this.hasSitePathError = false;
      this.siteExpirationErrorMessage = null;
      this.siteLabelErrorMessage = null;
      this.hasSiteLabelError = false;

      this.errorBar = null;
    },
    hasErrors() {
      return this.hasSiteLabelError || this.hasSitePathError || this.siteExpirationErrorMessage;
    },
    formatChip(str) {
      console.log('insert', JSON.stringify(str));
      this.displayAddUserModal = true;
      //this.newUser.email = JSON.stringify(str);
      return str = '';
    },
    async updatePortal() {
      this.resetErrors();
      this.loading = true;
      if (!this.selectedSite.label || this.selectedSite.label.length <= 0) {
        this.siteLabelErrorMessage = 'Label Required';
        this.hasSiteLabelError = true;
      }

      if (!this.selectedSite.expiration.date || this.selectedSite.expiration.date.length <= 0) {
        this.siteExpirationErrorMessage = 'Expiration Required';
      }

      if (!this.selectedSite.path || this.selectedSite.path.length <= 0) {
        this.sitePathErrorMessage = 'Path Required';
        this.hasSitePathError = true;
      }
      this.selectedSite.path = this.formatPath.length <= 1 ? '' : this.formatPath;
      if (!this.selectedSite.path || this.selectedSite.path.length <= 0) {
        this.sitePathErrorMessage = 'Path Required';
        this.hasSitePathError = true;
      }

      if (this.hasErrors()) {
        this.loading = false;
        return;
      }
      console.log('selectedSite - ', this.selectedSite, ' -- ', this.cameras, this.hasErrors())
      if(this.selectedSite.authentication.authenticationType === 'user-managed' && this.selectedSite.display.showViewers === true){
        this.selectedSite.display.showViewers = false;
      }
      try {
        this.loading = true;
        await this.createSite({domainId: this.selectedDomain._id, site: this.selectedSite});
        const accessToken = await this.$auth.getAccessToken();
        this.$refs.dropzone.setOption('headers', {"Authorization": `Bearer ${accessToken}`});
        this.$refs.dropzone.setOption('url', `/api/domains/${this.selectedDomain._id}/sites/${this.createdSite._id}/logo`);
        console.log('init upload', !!this.imageFile)
        if (this.imageFile) {
          this.$refs.dropzone.processQueue();
          console.log('start upload')
        }else{
          await this.onLogoUploadSuccess()
        }
      } catch (e) {
        console.log(e)
        this.errorBar = {message: 'Site Error!'};
        this.loading = false;
      }


    },
    async onLogoUploadSuccess() {
      const here  = this;
      console.log('createdSite - ', this.createdSite);
      const push = (path)=>{
        setTimeout(async () => {
          await here.$router.push(path);
        }, 500);
      };
      this.loading = false;
      if (this.createdSite) {
        if (this.createdSite.path) {
          await push(`/portals${this.createdSite.path}`);
        } else {
          await (`/portals`);
        }
      }
    },
    addNewUser(nUser) {
      console.log(nUser);
      console.log(this.newUser);
      let newUser = nUser;


      if (!newUser.firstName && newUser.firstName.length < 1) {
        this.hasUserFirstNameError = true;
        // setTimeout(()=>{
        //     this.hasUserFirstNameError = false;
        // }, 2000);

      }
      if (!newUser.lastName || newUser.lastName.length < 1) {
        this.hasUserLastNameError = true;
        // setTimeout(()=>{
        //     this.hasUserLastNameError = false;
        // }, 2000);

      }
      this.validateEmail(newUser.email);

      if (this.hasUserFirstNameError) {
        //this.newUser.firstName = "";
        return
      }
      if (this.hasUserLastNameError) {
        //this.newUser.lastName = "";
        return
      }
      if (this.hasUserEmailError) {
        //this.newUser.email = "";
        return
      }

      this.dialogAddUserLoading = true;
      this.authentication.users.push(newUser.email);
      this.selectedSite.authentication.users.push(newUser);

      this.cancelNewUser();
    },
    cancelNewUser() {
      this.displayAddUserModal = false;
      this.newUser = {};
      this.dialogAddUserLoading = false;
    },
    validateEmail(x) {
      //open modal
      console.log('validateEmail - ', x);
      let emailValue = x;
      let reg = /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;
      if (!reg.test(emailValue)) {
        console.log('invalid email');
        /*let index = this.selectedSite.authentication.users.findIndex((email)=>{
            return email === emailValue;
        });
        this.selectedSite.authentication.users.splice(index, 1);*/

        this.hasUserEmailError = true;
        /*setTimeout(()=>{
            this.hasUserEmailError = false;
        }, 2000);*/
      } else {
        console.log('valid email');
      }
      //console.log('users - ',this.selectedSite.authentication.users)
    },
    template() {
      return `<div class="dz-preview dz-file-preview">
                <div class="dz-image">
                    <div data-dz-thumbnail-bg></div>
                </div>
                <div class="dz-details">
                    <div class="dz-size"><span data-dz-size></span></div>
                    <div class="dz-filename"><span data-dz-name></span></div>
                </div>
                <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                <div class="dz-error-message"><span data-dz-errormessage></span></div>
                <div class="dz-success-mark"><i class="fa fa-check"></i></div>
                <div class="dz-error-mark"><i class="fa fa-close"></i></div>
            </div>
        `;
    },
    thumbnail(file, dataUrl) {
      let j, len, ref, thumbnailElement;
      if (file.previewElement) {
        file.previewElement.classList.remove("dz-file-preview");
        ref = file.previewElement.querySelectorAll("[data-dz-thumbnail-bg]");
        for (j = 0, len = ref.length; j < len; j++) {
          thumbnailElement = ref[j];
          thumbnailElement.alt = file.name;
          thumbnailElement.style.backgroundImage = 'url("' + dataUrl + '")';
        }
        return setTimeout(((function () {
          return function () {
            return file.previewElement.classList.add("dz-image-preview");
          };
        })(this)), 1);
      }
    }
  },
  data: () => ({
    imageFile: null,
    selectedSite: JSON.parse(JSON.stringify(siteModel)),
    showDropzone: true,
    hasUserEmailError: false,
    hasSitePathError: false,
    siteExpirationErrorMessage: null,
    sitePathErrorMessage: null,
    siteLabelErrorMessage: null,
    hasSiteLabelError: false,

    errorBar: false,
    loading: true,
    authentication: {
      users: []
    },
    displayAddUserModal: false,
    newUser: {},
    dialogAddUserLoading: null,
    hasUserFirstNameError: false,
    hasUserLastNameError: false,
  }),
  watch: {
    ["selectedSite.authentication.authenticationType"](n, o) {
      //console.log('The colour has changed!', n,o);
      if (n === null) this.selectedSite.authentication.authenticationType = o;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.md-invalid .md-error{
  color: red !important;
  opacity: 1;
  transform: translateZ(0);
}
span.md-error {
  font-size: 12px;
}
.md-card.dz-card.md-theme-default{
  background-color: #2e2e2e;
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 0;
  margin-right: 0;
}
#dropzone {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}

.dz-preview.dz-file-preview {
  /*  width: 100%;
    height: 100%;*/
  width: 100px;
  margin: 0;
}
.expirationContainer {
  margin-top: 10px;
  margin-bottom: 0;
}

.expirationContainer > label > input, .expirationContainer > label > input:focus-visible, .vmdtp_input {
  outline: none;
  padding-left: 0;
  padding-right: 0;
  border: none !important;
  border-radius: 0 !important;
  border-bottom: 1px solid rgba(0, 0, 0, .54) !important;
}
.md-invalid  div div .expirationContainer > label > input, .expirationContainer > label > input:focus-visible, .vmdtp_input {
  padding-left: 0;
  padding-right: 0;
  border-bottom-color: red !important;
}

</style>
<style scoped>

.md-field.border-none:after {
  height: 0;
}

.md-switch {
  display: flex;
}

table {
  width: 100%;
  table-layout: fixed;
}

th {
  text-align: left;

}

#camera-options > .md-layout > .md-layout-item > .md-field, #security-options > .md-layout > .md-layout-item > .md-field {
  margin-bottom: 0;
}

.md-toggle {
  border-radius: 50px;
}

.md-field.md-chips.md-theme-default.md-has-placeholder.md-theme-default.md-has-value {
  margin-bottom: 0;
  border: none;
}

.top-0 {
  top: 0;
}

/*.md-field label.top-0{*/
/*    color: var(--md-theme-default-text-primary-on-background,rgba(0,0,0,.87));*/
/*    font-size: 14px;*/
/*}*/
span.md-error {
  border: none;
}

#dropzone {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}

.close-btn {
  color: white;
  top: 5px;
  position: absolute;
  right: 5px;
}
</style>
