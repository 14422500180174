<template>
  <md-content>
    <md-toolbar class="md-transparent w-100" md-elevation="0">

      <div class="md-toolbar-section-end">
        <md-button v-show="selectedSiteChanged" @click="cancelPortal()">
          CANCEL
        </md-button>
        <md-button class="md-raised md-primary" @click="updatePortal()">
          UPDATE PORTAL
        </md-button>
      </div>
    </md-toolbar>
    <md-content id="portal">

      <transition name="fade" mode="out-in">

        <div v-if="selectedDomain && selectedSite && !loading">
          <div class="md-layout md-gutter">
            <div class="md-layout-item">
              <md-field>
                <label>Name</label>
                <md-input v-model="selectedSite.label" placeholder="Portal name"></md-input>
              </md-field>

              <div id="security-options">
                <label>Portal Security</label>

                <div class="md-layout">
                  <div class="md-layout-item">
                    <md-switch style="margin-top: 25px;"
                               v-model="selectedSite.authentication.authenticationType"
                               value="password">Universal Password
                    </md-switch>
                  </div>
                  <div class="md-layout-item">
                    <md-field>
                      <!--<label>Password toggle</label>-->
                      <md-input v-model="selectedSite.authentication.credentials.password" type="password"
                                placeholder="Password"></md-input>
                    </md-field>
                  </div>
                </div>
                <md-switch v-model="selectedSite.authentication.authenticationType"
                           value="user-managed">User Managed
                </md-switch>
                <!--<md-switch v-model="selectedSite.authentication.authenticationType"
                           value="multi-factor">Multi Factor Authentication
                </md-switch>-->
              </div>
              <hr style="margin-top: 20px;"/>
              <label>Portal Options</label>

              <md-switch v-model="selectedSite.chat.enableChat">Chat function</md-switch>
              <md-switch v-model="selectedSite.display.showViewers">Viewers function</md-switch>
              <!--<md-switch disabled>Video Chat function</md-switch>-->
              <!--<md-switch disabled v-model="selectedSite.authentication.autoInvite">Send invitation email
                  to new viewers when portal is updated
              </md-switch>-->
              <md-switch v-model="selectedSite.display.showCameraLabels">Show Camera labels</md-switch>
              <md-switch v-model="selectedSite.display.showLiveDuration">Show live timecodes duration
              </md-switch>
              <!--<md-switch v-model="selectedSite.display.allowStreamSelection">Allow users to select which
                  cameras they view
              </md-switch>-->

              <hr style="margin-top: 20px;"/>
              <div id="camera-options">
                <div class="md-layout">
                  <div class="md-layout-item md-size-70">
                    <label>Multiple Cameras</label>
                  </div>
                  <div class="md-layout-item md-size-30 text-center">
                    <md-button @click="selectedSite.cameras.streams.push({ enabled: true, label: null })">
                      <!--<md-icon >add_circle_outline</md-icon>-->
                      ADD
                    </md-button>
                  </div>
                </div>

                <!--<div class="md-layout" v-for="(stream, index) in selectedSite.cameras.streams"
                     :key="`stream-${index}`">
                    <div class="md-layout-item">
                        <md-switch style="margin-top: 25px;" v-model="stream.enabled">
                            {{numToSSColumn(index+1)}}
                        </md-switch>
                    </div>
                    <div class="md-layout-item">
                        <md-field>
                            <label>Label</label>
                            <md-input v-model="stream.label" placeholder="Camera label"></md-input>
                        </md-field>
                    </div>
                </div>-->
                <div class="md-layout" v-for="(camera, index) in selectedSite.cameras.streams" :key="`camera-${index}`">
                  <div class="md-layout-item md-size-30">
                    <md-switch style="margin-top: 25px;" v-model="camera.enabled">{{ numToSSColumn(index + 1) }}
                    </md-switch>
                  </div>
                  <div class="md-layout md-layout-item md-size-70">
                    <div class="md-layout-item md-size-70">
                      <md-field>
                        <label>*Label</label>
                        <md-input v-model="camera.label" placeholder="Camera label"></md-input>
                      </md-field>
                    </div>
                    <div class="md-layout-item md-size-30" v-if="selectedSite.cameras.streams.length > 1">
                      <md-button @click="removeCamera(index)">
                        <md-icon>remove_circle_outline</md-icon>
                      </md-button>
                    </div>
                  </div>
                </div>

              </div>
            </div>


            <div class="md-layout-item">
              <md-field>
                <label>Domain</label>
                <md-input style="flex: unset;" :value="getDomainUrl" readonly></md-input>
                <md-input v-model="selectedSite.path" placeholder="Path name"></md-input>
                <span class="md-error">Invalid Path</span>
              </md-field>

              <!--<label>Viewers <md-icon>info_outline <md-tooltip>tooltip</md-tooltip></md-icon></label>
              <md-field :class="userEmailError" style="margin-top: 0; padding-top: 0;">
                  <md-chips style="margin-top: 0;" @md-insert="validateEmail" v-model="usersEmails" md-placeholder="Add Viewer email..."></md-chips>
                  <span class="md-error">Invalid Email</span>
              </md-field>-->
              <label v-show="selectedSite.authentication.authenticationType !== 'password'">Viewers
<!--                <md-icon>info_outline
                  <md-tooltip>Invite Users</md-tooltip>
                </md-icon>-->
              </label>
              <md-field style="margin-top: 0; padding-top: 0;"
                        v-if="selectedSite.authentication.authenticationType !== 'password'">
                <!--<md-chips style="margin-top: 0;" md-deletable="true" v-model="usersEmails"
                          md-placeholder="Add Viewer..."></md-chips>
                <md-button @click="displayAddUserModal = true">ADD
                </md-button>-->
                <div v-if="authentication.users.length<1" @click="displayAddUserModal = true" style="cursor: not-allowed;    width: calc(100% - 88px);    position: absolute;    height: 100%;    z-index: 30;"></div>
                <md-chips style="margin-top: 0;" :md-limit="authentication.users.length" md-deletable="true" v-model="authentication.users"
                          :md-format="formatChip" @md-delete="onUserDelete"></md-chips>
                <md-button @click="displayAddUserModal = true">ADD
                </md-button>
                <!--<span class="md-error">Invalid Email</span>-->
              </md-field>
              <md-field v-else>
                <label>Share URL
<!--                  <md-icon>info_outline
                    <md-tooltip>Share URL</md-tooltip>
                  </md-icon>-->
                </label>
                <md-input style="margin-top: 15px;" :value="getShareUrl" readonly></md-input>
                <md-button @click="copyText(getShareUrl)">
                  <md-icon>content_copy</md-icon>
                </md-button>
              </md-field>

              <div class="md-layout">
                <div class="md-layout-item" style="margin-bottom: 25px;">
                  <label>Expiration
<!--                    <md-icon>info_outline
                      <md-tooltip>tooltip</md-tooltip>
                    </md-icon>-->
                  </label>
                  <div class="md-layout-item md-layout">
                    <div class="md-layout-item">
                      <div class="expirationContainer" style="">
                        <VueMaterialDateTimePicker placeholder="Click here to set expiration" v-model="selectedSite.expiration.date"
                                                   :is-date-only="false"></VueMaterialDateTimePicker>
                      </div>
                    </div>
                    <div class="md-layout-item md-size-20">
                      <md-button @click="selectedSite.expiration.date=null">CLEAR</md-button>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <label>Portal State</label>
                <div class="md-layout">
                  <div class="md-layout-item">
                    <md-field style="margin-top: 0;">
                      <md-select v-model="selectedSite.state" name="state" id="state">
                        <md-option value="enabled">Enabled</md-option>
                        <md-option value="disabled">Disabled</md-option>
                        <md-option disabled value="error">Error</md-option>
                        <md-option disabled value="expired">Expired</md-option>
                      </md-select>
                    </md-field>
                  </div>
                </div>
              </div>

              <label>Branding
<!--                <md-icon>info_outline
                  <md-tooltip>MAX WIDTH: 1440 pixels MAX HEIGHT: 1440 pixels</md-tooltip>
                </md-icon>-->
              </label>
              <md-card v-if="dropOptions" style="background-color: #2e2e2e; margin: 0 0 20px;">
                <md-card-media-cover>
                  <md-card-media md-ratio="16:9">
                    <img v-if="selectedSite.brands.main"
                         :src="`/logo-uploads/${selectedDomain._id}/${selectedSite._id}/${selectedSite.brands.main}`"
                         alt="Skyscraper">
                    <vue-dropzone v-show="!selectedSite.brands.main" ref=dropzone
                                  :options="dropOptions" @vdropzone-error="onDzError"
                                  @vdropzone-file-added="onFileAdded">
                    </vue-dropzone>
                    <md-button v-show="selectedSite.brands.main && showDropzone"
                               class="md-icon-button close-btn" @click="showDropzone = false">
                      <md-icon style="color: rgba(0,0,0,.54) !important;">close</md-icon>
                    </md-button>
                  </md-card-media>


                  <md-card-area v-show="!showDropzone && (selectedSite.brands.main || imageFile)">
                    <md-card-actions class="md-alignment-left">
                      <md-button class="md-icon-button" @click="onRemoveFile">
                        <md-icon style="color: rgba(0,0,0,.54) !important;">delete</md-icon>
                      </md-button>
                      <md-button v-show="selectedSite.brands.main && !showDropzone" class="md-icon-button" @click="showDropzone = true">
                        <md-icon style="color: rgba(0,0,0,.54) !important;">edit</md-icon>
                      </md-button>
                    </md-card-actions>
                  </md-card-area>
                </md-card-media-cover>
              </md-card>
              <hr style="margin-top: 25px;"/>
              <div class="content">
                <label>Individual portal navigation
<!--                  <md-icon>info_outline
                    <md-tooltip>Navigation Menu</md-tooltip>
                  </md-icon>-->
                </label>
                <br/>
                <md-button-toggle>
                  <md-button class="md-toggle" @click="toggleNavigation('portal')"
                             :disabled="selectedToggle === 'portal'">
                    {{ 'Portal' }}
                  </md-button>
                  <md-button class="md-toggle" @click="toggleNavigation('monitor')"
                             :disabled="selectedToggle === 'monitor'">
                    {{ 'Monitor' }}
                  </md-button>
                  <md-button class="md-toggle" @click="toggleNavigation('broadcast')"
                             :disabled="selectedToggle === 'broadcast'">
                    {{ 'Broadcast' }}
                  </md-button>
                  <md-button class="md-toggle" @click="toggleNavigation('keys')" :disabled="selectedToggle === 'keys'">
                    {{ 'Keys' }}
                  </md-button>
                  <md-button class="md-toggle" @click="toggleNavigation('viewers')"
                             :disabled="selectedToggle === 'viewers'">
                    {{ 'Viewers' }}
                  </md-button>

                </md-button-toggle>
              </div>
            </div>
          </div>
        </div>


        <div v-else>
          <div class="md-layout md-gutter">
            <div class="md-layout-item text-center">
              <spinner></spinner>
            </div>
          </div>
        </div>
      </transition>

    </md-content>
    <md-snackbar md-position="center" :md-active.sync="updateStatus" md-persistent>
      <span>{{ updateStatus }}</span>
      <md-button class="md-primary" @click="updateStatus = null">Close</md-button>
    </md-snackbar>
    <div>
      <md-dialog :md-active.sync="displayAddUserModal" class="md-accent">

        <md-dialog-title>Add New Viewer</md-dialog-title>

        <md-dialog-content v-if="!dialogAddUserLoading">
          <div class="md-layout md-gutter">
            <div class="md-layout-item">
              <md-field :class="firstNameError">
                <label>First name</label>
                <md-input v-model="newUser.profile.firstName" placeholder="First name"
                          required></md-input>
                <span class="md-error">There is an error</span>
              </md-field>
            </div>
            <div class="md-layout-item">
              <md-field :class="lastNameError">
                <label>Last name</label>
                <md-input v-model="newUser.profile.lastName" placeholder="Last name"
                          required></md-input>
                <span class="md-error">There is an error</span>
              </md-field>
            </div>
          </div>
          <md-field :class="userEmailError">
            <label>Email</label>
            <md-input v-model="newUser.profile.email" placeholder="Email" required></md-input>
            <span class="md-error">There is an error</span>
          </md-field>
          <div class="md-layout md-gutter">
            <div class="md-layout-item">
              <md-switch class="allowBroadcastSwitch" v-model="newUser.allowBroadcast" value="1">Allow Viewer to Broadcast</md-switch>
            </div>
          </div>
        </md-dialog-content>
        <md-dialog-content v-if="dialogAddUserLoading">
          <div class="md-layout md-gutter md-alignment-center-center">
            <div class="md-layout-item md-size-40">
              <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
            </div>
          </div>
        </md-dialog-content>
        <md-dialog-actions>
          <md-button class="md-primary" @click="cancelNewUser()">Cancel</md-button>
          <md-button class="md-primary" @click="addNewUser(newUser)">Add</md-button>
        </md-dialog-actions>
      </md-dialog>
    </div>
  </md-content>
</template>

<script>

import {mapActions, mapState} from "vuex";
import Spinner from "../components/UIComponents/Spinner";
import vueDropzone from "vue2-dropzone";
import VueMaterialDateTimePicker from 'vue-material-date-time-picker'

export default {
  name: 'Portal',
  components: {
    Spinner,
    vueDropzone,
    VueMaterialDateTimePicker
  },
  computed: {
    ...mapState({
      selectedDomain(state) {
        return state.domains.selectedDomain
      },
      selectedSite(state) {
        if (state.sites.selectedSite && state.sites.selectedSite.expiration && state.sites.selectedSite.expiration.date) {
          state.sites.selectedSite.expiration.date = new Date(state.sites.selectedSite.expiration.date);
        }

        return state.sites.selectedSite
      },
      displayDomain(state) {
        return state.domains.displayDomain
      }
    }),
    selectedToggle() {
      return this.toggleName;
    },
    getShareUrl() {
      return this.selectedDomain ? `${this.displayDomain}${this.selectedSite.path}` : '';
    },
    getDomainUrl() {
      return this.selectedDomain ? `${this.displayDomain}` : '';

    },
    /*getCredentials() {
        this.selectedSite.authentication.credentials = this.selectedSite.authentication.credentials ? this.selectedSite.authentication.credentials : {password: ""};
        return this.selectedSite.authentication.credentials.password;
    },
    usersEmails() {
      const emails = [];
      console.log('usersEmails - ', this.selectedSite.authentication);
      if (this.selectedSite.authentication && this.selectedSite.authentication.users) {
        this.selectedSite.authentication.users.forEach((user) => {
          console.log(user);
          emails.push(user.profile.email)
        });
      }
      return emails;
    },*/
    /*cameras(){
        return {
            A:{},
            B:{},
            C:{},
            D:{},
        }
    },*/
    viewers() {
      return this.selectedSite.authentication.users
    },
    userEmailError() {
      return {
        'md-invalid': this.hasUserEmailError
      }
    },
    dropOptions() {
      if (!this.selectedDomain || !this.selectedSite || !this.accessToken) return null
      return {
        url: `/api/domains/${this.selectedDomain._id}/sites/${this.selectedSite._id}/logo`,
        headers: {"Authorization": `Bearer ${this.accessToken}`},
        paramName: "uploaded_file",
        autoProcessQueue: false,
        createImageThumbnails: false
      }
    }
  },
  /*beforeRouteLeave(){
      this.updateSelectedSite({data:null, error:null});
  },*/
  async created() {
    this.loading = true;
    this.toggleName = this.$route.name;
    console.log('selectedDomain --- ');
    await this.run();
    this.loading = false;
  },
  methods: {
    ...mapActions({
      'selectDomain': 'domains/selectDomain',
      'getSite': 'sites/getSite',
      'selectSite': 'sites/selectSite',
      'updateSite': 'sites/updateSite'
    }),
    async run() {
      if (this.selectedDomain) {
        const {_id} = this.selectedDomain;
        const {siteId} = this.$route.params;
        await this.getSite({domainId: _id, siteName: siteId});
        //this.cameras = [].concat([], this.selectedSite.cameras.streams);
        if (this.selectedSite.authentication.users) {
          this.selectedSite.authentication.users.forEach((user) => {
            this.authentication.users.push(user.profile.email);
          });
        }
        this.selectedSite.authentication.credentials = this.selectedSite.authentication.credentials ? this.selectedSite.authentication.credentials : {password: ""};

        this.accessToken = await this.$auth.getAccessToken();
      }else{
        await this.$router.push('/domains');
      }
    },
    toggleNavigation(name) {
      this.toggleName = name;
      const to = name === 'viewers' ? `/${this.toggleName}` : `/${this.toggleName}/${this.$route.params.siteId}`
      this.$router.push(to);
    },
    onRemoveFile() {
      if (this.imageFile) {
        this.$refs.dropzone.removeFile(this.imageFile);
        this.imageFile = null;
      }
    },
    onFileAdded(file) {
      this.imageFile = file;
    },
    onDzError(file, message, xhr) {
      console.log(file, message, xhr);
    },
    onUserDelete(evt) {
      console.log('onUserDelete', evt);

      /*function add(users, email) {
          const { length } = users;
          const found = users.some(el => el.profile.email === email);
          if (!found) users.push({profile:{ email: email }});
          return users;
      }*/
      function remove(users, email) {
        let foundIndex = 0;
        const found = users.some((user, index) => {
          foundIndex = index;
          return user.profile.email === email;
        });
        if (found) users.splice(foundIndex, 1);
        return users;
      }

      this.selectedSite.authentication.users = remove(this.selectedSite.authentication.users, evt)
    },
    removeCamera(index) {
      //const index = this.selectedSite.cameras()
      if (index > -1) {
        this.selectedSite.cameras.streams.splice(index, 1);
      }

      console.log(this.selectedSite.cameras);
    },
    formatChip(str) {
      console.log(str.length, ' - inserted', JSON.stringify(str).length);
      if (str < 1) return str = '';
      this.displayAddUserModal = true;
      //this.newUser.email = JSON.stringify(str);
      return str = '';
    },
    numToSSColumn(num) {
      let s = '', t;

      while (num > 0) {
        t = (num - 1) % 26;
        s = String.fromCharCode(65 + t) + s;
        num = (num - t) / 26 | 0;
      }
      return s || undefined;
    },
    async cancelPortal() {
      //this.$router.push('/');
      //this.selectedSite = this.selectedSiteCopy;
      const domainId = this.selectedSite.domainId;
      this.selectedSite = null;
      this.selectedSite = null;
      this.loading = true;
      try {
        //await this.selectDomain(domainId);
        //await this.selectSite(this.$route.params.siteId);
        const {siteId} = this.$route.params;
        this.getSite({domainId, siteName: siteId})
        this.loading = false;
        console.log('cancelPortal - ', this.selectedSite)
      } catch (e) {
        console.log('cancelPortal', e)
      }


      //this.cameras = [].concat([], this.selectedSite.cameras.streams);
      //this.selectedSiteCopy = JSON.parse(JSON.stringify(this.selectedSite));

    },
    async updatePortal() {
      //console.log('updatePortal selectedSite - ', this.selectedSite);
      //console.log('updatePortal cameras - ', this.cameras);
      //console.log('copy ', this.selectedSiteCopy);
      if(this.selectedSite.authentication.authenticationType === 'user-managed' && this.selectedSite.display.showViewers === true){
        this.selectedSite.display.showViewers = false;
      }

      const updateSitePayLoad = Object.assign({}, this.selectedSite, {});

      console.log('updateSitePayLoad - ', updateSitePayLoad);
      this.cameras = [];
      let response;
      try {
        response = await this.updateSite({
          domainId: this.selectedDomain._id,
          siteId: this.selectedSite._id,
          site: updateSitePayLoad
        });
        if (this.imageFile) {
          this.$refs.dropzone.setOption('url'`/api/domains/${this.selectedDomain._id}/sites/${this.selectedSite._id}/logo`);
          this.$refs.dropzone.processQueue();
        }
        if (!response) {
          this.updateStatus = 'Site not updated';
        } else {
          this.updateStatus = 'Site successfully updated';
          this.selectedSiteChanged = false;
          //this.cameras = this.selectedSite.cameras.streams;

        }

        //console.log('updateSitePayLoaded cams - ', this.cameras);
      } catch (e) {
        console.log(e);
        this.updateStatus = 'Site update failed';
      }

      //this.cameras = [].concat([], this.selectedSite.cameras.streams);
    },
    validateEmail(x) {
      console.log('validateEmail - ', x);
      let emailValue = x;
      let reg = /^[_a-z0-9]+(\.[_a-z0-9]+)*@[a-z0-9-]+(\.[a-z0-9-]+)*(\.[a-z]{2,4})$/;
      if (!reg.test(emailValue)) {
        console.log('invalid email')
        let index = this.selectedSite.authentication.users.findIndex((email) => {
          return email === emailValue;
        })
        this.selectedSite.authentication.users.splice(index, 1);
        //console.log(index);
        this.hasUserEmailError = true;
        setTimeout(() => {
          this.hasUserEmailError = false;
        }, 2000);
      } else {
        console.log('valid email')
      }
      console.log('users - ', this.selectedSite.authentication.users)
    },
    template() {
      return `<div class="dz-preview dz-file-preview">
                <div class="dz-image">
                    <div data-dz-thumbnail-bg></div>
                </div>
                <div class="dz-details">
                    <div class="dz-size"><span data-dz-size></span></div>
                    <div class="dz-filename"><span data-dz-name></span></div>
                </div>
                <div class="dz-progress"><span class="dz-upload" data-dz-uploadprogress></span></div>
                <div class="dz-error-message"><span data-dz-errormessage></span></div>
                <div class="dz-success-mark"><i class="fa fa-check"></i></div>
                <div class="dz-error-mark"><i class="fa fa-close"></i></div>
            </div>
        `;
    },
    thumbnail(file, dataUrl) {
      let j, len, ref, thumbnailElement;
      if (file.previewElement) {
        file.previewElement.classList.remove("dz-file-preview");
        ref = file.previewElement.querySelectorAll("[data-dz-thumbnail-bg]");
        for (j = 0, len = ref.length; j < len; j++) {
          thumbnailElement = ref[j];
          thumbnailElement.alt = file.name;
          thumbnailElement.style.backgroundImage = 'url("' + dataUrl + '")';
        }
        return setTimeout(((function () {
          return function () {
            return file.previewElement.classList.add("dz-image-preview");
          };
        })(this)), 1);
      }
    },
    addNewUser(nUser) {
      //console.log(nUser);
      //console.log(this.newUser);
      let newUser = JSON.parse(JSON.stringify(nUser));


      if (!newUser.profile.firstName && newUser.profile.firstName.length < 1) {
        this.hasUserFirstNameError = true;
        // setTimeout(()=>{
        //     this.hasUserFirstNameError = false;
        // }, 2000);

      }
      if (!newUser.profile.lastName || newUser.profile.lastName.length < 1) {
        this.hasUserLastNameError = true;
        // setTimeout(()=>{
        //     this.hasUserLastNameError = false;
        // }, 2000);

      }
      this.validateEmail(newUser.profile.email);

      if (this.hasUserFirstNameError) {
        //this.newUser.firstName = "";
        return
      }
      if (this.hasUserLastNameError) {
        //this.newUser.lastName = "";
        return
      }
      if (this.hasUserEmailError) {
        //this.newUser.email = "";
        return
      }

      this.dialogAddUserLoading = true;
      this.authentication.users.push(newUser.profile.email);
      this.selectedSite.authentication.users.push(newUser);

      this.cancelNewUser();
    },
    cancelNewUser() {
      this.displayAddUserModal = false;
      this.newUser = {profile: {}};
      this.dialogAddUserLoading = false;
    },
  },
  data: () => ({
    imageFile: null,
    toggleName: null,
    accessToken: null,
    selectedSiteChanged: false,
    loading: false,
    //selectedSiteCopy: null,
    updateStatus: null,
    showDropzone: false,
    array: [],
    cameras: [],
    sortedCameras: [],
    hasUserEmailError: false,

    displayAddUserModal: false,
    newUser: {profile: {},allowBroadcast:false},
    dialogAddUserLoading: null,
    hasUserFirstNameError: false,
    hasUserLastNameError: false,
    authentication: {
      users: []
    },

    /*dropOptions: {
        url: '/api/domains/${domainId}/sites/${siteId}/logo',
        /!*thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: { "My-Awesome-Header": "header value" }*!/
    }*/
  }),
  watch: {
    // whenever question changes, this function will run
    /*'selectedSite.chat.enableChat': function (newQuestion, oldQuestion) {
       console.log ('selectedSite ',newQuestion, oldQuestion);
    }
    */

    selectedSite: {
      handler: function (newValue, oldValue) {
        //console.log("Person with ID:" + newValue.chat.enableChat + " modified", oldValue)
        if (!this.selectedSiteChanged && !!oldValue) this.selectedSiteChanged = true;
      },
      deep: true
    },
    /*['authentication.users'](newValue, oldValue){
      console.log('authentication.users', newValue, oldValue);

    },*/
    ["selectedSite.authentication.authenticationType"](n, o) {
      //console.log('The colour has changed!', n,o);
      if (n === null) this.selectedSite.authentication.authenticationType = o;
    }
  },
}
</script>
<style>
.allowBroadcastSwitch label{
 top: 0;
}
#dropzone {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}

.dz-preview.dz-file-preview {
  /*  width: 100%;
    height: 100%;*/
  width: 100px;
  margin: 0;
}

.expirationContainer {
  margin-top: 15px;
  margin-bottom: 5px;
}

.expirationContainer > label > input, .expirationContainer > label > input:focus-visible, .vmdtp_input {
  border: none !important;
  border-radius: 0 !important;
  border-bottom: 1px solid rgba(0, 0, 0, .87) !important;
}
</style>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.md-switch {
  display: flex;
}

table {
  width: 100%;
  table-layout: fixed;
}

th {
  text-align: left;

}

#camera-options > .md-layout > .md-layout-item > .md-field, #security-options > .md-layout > .md-layout-item > .md-field {
  margin-bottom: 0;
}

.md-toggle {
  border-radius: 50px;
}

.md-field.md-chips.md-theme-default.md-has-placeholder.md-theme-default.md-has-value {
  margin-bottom: 0;
  border: none;
}

.top-0 {
  top: 0;
}

/*.md-field label.top-0{*/
/*    color: var(--md-theme-default-text-primary-on-background,rgba(0,0,0,.87));*/
/*    font-size: 14px;*/
/*}*/
span.md-error {
  border: none;
}

#dropzone {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
}

.close-btn {
  top: 5px;
  position: absolute;
  right: 5px;
}
</style>
