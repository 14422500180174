import apiClient from "@/services/apiClient";

const state = () => ({
    chatToken: null,
    error: null
});
//silent update?
const getters = {

};

const actions = {
    async getChatToken({commit}, channelName) {
        commit('updateChatToken', {error: null, data: null});
        const response = {
            error: null,
            data: null
        };

        let data = null;
        let error = null;

        try {
            data = await apiClient.getChatTokenInfo(channelName);
        } catch (e) {
            console.log(e);
            error = e
        }
        console.log(error, data);

        if (data) {
            response.data = data;
        }

        commit('updateChatToken', {error, data});
    }
};

const mutations = {
    updateChatToken(state, resp) {
        console.log('ChatToken - ', resp);
        state.chatToken = resp.data;
        state.error = resp.error
    }
};

const modules = {

};

const sites = {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
    modules
};
export default sites
