<template>
  <md-content>
    <md-toolbar class="md-transparent" md-elevation="0">
      <md-button class="md-icon-button">
        <md-icon>monitor</md-icon>
      </md-button>
      <span class="md-title">Monitors</span>
    </md-toolbar>
    <md-content v-if="selectedDomain && portals">
      <portals-list type="Monitor"></portals-list>
    </md-content>
    <md-app class="h-100" v-else>
      <md-app-content>
        <div class="md-layout md-gutter md-alignment-center-space-between">
          <div class="md-layout-item"></div>
          <div class="md-layout-item text-center">
            <Spinner></Spinner>
          </div>
          <div class="md-layout-item"></div>
        </div>
      </md-app-content>
    </md-app>
  </md-content>
</template>

<script>
import PortalsList from './Partials/PortalsList.vue'
import {mapActions, mapMutations, mapState} from "vuex";
import Spinner from "@/views/components/UIComponents/Spinner";

export default {
  name: 'Monitors',
  components: {
    Spinner,
    PortalsList
  },
  computed: {
    ...mapState({
      portals(state) {
        return state.sites.sites
      },
      selectedDomain(state) {
        return state.domains.selectedDomain
      },
      sitesInfoParams(state) {
        return state.sites.sitesInfoParams
      }
    }),
  },
  methods: {
    ...mapActions({
      'getSites': 'sites/getSites',
    }),
    ...mapMutations({
      'updateSelectedSite': 'sites/updateSelectedSite'
    }),
  },
  created() {
    this.updateSelectedSite({data: null, error: null});
    if (this.selectedDomain) {
      this.getSites({domainId: this.selectedDomain._id, params: this.sitesInfoParams});
    } else {
      this.$router.push('/domains');
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/*#portals {
    display: flex;
    justify-content: center;
    align-items: center;
}*/
.md-content.md-table-content.md-scrollbar.md-theme-default {
  height: 100% !important;
  max-height: 100% !important;
}

</style>
