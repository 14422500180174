<template>
    <div class="position-relative" style="text-align: center; padding-top: 30px">
        <div v-if="error_message">
            <div>{{error_message}}</div>
            <router-link tag="button" class="md-button" to="/logout">
                <md-icon>input</md-icon>
            </router-link>
        </div>
    </div>
</template>

<script> export default {
    //name: "OktaError.vue",
    data: () => ({
        error_message: null,
        error: false,
    }),
    async beforeMount() {
        try {
            await this.$auth.handleAuthentication();
            console.log(this.$auth.getFromUri())
            await this.$router.replace({path: this.$auth.getFromUri() });
        } catch (e) {
            //todo - Handle error
          console.log(e)
            this.error_message = e.message
        }
    },
}
</script>
