<template>
  <div>
    <md-table md-height="100%" id="portals-table" v-model="searched" md-sort="createdAt" md-sort-order="desc"
              md-fixed-header style="height: 100%;">

      <md-table-empty-state v-if="portals"
          :md-icon="iconType"
          :md-label="portals.length > 0 ? `Portal not found` : `Create your first Portal`"
          :md-description="portals.length > 0 ? `Search found none` : `Creating a Portal, will allow you to make Viewer Portals Accessible to Users.`">
        <md-button v-show="portals.length<1" @click="$router.push('/create/portal')" class="md-primary md-raised">Create
          first Portal
        </md-button>
      </md-table-empty-state>
      <div v-else class="text-center" >
        <spinner></spinner>
      </div>

      <md-table-row slot="md-table-row" slot-scope="{ item }">
        <!--<md-table-cell md-label="ID" md-sort-by="id" md-numeric>{{ item.id }}</md-table-cell>-->
        <md-table-cell md-label="Created">
          <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;  max-width: 90px;">
            {{ item.createdAt | moment('timezone', Intl.DateTimeFormat().resolvedOptions().timeZone, "MMM DD, Y h:mA") }}
          </div>
        </md-table-cell>
        <md-table-cell md-label="Name">
          <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;  max-width: 90px;">
            {{ item.label }}
          </div>
        </md-table-cell>
        <md-table-cell md-label="Domain">
          <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;  max-width: 90px;">
            {{ `${portalBaseUrl}${item.path}` }}
          </div>
        </md-table-cell>
        <md-table-cell md-label="Type">
          <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;  max-width: 90px;">
            {{ item.authentication.authenticationType || '' }}
          </div>
        </md-table-cell>
        <md-table-cell md-label="Status">
          <md-icon :class="{'red': item.state === 'disabled', 'green': item.state === 'enabled', 'yellow': item.state === 'expired' }">
            power_settings_new
          </md-icon>
        </md-table-cell>
        <md-table-cell v-if="type==='Portals'" md-label="Expires">
          <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis;  max-width: 90px;">
            {{ item.expiration ? item.expiration.date || "" : "" | moment("MMM DD, Y h:mA") }}
          </div>
        </md-table-cell>
        <!--          <md-table-cell v-if="type==='Portals'" md-label="Edit">
                    <md-button class="md-icon-button" @click="$router.push(`/portals${item.path}`)">
                      <md-icon>mode</md-icon>
                    </md-button>
                  </md-table-cell>
                  <md-table-cell v-if="type==='Portals'" md-label="Delete">
                    <md-button class="md-icon-button" @click="onDeletePortal(item)">
                      <md-icon>delete</md-icon>
                    </md-button>
                  </md-table-cell>-->
        <md-table-cell v-if="type==='Portals'" md-label="Actions">
          <md-menu md-direction="bottom-end">
            <md-button class="md-icon-button" md-menu-trigger>
              <md-icon>menu</md-icon>
            </md-button>
            <md-menu-content>
              <md-menu-item @click="$router.push(`/portals${item.path}`)">
                <span>Edit</span>
                <md-icon>mode</md-icon>
              </md-menu-item>
              <md-menu-item @click="copyText(`Stream link https://${selectedDomain.name}.${selectedDomain.rootDomain}${item.path}\n${item.authentication.authenticationType ==='password' ? `Password ${item.authentication.credentials.password}`: '' }`)">
                <span>Copy Invite</span>
                <md-icon>content_copy</md-icon>
              </md-menu-item>
              <md-menu-item v-if="item.state || item.state === 'error'" @click="onTogglePortalState(item)">
                <span>{{ item.state | stateToggleLabel | capitalize }} Portal</span>
                <md-icon v-if="item.state"
                         :class="{'red': item.state === 'disabled', 'green': item.state === 'enabled', 'yellow': item.state === 'expired' }">
                  power_settings_new
                </md-icon>
                <md-icon v-else>error_outline</md-icon>
              </md-menu-item>
              <md-menu-item @click="onDeletePortal(item)">
                <span>Delete</span>
                <md-icon>delete</md-icon>
              </md-menu-item>
            </md-menu-content>
          </md-menu>
        </md-table-cell>
        <md-table-cell v-if="type==='Monitor'" md-label="Monitor">
          <md-button class="md-icon-button" @click="$router.push(`/monitor${item.path}`)">
            <md-icon>remove_red_eye</md-icon>
          </md-button>
        </md-table-cell>
        <md-table-cell v-if="type==='Broadcast'" md-label="Broadcast">
          <md-button class="md-icon-button" @click="$router.push(`/broadcast${item.path}`)">
            <md-icon>videocam</md-icon>
          </md-button>
        </md-table-cell>
        <md-table-cell v-if="type==='Keys'" :md-label="type">
          <md-button class="md-icon-button" @click="$router.push(`/keys${item.path}`)">
            <md-icon>vpn_key</md-icon>
          </md-button>
        </md-table-cell>
        <md-table-cell v-if="type==='Viewers'" :md-label="'Viewer'">
          <md-button class="md-icon-button" @click="$router.push(`/viewers${item.path}`)">
            <md-icon>groups</md-icon>
          </md-button>
        </md-table-cell>
      </md-table-row>
    </md-table>
    <v-page v-show="portals && portals.length" :align="'center'" v-model="sitesInfoParams.pageNumber" :total-row="totalRow" @page-change="pageChange"></v-page>
    <div>
      <md-dialog :md-active.sync="showDialog">

        <md-dialog-title v-if="!dialogLoading">Are you sure?</md-dialog-title>
        <md-dialog-content v-if="dialogLoading">
          <div class="md-layout md-gutter md-alignment-center-center">
            <div class="md-layout-item md-size-40">
              <md-progress-spinner md-mode="indeterminate"></md-progress-spinner>
            </div>
          </div>
        </md-dialog-content>
        <md-dialog-actions v-if="!dialogLoading">
          <md-button class="md-primary" @click="showDialog = false">Cancel</md-button>
          <md-button class="md-primary" @click="onDialog()">Yes</md-button>
        </md-dialog-actions>
      </md-dialog>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import sidebarLinksArray from "../../../services/sidebarLinks";
import {Page} from "v-page";
import Spinner from "@/views/components/UIComponents/Spinner";

export default {
  name: 'PortalsList',
  props: {
    type: {
      type: String,
      default: 'Portals'
    },
    deleteCallback: {
      type: Function,
      default: null
    },
    stateCallback: {
      type: Function,
      default: null
    },
    onPageChange: {
      type: Function,
    },
  },
  filters: {
    stateToggleLabel(state) {
      let label;
      switch (state) {
        case 'enabled':
          label = `Disable`;
          break;
        default:
          label = `Enable`;
      }
      return label;
    }
  },
  components: {
    'spinner': Spinner,
    'v-page': Page,
  },
  computed: {
    ...mapState({
      portals(state) {
        return state.sites.sites
      },
      selectedDomain(state) {
        return state.domains.selectedDomain
      },
      displayDomain(state) {
        return state.domains.displayDomain
      },
      totalRow(state) {
        return state.domains.selectedDomain.sites.length
      },
      sitesInfoParams(state) {
        return state.sites.sitesInfoParams
      }
    }),
    searched() {
      return this.portals || [];
    },
    iconType() {
      let icon = 'dvr';
      const foundlink = sidebarLinksArray.find((link) => {
        return link.name === this.type;
      });
      icon = foundlink ? foundlink.icon : icon;
      console.log(icon);
      return icon;
    },
    portalBaseUrl() {
      //return `${this.selectedDomain.name}.remotefilming.tv`;
      return this.displayDomain;
    }
  },
  data: () => ({
    dialogLoading: false,
    showDialog: false,
    dialogType: null,
    search: null,
    selectedItem: null,
    page: 1,
    perPage: 9,
    pages: [],
    //portalBaseUrl: 'dyson.remotefilming.tv',
  }),
  methods: {
    pageChange(info){
      if(this.onPageChange){
        this.onPageChange(info);
      }
    },
    onUpdatePortalStatus(state) {
      switch (state) {
        case 'enabled':
          break;
        case 'disabled':
          break;
        case 'expired':
          break;
        case 'error':
          break;
      }
    },
    onTogglePortalState(item) {
      this.showDialog = true;
      this.dialogType = 'state';
      this.selectedItem = item;
    },
    onDeletePortal(item) {
      this.showDialog = true
      this.dialogType = 'delete'
      this.selectedItem = item;
    },
    newUser() {
      window.alert('Noop')
    },
    async setPortalState() {
      if (this.stateCallback) {
        let b = await this.stateCallback(this.selectedItem) === true;
        this.dialogLoading = this.showDialog = !b;
        if (!b === false) {
          this.selectedItem = null;
        }
      }
    },
    async deletePortal() {
      //this.dialogLoading = true;
      if (this.deleteCallback) {
        let b = await this.deleteCallback(this.selectedItem) === true;
        this.dialogLoading = this.showDialog = !b;
        if (!b === false) {
          this.selectedItem = null;
        }
      }

      /*setTimeout(()=>{
          this.dialogLoading = false;
          this.showDialog = false;
      }, 2000)*/
    },
    async onDialog() {
      this.dialogLoading = true;
      if(this.dialogType === 'delete'){
        await this.deletePortal()
      }else if (this.dialogType === 'state'){
        await this.setPortalState()
      }
    }
  },
  created(){

  }
}

</script>

<style scoped>
.md-field {
  max-width: 300px;
}

.md-card.md-theme-default {

  box-shadow: none !important;
}

</style>
<style>
i.md-icon.md-icon-font.md-theme-default.green,
.green {
  color: green;
}

i.md-icon.md-icon-font.md-theme-default.yellow,
.yellow {
  color: yellow;
}

i.md-icon.md-icon-font.md-theme-default.red,
.red {
  color: red;
}

table {
  width: 100% !important;
}
</style>
