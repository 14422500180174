export default [
  {
    name: 'Portals',
    icon: 'dvr',
    path: '/portals',
    /*create: {
      title: '',
      description: ''
    }*/
  },
  {
    name: 'Monitor',
    icon: 'monitor',
    path: '/monitor'
  },
  {
    name: 'Broadcast',
    icon: 'videocam',
    path: '/broadcast'
  },
  {
    name: 'Keys',
    icon: 'vpn_key',
    path: '/keys'
  },
  {
    name: 'Viewers',
    icon: 'groups',
    path: '/viewers'
  },
  {
    name: 'Settings',
    icon: 'settings',
    path: '/profile',
    children: [
      {
        name: 'Profile',
        icon: 'business',
        path: '/profile'
      },
      {
        name: 'Team',
        icon: 'admin_panel_settings',
        path: '/team'
      },
      {
        name: 'Domains',
        icon: 'language',
        path: '/domains'
      },
    ]
  },
  {
    name: 'Help',
    icon: 'help_outline',
    path: '/help'
  },
  {
    name: 'Logout',
    icon: 'input',
    path: '/logout'
  }
]
